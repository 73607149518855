/* tslint:disable */
/* eslint-disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ## A custom template from the reclaim-api/api-client         ##
 * ## repo was used.                                            ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from "axios";

export interface AbstractLinearTeam {
  id?: string;
  name?: string;
  key?: string;
  issueEstimationType?: string | null;
}

export interface AbstractMetric {
  type: AnalyticsType;
  unit: AnalyticsUnit;
  description: string;
  appendixKeys: Record<string, string[]>;
  noData: boolean;
}

export enum AccessRole {
  FreeBusyReader = "freeBusyReader",
  Reader = "reader",
  Owner = "owner",
  Writer = "writer",
  None = "none",
}

export interface AccessibleObject {
  accessible?: boolean;
  annotations?: Annotation[];
  declaredAnnotations?: Annotation[];
}

export enum AnalyticsBucket {
  DAYOFWEEK = "DAY_OF_WEEK",
  DATE = "DATE",
  WEEKOF = "WEEK_OF",
  ANALYTICSCATEGORY = "ANALYTICS_CATEGORY",
  EMAIL = "EMAIL",
  NUMBERRANGESTART = "NUMBER_RANGE_START",
  NUMBERCUMULATIVESTART = "NUMBER_CUMULATIVE_START",
  STRING = "STRING",
  RECLAIMEVENTTYPE = "RECLAIM_EVENT_TYPE",
}

export enum AnalyticsCategory {
  TEAMMEETING = "TEAM_MEETING",
  EXTERNALMEETING = "EXTERNAL_MEETING",
  ONEONONE = "ONE_ON_ONE",
  TRAVEL = "TRAVEL",
  BREAK = "BREAK",
  PERSONAL = "PERSONAL",
  VACATION = "VACATION",
  DEEPWORK = "DEEP_WORK",
  SHALLOWWORK = "SHALLOW_WORK",
}

export type AnalyticsDurationByCategoryByStringMetric = AbstractMetric & {
  bucket?: AnalyticsBucket;
  subBucket?: AnalyticsBucket;
  values?: AnalyticsGroupLongAnalyticsCategoryString[];
};

export type AnalyticsDurationByCategoryMetric = AbstractMetric & {
  bucket?: AnalyticsBucket;
  values?: AnalyticsRowAnalyticsCategoryLong[];
};

export type AnalyticsDurationByDateByCategoryMetric = AbstractMetric & {
  bucket?: AnalyticsBucket;
  subBucket?: AnalyticsBucket;
  values?: AnalyticsGroupLongLocalDateAnalyticsCategory[];
};

export type AnalyticsDurationByDateByStringMetric = AbstractMetric & {
  bucket?: AnalyticsBucket;
  subBucket?: AnalyticsBucket;
  values?: AnalyticsGroupLongLocalDateString[];
};

export type AnalyticsDurationByDateMetric = AbstractMetric & {
  bucket?: AnalyticsBucket;
  values?: AnalyticsRowLocalDateLong[];
};

export type AnalyticsDurationByDayOfWeekMetric = AbstractMetric & {
  bucket?: AnalyticsBucket;
  values?: AnalyticsRowDayOfWeekLong[];
};

export interface AnalyticsGroupFloatLocalDateInteger {
  key: string;
  values: AnalyticsRowIntegerFloat[];
}

export interface AnalyticsGroupLongAnalyticsCategoryString {
  key: AnalyticsCategory;
  values: AnalyticsRowStringLong[];
}

export interface AnalyticsGroupLongLocalDateAnalyticsCategory {
  key: string;
  values: AnalyticsRowAnalyticsCategoryLong[];
}

export interface AnalyticsGroupLongLocalDateString {
  key: string;
  values: AnalyticsRowStringLong[];
}

export enum AnalyticsGroupingInterval {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
}

export type AnalyticsIntegerValueMetric = AbstractMetric & { value?: number };

export enum AnalyticsMetricName {
  DIAGNOSTICSEVENTCOUNT = "DIAGNOSTICS_EVENT_COUNT",
  DIAGNOSTICSEVENTCOUNTBYTEAMMEMBER = "DIAGNOSTICS_EVENT_COUNT_BY_TEAM_MEMBER",
  DIAGNOSTICSEVENTBUSYCOUNTBYTEAMMEMBER = "DIAGNOSTICS_EVENT_BUSY_COUNT_BY_TEAM_MEMBER",
  DIAGNOSTICSMEETINGCOUNTBYTEAMMEMBER = "DIAGNOSTICS_MEETING_COUNT_BY_TEAM_MEMBER",
  TIMEWITHPEOPLEDURATIONBYDATEBYPERSON = "TIME_WITH_PEOPLE_DURATION_BY_DATE_BY_PERSON",
  TIMEWITHPEOPLEDURATIONBYPERSON = "TIME_WITH_PEOPLE_DURATION_BY_PERSON",
  FOCUSTIMEDURATIONBYDATEBYTEAMMEMBER = "FOCUS_TIME_DURATION_BY_DATE_BY_TEAM_MEMBER",
  FREETIMEDURATIONBYDATEBYTEAMMEMBER = "FREE_TIME_DURATION_BY_DATE_BY_TEAM_MEMBER",
  MEETINGTIMEDURATIONBYDATEBYTEAMMEMBER = "MEETING_TIME_DURATION_BY_DATE_BY_TEAM_MEMBER",
  TASKTIMEDURATIONBYDATEBYTEAMMEMBER = "TASK_TIME_DURATION_BY_DATE_BY_TEAM_MEMBER",
  HABITTIMEDURATIONBYDATEBYTEAMMEMBER = "HABIT_TIME_DURATION_BY_DATE_BY_TEAM_MEMBER",
  MEETINGTIMEPERCENTAGEBYDATEBYDURATIONRANGE = "MEETING_TIME_PERCENTAGE_BY_DATE_BY_DURATION_RANGE",
  FOCUSDURATIONBYCATEGORYBYEVENTNAME = "FOCUS_DURATION_BY_CATEGORY_BY_EVENT_NAME",
  SCHEDULINGLINKEVENTCOUNT = "SCHEDULING_LINK_EVENT_COUNT",
  RECLAIMSCHEDULEDEVENTCOUNT = "RECLAIM_SCHEDULED_EVENT_COUNT",
  DURATIONBYCATEGORY = "DURATION_BY_CATEGORY",
  RECLAIMWORKDURATIONBYDATEBYASSIGNMENTTYPE = "RECLAIM_WORK_DURATION_BY_DATE_BY_ASSIGNMENT_TYPE",
  DURATIONBYDATEBYCATEGORY = "DURATION_BY_DATE_BY_CATEGORY",
  PERSONALHABITTIMEDURATION = "PERSONAL_HABIT_TIME_DURATION",
  VACATIONTIMEDURATION = "VACATION_TIME_DURATION",
  OUTSIDEWORKTIMEDURATION = "OUTSIDE_WORK_TIME_DURATION",
  RECLAIMEDTIMEDURATION = "RECLAIMED_TIME_DURATION",
  DECOMPRESSIONTIMEDURATION = "DECOMPRESSION_TIME_DURATION",
  TRAVELTIMEDURATION = "TRAVEL_TIME_DURATION",
  MEETINGTIMEDURATIONBYDAYOFWEEK = "MEETING_TIME_DURATION_BY_DAY_OF_WEEK",
  FOCUSTIMEDURATIONBYDAYOFWEEK = "FOCUS_TIME_DURATION_BY_DAY_OF_WEEK",
  WORKHABITSANDTASKSDURATIONBYDATEBYNAME = "WORK_HABITS_AND_TASKS_DURATION_BY_DATE_BY_NAME",
  PERSONALHABITSANDTASKSDURATIONBYDATEBYNAME = "PERSONAL_HABITS_AND_TASKS_DURATION_BY_DATE_BY_NAME",
  PERSONALTIMEDURATION = "PERSONAL_TIME_DURATION",
  LARGEMEETINGSCOUNT = "LARGE_MEETINGS_COUNT",
  MEETINGSDECLINEDCOUNT = "MEETINGS_DECLINED_COUNT",
  MEETINGCALLOUTGROUP = "MEETING_CALLOUT_GROUP",
  WORKLIFECALLOUTGROUP = "WORK_LIFE_CALLOUT_GROUP",
  ROICALLOUTGROUP = "ROI_CALLOUT_GROUP",
}

export type AnalyticsPercentageByDurationRangeByDateMetric = AbstractMetric & {
  bucket?: AnalyticsBucket;
  subBucket?: AnalyticsBucket;
  values?: AnalyticsGroupFloatLocalDateInteger[];
};

export interface AnalyticsResult {
  name: AnalyticsMetricName;
  metric: AbstractMetric;
}

export interface AnalyticsResults {
  metrics: AnalyticsResult[];
  appendix: Appendix;
}

export interface AnalyticsRowAnalyticsCategoryLong {
  key: AnalyticsCategory;
  value: number;
}

export interface AnalyticsRowDayOfWeekLong {
  key: DayOfWeek;
  value: number;
}

export interface AnalyticsRowIntegerFloat {
  key: number;
  value: number;
}

export interface AnalyticsRowLocalDateLong {
  key: string;
  value: number;
}

export interface AnalyticsRowStringLong {
  key: string;
  value: number;
}

export type AnalyticsServiceRichContact = ThinPerson & { firstName?: string | null; lastName?: string | null };

export type AnalyticsSubscription = Subscription & { subscriptionType?: SubscriptionType };

export enum AnalyticsType {
  IntegerValue = "integerValue",
  CountByString = "countByString",
  DurationSummary = "durationSummary",
  DurationByDateByCategory = "durationByDateByCategory",
  DurationByCategory = "durationByCategory",
  DurationByDate = "durationByDate",
  DurationByString = "durationByString",
  DurationByDayOfWeek = "durationByDayOfWeek",
  DurationByDateByString = "durationByDateByString",
  DurationByRangeByDate = "durationByRangeByDate",
  DurationByCategoryByString = "durationByCategoryByString",
  CalloutGroup = "calloutGroup",
}

export enum AnalyticsUnit {
  COUNT = "COUNT",
  DURATIONMINUTES = "DURATION_MINUTES",
  PERCENTAGE = "PERCENTAGE",
}

export interface AnnotatedElement {
  annotations?: Annotation[];
  declaredAnnotations?: Annotation[];
}

export type AnnotatedType = AnnotatedElement & {
  annotatedOwnerType?: AnnotatedType;
  type?: Type;
  annotations?: Annotation[];
  declaredAnnotations?: Annotation[];
};

export type Annotation = object;

export interface ApiKey {
  id: string;
  description: string;
  lifetimeMs?: number | null;
  token?: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface AppNotificationsSettings {
  enabled?: boolean;
  unscheduledPriority?: boolean;
}

export interface Appendix {
  people?: RichAppendixEntry;
  teamSize: number;
}

export interface AsanaAttachedResource {
  error?: string | null;
  resource_name?: string | null;
  resource_url?: string | null;
}

export interface AsanaFormFieldOption {
  id?: string;
  label?: string;
  sub_label?: string | null;
}

export enum AsanaFormFieldType {
  SingleLineText = "single_line_text",
  RichText = "rich_text",
  Typeahead = "typeahead",
  Checkboxes = "checkboxes",
  RadioButton = "radio_button",
  Date = "date",
  Datetime = "datetime",
  StaticText = "static_text",
}

export interface AsanaFormMetadata {
  metadata?: AsanaSyncForm;
  template?: string;
}

export interface AsanaIntegration {
  workspace?: string;
  availableProjects?: AsanaProject[];
}

export interface AsanaProject {
  gid?: string;
  resource_type?: ResourceType;
  name?: string;
  workspace?: AsanaWorkspace | null;
}

export interface AsanaSettings {
  enabled?: boolean;
}

export interface AsanaSyncForm {
  title?: string;
  on_submit_callback?: string;
  on_change_callback?: string;
  submit_button_text?: string;
  fields?: AsanaSyncFormField[];
}

export interface AsanaSyncFormField {
  type?: AsanaFormFieldType;
  id?: string;
  name?: string;
  value?: string | null;
  placeholder?: string | null;
  width?: string | null;
  error?: string | null;
  options?: AsanaFormFieldOption[] | null | null;
  is_watched?: boolean;
  is_required?: boolean;
}

export interface AsanaSyncFormSubmission {
  data?: string;
}

export interface AsanaWidget {
  title?: string;
  error?: string | null;
  fields?: AsanaWidgetField[];
  subtitle?: string | null;
  subicon_url?: string | null;
  num_comments?: number | null;
  footer?: AsanaWidgetFooter;
}

export interface AsanaWidgetField {
  name?: string;
  type?: AsanaWidgetFieldType;
  datetime?: string | null;
  icon_url?: string | null;
  color?: AsanaWidgetPillColor | null;
  text?: string | null;
}

export enum AsanaWidgetFieldType {
  DatetimeWithIcon = "datetime_with_icon",
  Pill = "pill",
  TextWithIcon = "text_with_icon",
}

export interface AsanaWidgetFooter {
  footer_type?: string;
  created_at?: string | null;
  last_updated_at?: string | null;
  text?: string | null;
}

export interface AsanaWidgetMetadata {
  metadata?: AsanaWidget;
  template?: string;
}

export enum AsanaWidgetPillColor {
  None = "none",
  Red = "red",
  Orange = "orange",
  YellowOrange = "yellow_orange",
  Yellow = "yellow",
  YellowGreen = "yellow_green",
  Green = "green",
  BlueGreen = "blue_green",
  Aqua = "aqua",
  Blue = "blue",
  Indigo = "indigo",
  Purple = "purple",
  Magenta = "magenta",
  HotPink = "hot_pink",
  Pink = "pink",
  CoolGray = "cool_gray",
}

export interface AsanaWorkspace {
  gid?: string;
  resource_type?: ResourceType;
  name?: string;
}

export type Assignment = Snoozeable & {
  id?: number;
  created?: string;
  recurringAssignmentType?: RecurringAssignmentType;
};

/**
 * Notification for websocket clients.
 */
export interface AssistCompleted {
  startTime: string;
  endTime: string;
  notificationKeys: string[];
  events: Event[];
}

export type AssistCompletedSubscription = Subscription & { subscriptionType?: SubscriptionType };

/**
 * This entity maps to the assist_events table.
 */
export interface AssistDetails {
  type?: AssistType;
  customHabit?: boolean;
  habitOrTask?: boolean;
  task?: boolean;
  conferenceBuffer?: boolean;
  status?: AssistStatus;

  /** The source event id for a travel assist event. */
  travelNewEventId?: string | null;

  /** The source event id for a conference (decompression time) event. */
  conferenceEventId?: string | null;
  lastControlledUpdate?: string;
  lastControlledHash?: number;
  defended?: boolean;
  pinned?: boolean;
  lockState?: LockState | null;
  dailyHabitId?: number | null;
  taskId?: number | null;
  taskIndex?: number | null;
  policyOverride?: AssistPolicyOverride | null;
  lastManualAdjustment?: string;
  recurringAssignmentType?: RecurringAssignmentType;
  eventType?: ReclaimEventType;
  assistReferenceValid?: boolean;
}

export type AssistFeatures = object;

export interface AssistPlanned {
  startTime: string;
  endTime: string;
  notificationKeys: string[];
  events: Event[];
}

export type AssistPlannedSubscription = Subscription & {
  subscriptionType?: SubscriptionType;
  startTime?: string;
  endTime?: string;
};

export interface AssistPolicyOverride {
  windowStart: string;
  idealTime: string;
  windowEnd: string;
  durationMin: number;
  durationMax: number;
  forceDefend: boolean;
}

export interface AssistSettings {
  travel?: boolean;
  otherTravelDuration?: number;
  conferenceBuffer?: boolean;
  conferenceBufferDuration?: number;
  assignmentPaddingDuration?: number;
  conferenceBufferPrivate?: boolean;
  conferenceBufferType?: ConferenceBufferType;
  customConferenceBufferTitle?: string;
  focus?: boolean;
  allOneOnOnesBusy?: boolean;
  autoLockForMeetings?: AutoLock;
  autoLockForNonMeetings?: AutoLock;
  assistDays?: number;
  bypassed?: boolean;
}

export enum AssistStatus {
  CONTROLLED = "CONTROLLED",
  RELEASED = "RELEASED",
  ARCHIVED = "ARCHIVED",
}

export enum AssistType {
  TASK = "TASK",
  CUSTOMDAILY = "CUSTOM_DAILY",
  CATCHUPAM = "CATCHUP_AM",
  CATCHUPPM = "CATCHUP_PM",
  LUNCH = "LUNCH",
  FOCUS = "FOCUS",
  TRAVELPRE = "TRAVEL_PRE",
  TRAVELPOST = "TRAVEL_POST",
  CONBUF = "CONBUF",
}

export interface AttendeeTimePolicyView {
  timezone: ZoneId;
  timePolicy: TimePolicy;
  timePolicyInViewerTimezone: TimePolicy;
  usesDefaultTimePolicy: boolean;
  eligibility: OneOnOneInviteeEligibility;
}

export enum AutoLock {
  OFF = "OFF",
  SAMEDAY = "SAME_DAY",
  SAMEWEEK = "SAME_WEEK",
  TOMORROW = "TOMORROW",
  ROLLING3DAYS = "ROLLING_3_DAYS",
}

export interface BillingSettings {
  enabled?: boolean;
}

export interface Calendar {
  valid?: boolean;
  id?: number;
  name?: string;
  colorHex?: string;
  user?: User;
  userId?: string;
  calendarId?: string;
  credentialId?: number;
  syncToken?: string;
  watchGuid?: string;
  watchResourceId?: string;
  data?: object;
  accessDomainRead?: boolean;
  authorized?: boolean;
  created?: string;
  deleted?: string;
  type?: CalendarType;
  accessRole?: AccessRole;
  systemCalendar?: boolean;
  nextRefresh?: string;
  timezone?: ZoneId;
  primaryCalendarId?: string;
  priority?: number;
  projectId?: number;
  syncSettings: SyncSettings;
  lastSynced?: string;
  syncIdEncodingVersion?: number;
  blockAvailability?: boolean;
  directlyConnected?: boolean;
}

export enum CalendarType {
  PRIMARY = "PRIMARY",
  SHADOW = "SHADOW",
  PERSONAL = "PERSONAL",
  PRIORITY = "PRIORITY",
  PROJECT = "PROJECT",
}

export enum CalendarAccess {
  NONE = "NONE",
  SETTINGSONLY = "SETTINGS_ONLY",
  READ = "READ",
  WRITE = "WRITE",
  SUPER = "SUPER",
}

export interface CalendarSettings {
  enabled?: boolean;
}

export interface CalendarSync {
  source: CalendarView;
  target: CalendarView;
  enabled: boolean;
  transparency: SyncTransparency;
  workingHours: boolean;
  defaultType: EventType;
  type: SyncCalendarType;
  color: EventColor;
  allDayEvents: SyncAllDayEvents;
}

export type CalendarSyncPolicy = SyncSettings & {
  sourceCalendarId?: number;
  targetCalendarId?: number;
  sourceCalendar?: Calendar;
  targetCalendar?: Calendar;
  enabled?: boolean;
  lastSyncToken?: string;
};

export interface CalendarView {
  name: string;
  id: number;
  externalId: string;
  connected: boolean;
  colorHex: string;
  credentialId: number;
  reason?: IneligibilityReason | null;
  writableFor: ReclaimEventType[];
}

export interface CertPath {
  type: string;
  encodings?: IteratorString;
  encoded?: string[];
  certificates?: Certificate[];
}

export interface Certificate {
  type: string;
  encoded?: string[];
  publicKey?: PublicKey;
}

export interface ClassLoader {
  name?: string;
  registeredAsParallelCapable?: boolean;
  parent?: ClassLoader;
  unnamedModule?: Module;
  definedPackages?: Package[];
}

export interface ClassListString {
  interface?: boolean;
  array?: boolean;
  primitive?: boolean;
  annotation?: boolean;
  synthetic?: boolean;
  name?: string;
  classLoader?: ClassLoader;
  module?: Module;
  typeParameters?: TypeVariableClassListString[];
  superclass?: ClassListString;
  genericSuperclass?: Type;
  package?: Package;
  packageName?: string;
  interfaces?: ClassObject[];
  genericInterfaces?: Type[];
  componentType?: ClassObject;
  modifiers?: number;
  signers?: object[];
  enclosingMethod?: Method;
  enclosingConstructor?: ConstructorObject;
  declaringClass?: ClassObject;
  enclosingClass?: ClassObject;
  simpleName?: string;
  typeName?: string;
  canonicalName?: string;
  anonymousClass?: boolean;
  localClass?: boolean;
  memberClass?: boolean;
  classes?: ClassObject[];
  fields?: Field[];
  methods?: Method[];
  constructors?: ConstructorObject[];
  declaredClasses?: ClassObject[];
  declaredFields?: Field[];
  recordComponents?: RecordComponent[];
  declaredMethods?: Method[];
  declaredConstructors?: ConstructorObject[];
  protectionDomain?: ProtectionDomain;
  enum?: boolean;
  record?: boolean;
  enumConstants?: string[][];
  annotations?: Annotation[];
  declaredAnnotations?: Annotation[];
  annotatedSuperclass?: AnnotatedType;
  annotatedInterfaces?: AnnotatedType[];
  nestHost?: ClassObject;
  nestMembers?: ClassObject[];
  hidden?: boolean;
  permittedSubclasses?: ClassObject[];
  sealed?: boolean;
}

export interface ClassObject {
  interface?: boolean;
  array?: boolean;
  primitive?: boolean;
  annotation?: boolean;
  synthetic?: boolean;
  name?: string;
  classLoader?: ClassLoader;
  module?: Module;
  typeParameters?: TypeVariableClassObject[];
  superclass?: ClassObject;
  genericSuperclass?: Type;
  package?: Package;
  packageName?: string;
  interfaces?: ClassObject[];
  genericInterfaces?: Type[];
  componentType?: ClassObject;
  modifiers?: number;
  signers?: object[];
  enclosingMethod?: Method;
  enclosingConstructor?: ConstructorObject;
  declaringClass?: ClassObject;
  enclosingClass?: ClassObject;
  simpleName?: string;
  typeName?: string;
  canonicalName?: string;
  anonymousClass?: boolean;
  localClass?: boolean;
  memberClass?: boolean;
  classes?: ClassObject[];
  fields?: Field[];
  methods?: Method[];
  constructors?: ConstructorObject[];
  declaredClasses?: ClassObject[];
  declaredFields?: Field[];
  recordComponents?: RecordComponent[];
  declaredMethods?: Method[];
  declaredConstructors?: ConstructorObject[];
  protectionDomain?: ProtectionDomain;
  enum?: boolean;
  record?: boolean;
  enumConstants?: object;
  annotations?: Annotation[];
  declaredAnnotations?: Annotation[];
  annotatedSuperclass?: AnnotatedType;
  annotatedInterfaces?: AnnotatedType[];
  nestHost?: ClassObject;
  nestMembers?: ClassObject[];
  hidden?: boolean;
  permittedSubclasses?: ClassObject[];
  sealed?: boolean;
}

export interface ClickUpIntegration {
  workspace?: ClickUpResource;
  available_spaces?: ClickUpSpace[];
  selected_space_ids?: string[];
  requires_tag?: boolean;
}

export interface ClickUpIntegrationDetails {
  workspace: ClickUpResource;
  availableSpaces: ClickUpSpaceWithEligibility[];
  tags: string[];
  lastSynced?: string | null;
}

export interface ClickUpIntegrationPatch {
  selected_space_ids?: string[];
  requires_tag?: boolean;
}

export interface ClickUpResource {
  id?: string;
  name?: string;
}

export interface ClickUpSpace {
  id?: string;
  name?: string;
  features?: object;
  statuses?: ClickUpStatus[];
}

export interface ClickUpSpaceWithEligibility {
  id: string;
  name: string;
  isEligible: boolean;
  features: object;
}

export interface ClickUpStatus {
  status?: string;
  orderindex?: number;
  type?: ClickUpStatusType;
}

export enum ClickUpStatusType {
  Open = "open",
  Done = "done",
  Closed = "closed",
  Custom = "custom",
  Unstarted = "unstarted",
  Active = "active",
}

export interface CodeSigner {
  signerCertPath: CertPath;
  timestamp: Timestamp;
}

export interface CodeSource {
  location?: string;
  certificates?: Certificate[];
  codeSigners?: CodeSigner[];
}

export interface Colorized {
  color?: EventColor;
}

export interface ColorsSettings {
  enabled?: boolean;
  prioritiesEnabled?: boolean;
  projectsEnabled?: boolean;
  categoriesEnabled?: boolean;
  lastModified?: string;
  priorities?: Record<string, EventColor>;
  categories?: Record<string, EventColor>;
}

export enum ConferenceBufferType {
  ALLMEETINGS = "ALL_MEETINGS",
  CONFERENCEMEETINGS = "CONFERENCE_MEETINGS",
}

export enum ConferenceType {
  GOOGLEMEET = "GOOGLE_MEET",
  ZOOM = "ZOOM",
  CUSTOM = "CUSTOM",
  GENERICLOCATION = "GENERIC_LOCATION",
  PHYSICALADDRESS = "PHYSICAL_ADDRESS",
  PHONENUMBER = "PHONE_NUMBER",
}

export interface ConnectedAccount {
  id?: number;
  name?: string;
  valid?: boolean;
  avatar?: string | null;
  main?: boolean;
  primaryCalendarId?: number | null;
  canDelete?: boolean;
  type?: ConnectedAccountType;
  identityAccess?: boolean;
  calendarAccess?: CalendarAccess;
  taskAccess?: boolean;
  unique?: boolean;
  lastSynced?: string | null;
  switchToMainURI?: string | null;
  repairURI?: string | null;
  connectedCalendars?: CalendarView[];
  numSyncedCalendars?: number;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  scopes?: string;
}

export interface ConnectedAccountPayload {
  accountName: string;
}

export enum ConnectedAccountType {
  GOOGLE = "GOOGLE",
}

export type ConstructorObject = Executable &
  AccessibleObject & {
    accessible?: boolean;
    declaringClass: ClassObject;
    name?: string;
    modifiers: number;
    typeParameters?: TypeVariableConstructorObject[];
    parameterTypes: ClassObject[];
    parameterCount?: number;
    genericParameterTypes?: Type[];
    exceptionTypes?: ClassObject[];
    genericExceptionTypes?: Type[];
    varArgs?: boolean;
    synthetic?: boolean;
    declaredAnnotations?: Annotation[];
    parameterAnnotations: Annotation[][];
    annotatedReturnType?: AnnotatedType;
    annotatedReceiverType?: AnnotatedType;
  };

export type ConvertibleMultiValuesString = ConvertibleValuesListString & { empty?: boolean };

export interface ConvertibleValuesListString {
  empty?: boolean;
  valueType?: ClassListString;
}

export interface CreateCalendarSyncRequest {
  sourceAccountId: number;
  sourceCalendarId: string;
  targetAccountId: number;
  targetCalendarId: string;
  syncAllDayEvents: SyncAllDayEvents;
  color: EventColor;
  defaultType: EventType;
  type: SyncCalendarType;
  transparency: SyncTransparency;
}

export interface Credential {
  id?: number;
  userId?: string;
  provider?: string;
  principal?: string;
  name?: string;
  splitScopes?: string[];
  scopes?: string;
  data?: object;
  avatar?: string;
  userInfo?: GoogleUserInfo;
  updated?: string;
  key?: string;
  valid?: boolean;
  serviceAccount?: boolean;
}

export type CustomTimePolicy = TimePolicy & { category: CustomTimePolicyCategory };

export enum CustomTimePolicyCategory {
  WORK = "WORK",
  PERSONAL = "PERSONAL",
}

export interface DailyHabit {
  id: number;
  title: string;
  alwaysPrivate: boolean;
  eventCategory: EventCategory;
  eventSubType: EventSubType;
  eventColor?: EventColor | null;
  created: string;
  updated: string;
  snoozeUntil?: string | null;
  defenseAggression: DefenseAggression;
  defendedDescription: string;
  recurringAssignmentType: RecurringAssignmentType;
  invitees: ThinPerson[];
  enabled: boolean;
  durationMin: number;
  durationMax: number;
  idealTime: string;
  idealDay?: DayOfWeek | null;
  recurrence: Recurrence;
  timesPerPeriod: number;
  additionalDescription: string;
  index: number;
  elevated: boolean;
  type: AssistType;
  reservedWords: string[];
  notification: boolean;
  timePolicyType?: TimePolicyType | null;
  oneOffPolicy?: TimePolicy | null;
  timeSchemeId?: string | null;
  autoDecline: boolean;
  autoDeclineText: string;
  adjusted: boolean;
  priority: PriorityLevel;
  prioritizableType?: string;
}

export type DailyHabitSubscription = Subscription & { subscriptionType?: SubscriptionType };

export interface DailyHabitTemplate {
  name: string;
  displayTitle: string;
  eventCategory: EventType;
  timePolicyType: TimePolicyType;
  oneOffPolicy: TimePolicy;
  idealTime: string;
  durationMin: number;
  durationMax: number;
  elevated: boolean;
  reservedWords: string[];
  recurrence: Recurrence;
  timesPerPeriod: number;
  idealDay: DayOfWeek;
}

export enum DateFieldOrder {
  MDY = "MDY",
  DMY = "DMY",
  YMD = "YMD",
}

export interface DayHours {
  intervals: LocalTimeInterval[];
  startOfDay?: string | null;
  endOfDay?: string | null;
}

export enum DayOfWeek {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

export enum DefenseAggression {
  NONE = "NONE",
  LOW = "LOW",
  DEFAULT = "DEFAULT",
  HIGH = "HIGH",
  MAX = "MAX",
}

export enum DelayStart {
  NODELAY = "NO_DELAY",
  FROMNOWMINUTES = "FROM_NOW_MINUTES",
  FROMNOWHOURS = "FROM_NOW_HOURS",
  FROMNOWDAYS = "FROM_NOW_DAYS",
  FROMNOWSTARTOFNEXTDAY = "FROM_NOW_START_OF_NEXT_DAY",
  FROMNOWSTARTOFNEXTWEEK = "FROM_NOW_START_OF_NEXT_WEEK",
}

export interface DerivativeContext {
  selectedAttendee: ThinPerson;
  toRecipients: ThinPerson[];
  ccRecipients: ThinPerson[];
  subject: string;
}

export interface DetailedEntitlements {
  unlimitedHabitsEnabled?: EntitlementDetails;
  unlimitedSyncEnabled?: EntitlementDetails;
  unlimitedConnectedCalendarsEnabled?: EntitlementDetails;
  customSyncTransparencyEnabled?: EntitlementDetails;
  smart11CreationEnabled?: EntitlementDetails;
  customSlackSyncStatusEnabled?: EntitlementDetails;
  customConferenceBufferTitleEnabled?: EntitlementDetails;
  linearEnabled?: EntitlementDetails;
  jiraEnabled?: EntitlementDetails;
  todoistEnabled?: EntitlementDetails;
  asanaEnabled?: EntitlementDetails;
  clickupEnabled?: EntitlementDetails;
  trelloEnabled?: EntitlementDetails;
  mondayEnabled?: EntitlementDetails;
}

export interface Duration {
  units?: TemporalUnit[];
  zero?: boolean;
  negative?: boolean;
  seconds?: number;
  nano?: number;
}

export interface EntitlementActualObject {
  actualValue: object;
  allowedValueForCurrentEdition: object;
  requiredEdition: ReclaimEdition;
  requiredEditionValue: object;
  moreUsageAllowed: boolean;
}

export interface EntitlementDetails {
  minimumEdition: string;
  name: string;
  enabledForUser?: boolean;
}

export interface EntitlementValueObject {
  value: object;
  nextValue: object | null;
  nextEdition: ReclaimEdition | null;
}

export interface Entitlements {
  unlimitedHabitsEnabled?: boolean;
  unlimitedConnectedCalendarsEnabled?: boolean;
  unlimitedSyncEnabled?: boolean;
  customSyncTransparencyEnabled?: boolean;
  smart11CreationEnabled?: boolean;
  customSlackSyncStatusEnabled?: boolean;
  customConferenceBufferTitleEnabled?: boolean;
  maxDaysAheadForSchedulingLinks?: number;
  linearEnabled?: boolean;
  jiraEnabled?: boolean;
  todoistEnabled?: boolean;
  asanaEnabled?: boolean;
  clickupEnabled?: boolean;
  trelloEnabled?: boolean;
  mondayEnabled?: boolean;
}

export interface Event {
  schedulingLinkId?: string | null;
  schedulingLinkMeetingId?: string | null;
  underAssistControl?: boolean;
  assist: AssistDetails;
  reclaimEventType?: ReclaimEventType;
  personalSync?: boolean;
  reclaimManaged?: boolean;
  eventId: string;
  calendarId: number;
  key: EventKey;
  priority: PriorityLevel;
  prioritySource?: PrioritySource | null;
  title: string;
  titleSeenByOthers: string;
  location: string;
  onlineMeetingUrl?: string;
  description: string;
  sourceDetails: EventSourceDetails;
  status: EventStatus;
  published?: boolean;
  type: EventType;
  subType: EventSubType;
  meetingType: MeetingType;
  categoryOverride: EventCategory;
  eventStart: string;
  eventEnd: string;
  timeChunks: number;
  allocatedTimeChunks: number;
  recurringEventId: string;
  updated: string;
  recurringException?: boolean;
  recurrence: string;
  recurrenceMax: string;
  recurring?: boolean;
  recurringInstance?: boolean;
  private?: boolean;
  public?: boolean;
  color?: EventColor;
  organizer: string;
  numAttendees?: number;
  free?: boolean;
  category?: EventCategory;
  rsvpStatus: EventResponseStatus;
  requiresTravel: boolean;
  conferenceCall?: boolean;
  projectIds: number[];
  projects: Project[];
  etag: string | null;
  version?: string;
}

export enum EventStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  CANCELLED = "CANCELLED",
}

export type EventCategory = Colorized;

export enum EventColor {
  NONE = "NONE",
  LAVENDER = "LAVENDER",
  SAGE = "SAGE",
  GRAPE = "GRAPE",
  FLAMINGO = "FLAMINGO",
  BANANA = "BANANA",
  TANGERINE = "TANGERINE",
  PEACOCK = "PEACOCK",
  GRAPHITE = "GRAPHITE",
  BLUEBERRY = "BLUEBERRY",
  BASIL = "BASIL",
  TOMATO = "TOMATO",
}

export interface EventKey {
  eventId?: string;
  calendarId?: number;
}

export interface EventList {
  eventKeys: EventKey[];
}

export enum EventResponseStatus {
  None = "None",
  Organizer = "Organizer",
  Accepted = "Accepted",
  Declined = "Declined",
  TentativelyAccepted = "TentativelyAccepted",
  NotResponded = "NotResponded",
}

export interface EventSourceDetails {
  writable: boolean;
  calendarId: number;
  eventId: string;
  etag: string;
  eventKey: EventKey;
  base64Id: string;
  url: string;
  title: string;
  description: string;
}

export interface EventStorageSettings {
  enabled?: boolean;
  writeMode?: EventStorageSettingsWriteMode;
  readMode?: EventStorageSettingsReadMode;
  backfilled?: boolean;
}

export enum EventStorageSettingsReadMode {
  LEGACY = "LEGACY",
  CURRENT = "CURRENT",
}

export enum EventStorageSettingsWriteMode {
  LEGACY = "LEGACY",
  CURRENT = "CURRENT",
  BOTH = "BOTH",
}

export enum EventSubType {
  ONEONONE = "ONE_ON_ONE",
  STAFFMEETING = "STAFF_MEETING",
  OPREVIEW = "OP_REVIEW",
  EXTERNAL = "EXTERNAL",
  IDEATION = "IDEATION",
  FOCUS = "FOCUS",
  PRODUCTIVITY = "PRODUCTIVITY",
  TRAVEL = "TRAVEL",
  FLIGHT = "FLIGHT",
  TRAIN = "TRAIN",
  RECLAIM = "RECLAIM",
  VACATION = "VACATION",
  HEALTH = "HEALTH",
  ERRAND = "ERRAND",
  OTHERPERSONAL = "OTHER_PERSONAL",
  UNKNOWN = "UNKNOWN",
}

export enum EventType {
  MEETING = "MEETING",
  WORK = "WORK",
  LOGISTICS = "LOGISTICS",
  PERSONAL = "PERSONAL",
}

export type EventWithData = Event & { rawData: object };

export type EventsSubscription = Subscription & { subscriptionType?: SubscriptionType };

export type Executable = AccessibleObject & {
  declaringClass?: ClassObject;
  name?: string;
  modifiers?: number;
  typeParameters?: TypeVariableGenericDeclaration[];
  parameterTypes?: ClassObject[];
  parameterCount?: number;
  genericParameterTypes?: Type[];
  parameters?: Parameter[];
  exceptionTypes?: ClassObject[];
  genericExceptionTypes?: Type[];
  varArgs?: boolean;
  synthetic?: boolean;
  parameterAnnotations?: Annotation[][];
  declaredAnnotations?: Annotation[];
  annotatedReturnType?: AnnotatedType;
  annotatedReceiverType?: AnnotatedType;
  annotatedParameterTypes?: AnnotatedType[];
  annotatedExceptionTypes?: AnnotatedType[];
};

export type Field = AccessibleObject & {
  accessible?: boolean;
  declaringClass: ClassObject;
  name: string;
  modifiers: number;
  enumConstant?: boolean;
  synthetic?: boolean;
  type: ClassObject;
  genericType?: Type;
  declaredAnnotations?: Annotation[];
  annotatedType?: AnnotatedType;
};

export interface FlexibleTimeSpan {
  durationMin?: number;
  durationMax?: number;
}

export interface FocusSettings {
  enabled?: boolean;
}

export type GenericDeclaration = AnnotatedElement & { typeParameters?: TypeVariableGenericDeclaration[] };

export interface GoogleAddOnSettings {
  enabled?: boolean;
}

export interface GoogleUserInfo {
  sub?: string | null;
  email?: string | null;
  email_verified?: boolean | null;
  name?: string | null;
  given_name?: string | null;
  family_name?: string | null;
  picture?: string | null;
  locale?: string | null;
  hd?: string | null;
}

export enum HabitTemplateKey {
  LUNCH = "LUNCH",
  MEDITATION = "MEDITATION",
  TAKEAWALK = "TAKE_A_WALK",
  READING = "READING",
  WRITING = "WRITING",
  MORNINGCATCHUP = "MORNING_CATCHUP",
  AFTERNOONCATCHUP = "AFTERNOON_CATCHUP",
  MONTHLYMETRICSREVIEW = "MONTHLY_METRICS_REVIEW",
  SPRINTPLANNING = "SPRINT_PLANNING",
  CUSTOMERFEEDBACKREVIEW = "CUSTOMER_FEEDBACK_REVIEW",
  WEEKLYSTATUSREPORT = "WEEKLY_STATUS_REPORT",
  PRODUCTBACKLOGREVIEW = "PRODUCT_BACKLOG_REVIEW",
  PRODUCTREQUIREMENTS = "PRODUCT_REQUIREMENTS",
  RETROSPECTIVEPLANNING = "RETROSPECTIVE_PLANNING",
  PRODUCTLAUNCHPLANNING = "PRODUCT_LAUNCH_PLANNING",
  FOCUSTIME = "FOCUS_TIME",
  DEBUGGING = "DEBUGGING",
  FEATUREBACKLOG = "FEATURE_BACKLOG",
  TESTING = "TESTING",
  CUSTOMERRESEARCH = "CUSTOMER_RESEARCH",
  RECRUITMENT = "RECRUITMENT",
  QUARTERLYRESEARCHREPORT = "QUARTERLY_RESEARCH_REPORT",
  STRATEGICPLANNING = "STRATEGIC_PLANNING",
  DEPLOY = "DEPLOY",
  PRODUCTDOCUMENTATION = "PRODUCT_DOCUMENTATION",
  CODING = "CODING",
  ARCHITECTUREDESIGNREVIEW = "ARCHITECTURE_DESIGN_REVIEW",
  CODEREVIEW = "CODE_REVIEW",
  TRAINING = "TRAINING",
  TROUBLESHOOTESCALATEDISSUES = "TROUBLESHOOT_ESCALATED_ISSUES",
  REVIEWPRODUCTUPDATES = "REVIEW_PRODUCT_UPDATES",
  COMMUNITYENGAGEMENT = "COMMUNITY_ENGAGEMENT",
  SUPPORTTIME = "SUPPORT_TIME",
  SHARECUSTOMERFEEDBACK = "SHARE_CUSTOMER_FEEDBACK",
  SNIPPETTEMPLATEUPDATES = "SNIPPET_TEMPLATE_UPDATES",
  ADVERTISINGREVIEW = "ADVERTISING_REVIEW",
  COMPETITORRESEARCH = "COMPETITOR_RESEARCH",
  PRPLANNING = "PR_PLANNING",
  SEOREVIEW = "SEO_REVIEW",
  SOCIALMEDIAUPDATES = "SOCIAL_MEDIA_UPDATES",
  NEWSLETTER = "NEWSLETTER",
  BLOGWRITING = "BLOG_WRITING",
  WEBINARPREP = "WEBINAR_PREP",
  EMAILINBOUNDLEADS = "EMAIL_INBOUND_LEADS",
  KEYACCOUNTCHECKINS = "KEY_ACCOUNT_CHECK_INS",
  SALESPIPELINEREVIEW = "SALES_PIPELINE_REVIEW",
  WRITEPROPOSAL = "WRITE_PROPOSAL",
  CRMUPDATES = "CRM_UPDATES",
  PROSPECTING = "PROSPECTING",
  UPDATESALESPRESENTATIONS = "UPDATE_SALES_PRESENTATIONS",
  OUTBOUNDCALLS = "OUTBOUND_CALLS",
  WEEKLYDESIGNREVIEW = "WEEKLY_DESIGN_REVIEW",
  FREEDESIGNHOUR = "FREE_DESIGN_HOUR",
  USERRESEARCH = "USER_RESEARCH",
  DESIGNUSERTESTS = "DESIGN_USER_TESTS",
  DAILYDESIGNTIME = "DAILY_DESIGN_TIME",
  WIREFRAMES = "WIREFRAMES",
  USERFLOWDESIGN = "USER_FLOW_DESIGN",
  EMPLOYEERELATIONSREVIEW = "EMPLOYEE_RELATIONS_REVIEW",
  PTOREVIEW = "PTO_REVIEW",
  PRODUCTIVITYREVIEW = "PRODUCTIVITY_REVIEW",
  DEIREVIEW = "DEI_REVIEW",
  PROCESSBACKGROUNDCHECKS = "PROCESS_BACKGROUND_CHECKS",
  QUARTERLYTRAININGPREP = "QUARTERLY_TRAINING_PREP",
  APPLICANTREVIEW = "APPLICANT_REVIEW",
  EMPLOYEERECOGNITION = "EMPLOYEE_RECOGNITION",
  PAYROLLUPDATES = "PAYROLL_UPDATES",
  INTERVIEWPREP = "INTERVIEW_PREP",
  EMPLOYEESURVEYS = "EMPLOYEE_SURVEYS",
  UPDATEJOBPOSTINGS = "UPDATE_JOB_POSTINGS",
  EMPLOYEENEWSLETTERPREP = "EMPLOYEE_NEWSLETTER_PREP",
  REVIEWDEPARTMENTREQUIREMENTS = "REVIEW_DEPARTMENT_REQUIREMENTS",
  SECURITYREVIEWS = "SECURITY_REVIEWS",
  TEAMMEETINGPREP = "TEAM_MEETING_PREP",
  LEGALCOMPLIANCEREVIEW = "LEGAL_COMPLIANCE_REVIEW",
  VENDORREVIEWS = "VENDOR_REVIEWS",
  BUSINESSPERFORMANCEREVIEW = "BUSINESS_PERFORMANCE_REVIEW",
  REVIEWPROCUREMENTREQUESTS = "REVIEW_PROCUREMENT_REQUESTS",
  REVIEWSUPPLIERAGREEMENTS = "REVIEW_SUPPLIER_AGREEMENTS",
  UPDATEINTERNALPOLICIES = "UPDATE_INTERNAL_POLICIES",
  ANALYZETECHNOLOGYUSAGE = "ANALYZE_TECHNOLOGY_USAGE",
  REVIEWTEAMWORKFLOWS = "REVIEW_TEAM_WORKFLOWS",
  UPDATEOPERATIONALPROCEDURES = "UPDATE_OPERATIONAL_PROCEDURES",
  REVIEWFINANCIALREPORTS = "REVIEW_FINANCIAL_REPORTS",
  INVESTORUPDATE = "INVESTOR_UPDATE",
  MONTHENDFINANCIALSTATEMENTS = "MONTH_END_FINANCIAL_STATEMENTS",
  REVIEWFORECASTREPORTS = "REVIEW_FORECAST_REPORTS",
  BUDGETPLANNING = "BUDGET_PLANNING",
  REVIEWMONTHLYREPORTS = "REVIEW_MONTHLY_REPORTS",
  REVIEWEXPENSEREPORT = "REVIEW_EXPENSE_REPORT",
  PREPAREMONTHLYREPORTS = "PREPARE_MONTHLY_REPORTS",
  UPDATEJOURNALENTRIES = "UPDATE_JOURNAL_ENTRIES",
  REVIEWTIMESHEETS = "REVIEW_TIME_SHEETS",
  PROCESSPAYMENTS = "PROCESS_PAYMENTS",
  REVIEWACCOUNTSPAYABLE = "REVIEW_ACCOUNTS_PAYABLE",
  TAXPLANNING = "TAX_PLANNING",
  WEEKDAYSTUDY = "WEEKDAY_STUDY",
  GROCERYSHOPPING = "GROCERY_SHOPPING",
  LAUNDRY = "LAUNDRY",
  INTERNSHIPAPPLICATIONS = "INTERNSHIP_APPLICATIONS",
  WEEKENDSTUDY = "WEEKEND_STUDY",
  EXERCISE = "EXERCISE",
  CLEANHOUSE = "CLEAN_HOUSE",
  CALLFAMILY = "CALL_FAMILY",
  WORKONTHESIS = "WORK_ON_THESIS",
}

export type HttpParameters = ConvertibleMultiValuesString & ConvertibleValuesListString;

export enum IconType {
  LIGHT = "LIGHT",
  COFFEE = "COFFEE",
  BOLT = "BOLT",
  COMMENT = "COMMENT",
  STAR = "STAR",
  AIRPLANE = "AIRPLANE",
  TWINKLE = "TWINKLE",
  COMPASS = "COMPASS",
  SUN = "SUN",
  SMILE = "SMILE",
}

export enum IneligibilityReason {
  MAINACCOUNTPRIMARY = "MAIN_ACCOUNT_PRIMARY",
  SYNCEDALREADY = "SYNCED_ALREADY",
  FREEBUSYONLY = "FREE_BUSY_ONLY",
}

/**
 * For use in response to an HTTP Get call.
 */
export interface InterpretedPlanGet {
  planType: string;
  id: string;
  planDetails: PlanView;
}

/**
 * For use in HTTP Get calls that return a list of InterpretedPlanGet interpretedPlans
 */
export interface InterpretedPlansGet {
  /** the list of interpreted plans */
  interpretedPlans: InterpretedPlanGet[];
}

export interface InviteeEligibility {
  oneOnOneInviteeEligibility: OneOnOneInviteeEligibility;
  shouldOfferTeamInvite: boolean;
  resolvedEmail: string;
  resolvedId: string | null;
}

export type IteratorString = object;

export interface JiraIntegration {
  id?: string;
  installation?: string;
  available_projects?: JiraProject[];
  selected_project_keys?: string[];
}

export interface JiraIntegrationPatch {
  installation?: string;
  selected_project_keys?: string[];
}

export interface JiraProject {
  project_key?: string;
  project_name?: string;
}

export interface Key {
  algorithm?: string;
  format?: string;
  encoded?: string[];
}

export interface LinearIntegration {
  id?: string;
  requiresLabel?: boolean;
  availableTeams?: AbstractLinearTeam[];
  selectedTeamIds?: string[];
}

export interface LinearIntegrationPatch {
  requiresLabel?: boolean | null;
  selectedTeamIds?: string[] | null | null;
}

export interface LinearTaskDetails {
  taskId?: number;
  owner?: boolean;
  status?: TaskStatus;
}

export enum LinkInvalidationReason {
  ACCESSLOST = "ACCESS_LOST",
  USERINITIATED = "USER_INITIATED",
}

export interface LocalTimeInterval {
  start: string;
  end: string;
  duration?: Duration;
}

export interface Locale {
  language: string;
  script?: string;
  country: string;
  variant: string;
  extensionKeys?: string[];
  unicodeLocaleAttributes?: string[];
  unicodeLocaleKeys?: string[];
  ISO3Language?: string;
  ISO3Country?: string;
  displayLanguage?: string;
  displayScript?: string;
  displayCountry?: string;
  displayVariant?: string;
  displayName?: string;
}

export enum LockState {
  MANUALLYLOCKED = "MANUALLY_LOCKED",
  ADJUSTED = "ADJUSTED",
  UPCOMINGWINDOW = "UPCOMING_WINDOW",
  MANUALLYUNLOCKED = "MANUALLY_UNLOCKED",
  DELETED = "DELETED",
  DECLINED = "DECLINED",
  INTHEPAST = "IN_THE_PAST",
}

export type LogContext = object;

export interface MeetingLimit {
  limit: number;
  period: MeetingLimitMeetingLimitPeriod;
}

export enum MeetingLimitMeetingLimitPeriod {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
}

export interface MeetingLocation {
  conferenceType: ConferenceType;
  location?: string | null;
  disabled?: boolean | null;
}

export enum MeetingType {
  DIRECT11 = "DIRECT_11",
  PEER11 = "PEER_11",
  SKIP11 = "SKIP_11",
  XFUNC11 = "XFUNC_11",
  OTHER11 = "OTHER_11",
  TEAM = "TEAM",
  XFUNCTEAM = "XFUNC_TEAM",
  EXEC = "EXEC",
  SKIPTEAM = "SKIP_TEAM",
  ALLHANDS = "ALL_HANDS",
  ONBOARDINGTRAINING = "ONBOARDING_TRAINING",
  COMPANYEVENT = "COMPANY_EVENT",
  BOARDMEETING = "BOARD_MEETING",
  OTHERSTAFF = "OTHER_STAFF",
  DEALREVIEW = "DEAL_REVIEW",
  EXECUTIONREVIEW = "EXECUTION_REVIEW",
  DEMO = "DEMO",
  PLANNINGMEETING = "PLANNING_MEETING",
  RETRO = "RETRO",
  EMERGENCY = "EMERGENCY",
  PEOPLEREVIEW = "PEOPLE_REVIEW",
  OTHEROP = "OTHER_OP",
  ROADMAP = "ROADMAP",
  CUSTMEETING = "CUST_MEETING",
  CUSTRESEARCH = "CUST_RESEARCH",
  CUSTURGENT = "CUST_URGENT",
  RECRUITSCREEN = "RECRUIT_SCREEN",
  OTHEREXTERNAL = "OTHER_EXTERNAL",
  DESIGNBRAIN = "DESIGN_BRAIN",
  TECHREVIEW = "TECH_REVIEW",
  KICKOFF = "KICKOFF",
  REQBRAIN = "REQ_BRAIN",
  OTHERIDEATION = "OTHER_IDEATION",
  UNKNOWN = "UNKNOWN",
}

export interface MessagePost {
  category: string;
  subtype: string;
  message: string;
}

export type Method = Executable &
  AccessibleObject & {
    accessible?: boolean;
    declaringClass: ClassObject;
    name: string;
    modifiers: number;
    typeParameters?: TypeVariableMethod[];
    returnType: ClassObject;
    genericReturnType?: Type;
    parameterTypes: ClassObject[];
    parameterCount?: number;
    genericParameterTypes?: Type[];
    exceptionTypes?: ClassObject[];
    genericExceptionTypes?: Type[];
    bridge?: boolean;
    varArgs?: boolean;
    synthetic?: boolean;
    default?: boolean;
    defaultValue?: object;
    declaredAnnotations?: Annotation[];
    parameterAnnotations: Annotation[][];
    annotatedReturnType?: AnnotatedType;
  };

export interface Module {
  named?: boolean;
  name?: string;
  classLoader?: ClassLoader;
  descriptor?: ModuleDescriptor;
  layer?: ModuleLayer;
  packages?: string[];
  annotations?: Annotation[];
  declaredAnnotations?: Annotation[];
}

export interface ModuleDescriptor {
  open?: boolean;
  automatic?: boolean;
}

export type ModuleLayer = object;

/**
 * Reflects what the current moment of time is for a user. Payload includes the "primary" active event (or none, if nothing is active) as well as any additional events that might also be in flight but are deemed by Reclaim as secondary/less important. Additionally, if the active event is a Reclaim-managed assignment (ex: task, habit, smart 1:1), it is also included in the payload. When there are multiple active events, the logic for what is considered "primary" vs "secondary" is an internal implementation, but generally speaking it should match the same logic that the Slack integration uses for determining which status to display when there are overlaps.
 */
export interface Moment {
  event?: Event | null;
  assignment?: TaskOrHabit | null;
  additionalEvents: Event[];
  now: string;
}

export type MomentSubscription = Subscription & { subscriptionType?: SubscriptionType };

export enum Month {
  JANUARY = "JANUARY",
  FEBRUARY = "FEBRUARY",
  MARCH = "MARCH",
  APRIL = "APRIL",
  MAY = "MAY",
  JUNE = "JUNE",
  JULY = "JULY",
  AUGUST = "AUGUST",
  SEPTEMBER = "SEPTEMBER",
  OCTOBER = "OCTOBER",
  NOVEMBER = "NOVEMBER",
  DECEMBER = "DECEMBER",
}

export type NamedPackage = object;

export type ObservedUser = LogContext & { id?: string; trackingCode?: string; edition?: ReclaimEdition };

export interface OnboardedState {
  habitTemplateKeys: HabitTemplateKey[];
  hasTravelBuffer: boolean;
  hasDecompressionTime: boolean;
}

export enum OneOnOneInviteeEligibility {
  RECLAIMUSER = "RECLAIM_USER",
  NONRECLAIMUSERWCALENDARACCESS = "NON_RECLAIM_USER_W_CALENDAR_ACCESS",
  NONRECLAIMUSER = "NON_RECLAIM_USER",
}

export type OneOnOneSubscription = Subscription & { subscriptionType?: SubscriptionType };

export interface OpenAISettings {
  enabled?: boolean;
}

export type Package = NamedPackage & {
  name?: string;
  specificationTitle?: string;
  specificationVersion?: string;
  specificationVendor?: string;
  implementationTitle?: string;
  implementationVersion?: string;
  implementationVendor?: string;
  sealed?: boolean;
  annotations?: Annotation[];
  declaredAnnotations?: Annotation[];
};

export interface Parameter {
  namePresent?: boolean;
  declaringExecutable?: Executable;
  modifiers: number;
  name: string;
  parameterizedType?: Type;
  type?: ClassObject;
  annotatedType?: AnnotatedType;
  implicit?: boolean;
  synthetic?: boolean;
  varArgs?: boolean;
  declaredAnnotations?: Annotation[];
  annotations?: Annotation[];
}

export interface PermissionCollection {
  readOnly?: boolean;
}

export interface Permissions {
  canEdit: boolean;
  canEnable: boolean;
  canDelete: boolean;
}

/**
 * Interface to tag calendar plan view models meant to be sent to client applications.
 */
export type PlanView = object;

export interface PlannerActionIntermediateResult {
  events: Event[];
  taskOrHabit?: TaskOrHabit | null;
  timeoutReached?: boolean | null;
  userInfoMessage: string | null;
}

export interface Principal {
  name?: string;
}

export interface PrioritiesSettings {
  enabled?: boolean;
}

export interface Prioritizable {
  priority?: PriorityLevel;
  priorityUntil?: string;
}

export enum PriorityLevel {
  P1 = "P1",
  P2 = "P2",
  P3 = "P3",
  P4 = "P4",
  PRIORITIZE = "PRIORITIZE",
  DEFAULT = "DEFAULT",
}

export enum PrioritySource {
  MANUAL = "MANUAL",
  AI = "AI",
  RULES = "RULES",
}

export interface ProductUsageReport {
  currentEdition: ReclaimEdition;
  actuals: Record<string, EntitlementActualObject>;
  terminalActuals: Record<string, EntitlementActualObject>;
  usageEdition: ReclaimEdition;
  terminalEdition: ReclaimEdition;
  recommendedEdition: ReclaimEdition;
  overage: boolean;
}

export type ProductUsageSubscription = Subscription & { subscriptionType?: SubscriptionType };

export interface Project {
  id: number;
  name: string;
  priority: PriorityLevel;
  priorityUntil?: string;
  prioritized?: boolean;
  color: EventColor;
  created: string;
  updated: string;
  taskIds?: number[];
  eventKeys?: EventKey[] | null | null;
  calendar?: Calendar | null;
  prioritizableType?: string;
}

export enum ProjectInclude {
  ID = "ID",
  FULL = "FULL",
}

export interface ProjectsSettings {
  enabled?: boolean;
}

export interface ProtectionDomain {
  codeSource?: CodeSource;
  classLoader?: ClassLoader;
  principals?: Principal[];
  permissions: PermissionCollection;
}

export type PublicKey = Key;

export interface QuestSettings {
  enabled?: boolean;
}

export type QuestSubscription = Subscription & { subscriptionType?: SubscriptionType };

export enum QuestType {
  WELCOMETORECLAIM = "WELCOME_TO_RECLAIM",
  SETAVAILABILITY = "SET_AVAILABILITY",
  SYNCCALENDAR = "SYNC_CALENDAR",
  SETSCHEDULINGHOURS = "SET_SCHEDULING_HOURS",
  INSTALLGCALADDON = "INSTALL_GCAL_ADDON",
  INSTALLSLACKINTEGRATION = "INSTALL_SLACK_INTEGRATION",
  HABITSOVERVIEW = "HABITS_OVERVIEW",
  CREATEANDCUSTOMIZEHABITS = "CREATE_AND_CUSTOMIZE_HABITS",
  MANAGEHABITS = "MANAGE_HABITS",
  SCHEDULINGLINKSOVERVIEW = "SCHEDULING_LINKS_OVERVIEW",
  SETUPSCHEDULINGLINKS = "SETUP_SCHEDULING_LINKS",
  SETUPTEAMLINKS = "SETUP_TEAM_LINKS",
  SMARTONEONONEOVERVIEW = "SMART_ONE_ON_ONE_OVERVIEW",
  SETUPFIRSTSMARTONEONONE = "SETUP_FIRST_SMART_ONE_ON_ONE",
  MANAGESMARTONEONONE = "MANAGE_SMART_ONE_ON_ONE",
  TASKOVERVIEW = "TASK_OVERVIEW",
  ADDFIRSTTASK = "ADD_FIRST_TASK",
  ADDTASKINTEGRATION = "ADD_TASK_INTEGRATION",
  MANAGETASKS = "MANAGE_TASKS",
  SETTASKDEFAULTS = "SET_TASK_DEFAULTS",
  AUTOSCHEDULEBREAKANDTRAVELTIME = "AUTO_SCHEDULE_BREAK_AND_TRAVEL_TIME",
  SETUPNOMEETINGDAYS = "SETUP_NO_MEETING_DAYS",
  CUSTOMIZEEVENTCOLORCODING = "CUSTOMIZE_EVENT_COLOR_CODING",
  TRACKANDANALYZEPRODUCTIVITY = "TRACK_AND_ANALYZE_PRODUCTIVITY",
  ADDMEMBERSTOTEAM = "ADD_MEMBERS_TO_TEAM",
  SELECTPLAN = "SELECT_PLAN",
}

export enum ReclaimEdition {
  NONE = "NONE",
  TRIAL = "TRIAL",
  ASSISTANT = "ASSISTANT",
  PRO = "PRO",
  TEAM = "TEAM",
  REWARD = "REWARD",
  LEGACYPROTRIAL = "LEGACY_PRO_TRIAL",
  LEGACYTEAMTRIAL = "LEGACY_TEAM_TRIAL",
  LITE = "LITE",
  STARTER = "STARTER",
  BUSINESS = "BUSINESS",
  TRIALBUSINESS = "TRIAL_BUSINESS",
  TRIALBUSINESS12W = "TRIAL_BUSINESS_12W",
  TRIALBUSINESSFORLEGACYPLANS = "TRIAL_BUSINESS_FOR_LEGACY_PLANS",
  ENTERPRISE = "ENTERPRISE",
  TEAMREWARD = "TEAM_REWARD",
}

export enum ReclaimEventType {
  USER = "USER",
  SYNC = "SYNC",
  HABITASSIGNMENT = "HABIT_ASSIGNMENT",
  ONEONONEASSIGNMENT = "ONE_ON_ONE_ASSIGNMENT",
  TASKASSIGNMENT = "TASK_ASSIGNMENT",
  CONFBUFFER = "CONF_BUFFER",
  TRAVELBUFFER = "TRAVEL_BUFFER",
  SCHEDULINGLINKMEETING = "SCHEDULING_LINK_MEETING",
  UNKNOWN = "UNKNOWN",
}

export interface RecordComponent {
  name?: string;
  type?: ClassObject;
  genericSignature?: string;
  genericType?: Type;
  annotatedType?: AnnotatedType;
  accessor?: Method;
  annotations?: Annotation[];
  declaredAnnotations?: Annotation[];
  declaringRecord?: ClassObject;
}

export enum Recurrence {
  Daily = "Daily",
  Weekly = "Weekly",
  Biweekly = "Biweekly",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
}

export enum RecurringAssignmentAttendeeStatus {
  INVITING = "INVITING",
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  ASSUMEDACCEPTED = "ASSUMED_ACCEPTED",
}

export interface RecurringAssignmentInstance {
  instanceId: number;
  eventId: string;
  eventKey?: EventKey;
  eventStatus: EventStatus;
  etag?: string;
  attendees?: object[];
  start: string;
  end: string;
}

export enum RecurringAssignmentStatus {
  NEW = "NEW",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  SCHEDULED = "SCHEDULED",
  INVITEEERROR = "INVITEE_ERROR",
}

export enum RecurringAssignmentType {
  ONEONONE = "ONE_ON_ONE",
  DAILYHABIT = "DAILY_HABIT",
  TASK = "TASK",
}

export enum RecurringEventUpdatePeriod {
  ALL = "ALL",
  SINGLE = "SINGLE",
}

export interface RecurringOneOnOne {
  id: number;
  title: string;
  created: string;
  updated: string;
  snoozeUntil?: string | null;
  enabled: boolean;
  idealTime: string;
  idealDay?: DayOfWeek | null;
  invitee: ThinPerson;
  inviteeTimePolicyType?: TimePolicyType | null;
  inviteeOneOffPolicy?: TimePolicy | null;
  inviteeTimeSchemeId?: string | null;
  displayTitle: string;
  organizerUserId: string;
  instances: RecurringAssignmentInstance[];
  duration: number;
  conferenceType?: ConferenceType | null;
  conferenceData?: object | null;
  recurringEventId: string;
  inviteKey?: string | null;
  additionalDescription?: string | null;
  location?: string | null;
  status: RecurringAssignmentStatus;
  recurrence: Recurrence;
  organizersTimeZone: string;
  deleted: boolean;
  rrule: string;
  organizer?: ThinPerson | null;
  adjusted: boolean;
  effectiveTimePolicy: TimePolicy;
  organizerTimePolicy: AttendeeTimePolicyView;
  inviteeTimePolicy: AttendeeTimePolicyView;
  organizerTimePolicyType?: TimePolicyType | null;
  organizerOneOffPolicy?: TimePolicy | null;
  organizerTimeSchemeId?: string | null;
  priority: PriorityLevel;
  prioritizableType?: string;
}

export interface ReferralStats {
  signedUp: number;
  active: number;
  deleted: number;
  claimed?: number;
  availableCredits?: number;
}

export interface Reindex {
  relativeTaskId: number;
  reindexDirection: ReindexDirection;
}

export enum ReindexDirection {
  Before = "before",
  After = "after",
}

export enum ResourceType {
  User = "user",
  Task = "task",
  Tag = "tag",
  Project = "project",
  Workspace = "workspace",
  Story = "story",
  Webhook = "webhook",
  UserTaskList = "user_task_list",
  CustomField = "custom_field",
  Attachment = "attachment",
  Team = "team",
}

export interface Resources {
  taskList: Task[];
  habitList: DailyHabit[];
  oneOnOneList: RecurringOneOnOne[];
  schedulingLinkList: SchedulingLink[];
}

export interface RichAppendixEntry {
  missingScopes: string[];
  values: Record<string, AnalyticsServiceRichContact>;
}

export interface RsvpResponseBody {
  responseStatus: EventResponseStatus;
  sendUpdates: boolean;
  comment?: string | null;
  period?: RsvpResponsePeriod | null;
}

export enum RsvpResponsePeriod {
  SINGLE = "SINGLE",
  FORWARD = "FORWARD",
  ALL = "ALL",
}

export interface SchedulingLink {
  id: string;
  title: string;
  slug: string;
  pageSlug: string;
  description?: string | null;
  enabled: boolean;
  hidden: boolean;
  mainOrganizerId: string;
  organizers: SchedulingLinkOrganizerView[];
  effectiveTimePolicy: TimePolicy;
  durations: number[];
  defaultDuration?: number | null;
  delayStart: DelayStart;
  delayStartUnits?: number | null;
  daysIntoFuture?: number | null;
  startDate?: string | null;
  endDate?: string | null;
  priority?: SchedulingPriority | null;
  locationOptions?: MeetingLocation[] | null | null;
  defaultLocationIndex?: number | null;
  iconType: IconType;
  organizerRefCode?: string | null;
  parentId?: string | null;
  meetingTitle?: string | null;
  linkGroupId?: string | null;
  linkGroupName?: string | null;
  linkGroupDescription?: string | null;
  derivativeContext?: DerivativeContext | null;
  reminderEmailDurationMins?: number | null;
  reminderEmailMessage?: string | null;
  targetCalendarId: number;
  invalidationReason?: LinkInvalidationReason | null;
  permissions: Permissions;
  meetingLimit?: MeetingLimit | null;
  survey?: Survey | null;
}

export enum SchedulingLinkMemberStatus {
  ACTIVE = "ACTIVE",
  UNPAID = "UNPAID",
  NOTINTEAM = "NOT_IN_TEAM",
  ACCOUNTDELETED = "ACCOUNT_DELETED",
  UNAUTHORIZED = "UNAUTHORIZED",
}

export interface SchedulingLinkOrganizerView {
  organizer: ThinPerson;
  role: SchedulingLinkRole;
  timezone: ZoneId;
  timePolicyType: TimePolicyType;
  oneOffPolicy?: TimePolicy | null;
  timeSchemeId?: string | null;
  resolvedTimePolicy: TimePolicy;
  status?: SchedulingLinkMemberStatus | null;
}

export enum SchedulingLinkRole {
  MAINORGANIZER = "MAIN_ORGANIZER",
  COORGANIZER = "CO_ORGANIZER",
}

export interface SchedulingLinkSettings {
  enabled?: boolean;
  note?: string;
  remindersMigrated?: boolean;
}

export type SchedulingLinkSubscription = Subscription & { subscriptionType?: SubscriptionType };

export enum SchedulingPriority {
  P1 = "P1",
  P2 = "P2",
  P3 = "P3",
  P4 = "P4",
  DEFAULT = "DEFAULT",
  HIGH = "HIGH",
}

export interface ScimEmail {
  type?: string;
  value?: string;
  display?: string;
  primary?: boolean;
}

export interface ScimMeta {
  resourceType?: string;
  created?: string;
}

export interface ScimName {
  givenName: string;
  familyName: string;
}

export interface ScimOperations {
  op: string;
  path?: string;
  value: object;
}

export interface ScimPatch {
  schemas: string[];
  Operations: ScimOperations[];
}

export type ScimResource = object;

export interface ScimResourceList {
  totalResults?: number;
  itemsPerPage: number;
  startIndex: number;
  schemas?: string[];
  Resources: ScimResource[];
}

export interface ScimUser {
  userName?: string;
  name?: ScimName;
  id?: string;
  emails?: ScimEmail[];
  active?: boolean;
  externalId?: string;
  meta?: ScimMeta;
  schemas?: string[];
}

export interface Settings {
  autoAddHangouts?: boolean;
  defaultEventLength?: number;
  weekStart?: number;
  format24HourTime?: boolean;
  locale?: string;
  showDeclinedEvents?: boolean;
  timezone?: string;
  dateFieldOrder?: DateFieldOrder;
}

export enum SkipReason {
  ManagedByReclaim = "managed_by_reclaim",
  ReclaimClone = "reclaim_clone",
  CalendarBridge = "calendar_bridge",
  SyncThemCalendars = "sync_them_calendars",
  CancelledRecurring = "cancelled_recurring",
  SkipExisting = "skip_existing",
  AlreadyCancelled = "already_cancelled",
  AllDay = "all_day",
  Nosync = "nosync",
  WorkParticipating = "work_participating",
  Declined = "declined",
  PrivateFree = "private_free",
  WorkingHours = "working_hours",
  InvalidId = "invalid_id",
}

export interface SlackOutsideHoursSetting {
  policy: string;
  message: string | null;
  dnd: boolean;
}

export interface SlackSettings {
  enabled?: boolean;
  personalSyncNotifyNew?: boolean;
  personalSyncNotifyUpdated?: boolean;
  personalSyncNotifyDeleted?: boolean;
  personalSyncNotificationsIncludingSelf?: boolean;
  habitNotifyUpcoming?: boolean;
  taskNotifyUpcoming?: boolean;
  travelNotify?: boolean;
  outsideHoursMigrated?: boolean;
  statusSync?: SlackSettingsStatusSync;
  statusEnabled?: boolean;
  privateStatus?: SlackStatusSetting;
  statuses?: Record<string, SlackStatusSetting>;
  outSideHours?: SlackOutsideHoursSetting;
}

export enum SlackSettingsStatusSync {
  OFF = "OFF",
  DEFAULT = "DEFAULT",
  BUSY = "BUSY",
  CUSTOM = "CUSTOM",
  TEAM = "TEAM",
}

export interface SlackStatusSetting {
  emoji?: string;
  template?: string;
  dnd?: boolean;
  enabled?: boolean;
}

export interface SmartOneOnOneSettings {
  enabled?: boolean;
}

export enum SnoozeOption {
  FROMNOW15M = "FROM_NOW_15M",
  FROMNOW30M = "FROM_NOW_30M",
  FROMNOW1H = "FROM_NOW_1H",
  FROMNOW2H = "FROM_NOW_2H",
  FROMNOW4H = "FROM_NOW_4H",
  TOMORROW = "TOMORROW",
  INTWODAYS = "IN_TWO_DAYS",
  NEXTWEEK = "NEXT_WEEK",
}

export interface Snoozeable {
  snoozeUntil?: string | null;
}

export type SubscribeAction = WebSocketRequestAction & { subscription?: Subscription; subscriptionId?: string };

export interface Subscription {
  subscriptionType?: SubscriptionType;
}

export enum SubscriptionType {
  Events = "Events",
  SyncStatus = "SyncStatus",
  DailyHabit = "DailyHabit",
  OneOnOne = "OneOnOne",
  Task = "Task",
  AssistCompleted = "AssistCompleted",
  AssistPlanned = "AssistPlanned",
  NewSubscription = "NewSubscription",
  Moment = "Moment",
  Quest = "Quest",
  ProductUsage = "ProductUsage",
  User = "User",
  Team = "Team",
  SchedulingLink = "SchedulingLink",
  Analytics = "Analytics",
}

export interface Survey {
  questions: SurveyQuestion[];
}

export interface SurveyQuestion {
  question: string;
  type: SurveyQuestionType;
}

export enum SurveyQuestionType {
  ONELINE = "ONE_LINE",
}

export enum SyncAllDayEvents {
  SKIPALL = "SKIP_ALL",
  SYNCBUSY = "SYNC_BUSY",
  SYNCALL = "SYNC_ALL",
}

export enum SyncCalendarType {
  BUSINESS = "BUSINESS",
  PERSONAL = "PERSONAL",
  TRAVEL = "TRAVEL",
}

export interface SyncEvent {
  sourceEtag?: string;
  sourceEventId?: string;
  sourceCalendarId?: number;
  targetCalendarId?: number;
  targetEventId?: string;
  data?: object;
  responseData?: object;
  eventSourceDetails?: EventSourceDetails;
  eventStart?: string;
  synced?: boolean;
  recurrence?: string;
  exceptionClass?: string;
  exceptionMessage?: string;
  recurringEventId?: string;
  skipReason?: SkipReason;
  oldTargetEventId?: string;
}

export interface SyncFeatureSettings {
  enabled?: boolean;
}

export interface SyncSettings {
  transparency?: SyncTransparency;
  workingHours?: boolean;
  defaultType?: EventType;
  type?: SyncCalendarType;
  color?: EventColor;
  allDayEvents?: SyncAllDayEvents;
  syncReclaimEvents?: boolean;
}

export type SyncStatusSubscription = Subscription & { subscriptionType?: SubscriptionType };

export enum SyncTransparency {
  DEFAULT = "DEFAULT",
  SEMIPRIVATE = "SEMI_PRIVATE",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
  SEMIPRIVATEALT = "SEMI_PRIVATE_ALT",
}

export interface Task {
  id: number;
  title: string;
  notes: string;
  eventCategory: EventCategory;
  eventSubType: EventSubType;
  eventColor?: EventColor | null;
  status: TaskStatus;
  timeChunksRequired: number;
  timeChunksSpent: number;
  timeChunksRemaining: number;
  minChunkSize: number;
  maxChunkSize: number;
  alwaysPrivate: boolean;
  deleted: boolean;
  index: number;
  due?: string | null;
  created: string;
  updated: string;
  finished?: string | null;
  snoozeUntil?: string | null;
  adjusted: boolean;
  atRisk: boolean;
  instances?: TaskInstance[] | null | null;
  timeSchemeId: string;
  priority: PriorityLevel;
  prioritizableType?: string;
  type?: string;
}

export interface TaskAutoWorkflowSettings {
  category?: TaskAutoWorkflowType;
  durationToWaitInDays?: number;
}

export enum TaskAutoWorkflowType {
  NOTHING = "NOTHING",
  REOPEN = "REOPEN",
  CLOSE = "CLOSE",
}

export enum TaskCategory {
  WORK = "WORK",
  PERSONAL = "PERSONAL",
  BOTH = "BOTH",
}

export interface TaskDefaults {
  timeChunksRequired?: number;
  commsTimeChunksRequired?: number;
  delayedStartInMinutes?: number;
  dueInDays?: number | null;
  category?: EventCategory;
  alwaysPrivate?: boolean;
  minChunkSize?: number;
  maxChunkSize?: number;
  timeSchemeId?: string | null;
  priority?: string;
}

export interface TaskInstance {
  taskId: number;
  eventId: string;
  eventKey: EventKey;
  status: TaskInstanceStatus;
  start: string;
  end: string;
  index: number;
  pinned: boolean;
}

export enum TaskInstanceStatus {
  DONE = "DONE",
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  ABORTED = "ABORTED",
}

export interface TaskOrHabit {
  id?: number;
  type?: AssistType;
  recurringAssignmentType?: RecurringAssignmentType;
  alwaysPrivate?: boolean;
  title?: string;
  eventSubType?: EventSubType;
  eventCategory?: EventCategory;
  eventColor?: EventColor;
  invitees?: ThinPerson[];
  defendedDescription?: string;
  additionalDescription?: string;
  priority?: PriorityLevel;
  location?: string;
  updated?: string;
}

export interface TaskSettings {
  enabled?: boolean;
  googleTasks?: boolean;
  defaults: TaskDefaults;
  autoWorkflowSettings?: TaskAutoWorkflowSettings;
}

export enum TaskStatus {
  NEW = "NEW",
  SCHEDULED = "SCHEDULED",
  INPROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
  ARCHIVED = "ARCHIVED",
}

export type TaskSubscription = Subscription & { subscriptionType?: SubscriptionType };

export type TeamSubscription = Subscription & { subscriptionType?: SubscriptionType };

export interface TemporalUnit {
  duration?: Duration;
  durationEstimated?: boolean;
  dateBased?: boolean;
  timeBased?: boolean;
}

export interface ThinCalendar {
  id: number;
  timezone: ZoneId;
  calendarId: string;
  lastSynced: string;
  credentialId: number;
}

export interface ThinPerson {
  userId?: string | null;
  email: string;
  name?: string;
  avatarUrl?: string;
  firstName?: string | null;
  lastName?: string | null;
}

export interface TimePolicy {
  startOfWeek?: DayOfWeek;
  endOfWeek?: DayOfWeek;
  dayHours: Record<string, DayHours>;
}

export enum TimePolicyType {
  WORK = "WORK",
  PERSONAL = "PERSONAL",
  MEETING = "MEETING",
  ONEOFF = "ONE_OFF",
  INHERITED = "INHERITED",
  CUSTOM = "CUSTOM",
}

export interface TimeScheme {
  id: string;
  userId: string;
  policyType: TimePolicyType;
  policy: TimePolicy;
  taskCategory?: TaskCategory | null;
  title: string;
  description: string;
  deleted?: string | null;
  features: ReclaimEventType[];
}

export interface TimeSchemeCreateRequest {
  title: string;
  description: string;
  policyType: TimePolicyType;
  policy: TimePolicy;
  taskCategory: TaskCategory | null;
  features: ReclaimEventType[];
}

export interface TimeSchemeIntegrationRule {
  id: string;
  userId: string;
  labels?: string[] | null | null;
  projectIds?: string[] | null | null;
  action: TimeSchemeIntegrationRuleAction;
  timeSchemeId?: string | null;
  serviceType: TimeSchemeIntegrationRuleServiceType;
  index: number;
  ruleType: TimeSchemeIntegrationRuleType;
  workspaceId?: string | null;
}

export enum TimeSchemeIntegrationRuleAction {
  SYNCTO = "SYNC_TO",
  DONTSYNC = "DONT_SYNC",
}

export interface TimeSchemeIntegrationRuleCreateRequest {
  labels?: string[] | null | null;
  projectIds?: string[] | null | null;
  action: TimeSchemeIntegrationRuleAction;
  serviceType: TimeSchemeIntegrationRuleServiceType;
  timeSchemeId?: string | null;
  ruleType: TimeSchemeIntegrationRuleType;
  workspaceId?: string | null;
}

export interface TimeSchemeIntegrationRuleReindexRequest {
  serviceType: TimeSchemeIntegrationRuleServiceType;
  workspaceId?: string | null;
  newIndex: number;
  shiftDirection: number;
}

export enum TimeSchemeIntegrationRuleServiceType {
  TODOIST = "TODOIST",
  CLICKUP = "CLICK_UP",
}

export enum TimeSchemeIntegrationRuleType {
  CASE = "CASE",
  OTHERWISE = "OTHERWISE",
}

export interface TimeSchemeIntegrationRuleUpdateRequest {
  labels?: string[] | null | null;
  projectIds?: string[] | null | null;
  action?: TimeSchemeIntegrationRuleAction | null;
  timeSchemeId?: string | null;
}

export interface TimeSchemeUpdateRequest {
  title?: string | null;
  description?: string | null;
  policyType?: TimePolicyType | null;
  policy?: TimePolicy | null;
  taskCategory?: TaskCategory | null;
  features?: ReclaimEventType[] | null | null;
}

export interface TimeSpentDuringWeek {
  eventType: EventType;
  eventTypeFriendly: string;
  hours: number;
  percentChange: number;
  noChange: boolean;
  increased: boolean;
  infinity: boolean;
}

export interface Timestamp {
  timestamp: string;
  signerCertPath: CertPath;
}

export interface TodoistIntegration {
  id?: string;
  requiresLabel?: boolean;
  availableProjects?: TodoistProject[];
  selectedProjectIds?: string[];
  labels?: TodoistLabel[];
}

export interface TodoistIntegrationDetails {
  id?: string;
  availableProjects?: TodoistProject[];
  labels?: TodoistLabel[];
  labelNames?: string[];
  lastSyncedAt?: string | null;
}

export interface TodoistIntegrationPatch {
  requiresLabel?: boolean | null;
  selectedProjectIds?: string[] | null | null;
}

export interface TodoistLabel {
  id?: string;
  name?: string;
}

export interface TodoistProject {
  id?: string;
  name?: string;
  parent_id?: string | null;
  is_archived?: boolean;
  is_deleted?: boolean;
}

export interface Type {
  typeName?: string;
}

export type TypeVariableClassListString = Type &
  AnnotatedElement & {
    bounds?: Type[];
    genericDeclaration?: ClassListString;
    name?: string;
    annotatedBounds?: AnnotatedType[];
  };

export type TypeVariableClassObject = Type &
  AnnotatedElement & {
    bounds?: Type[];
    genericDeclaration?: ClassObject;
    name?: string;
    annotatedBounds?: AnnotatedType[];
  };

export type TypeVariableConstructorObject = Type &
  AnnotatedElement & {
    bounds?: Type[];
    genericDeclaration?: ConstructorObject;
    name?: string;
    annotatedBounds?: AnnotatedType[];
  };

export type TypeVariableGenericDeclaration = Type &
  AnnotatedElement & {
    bounds?: Type[];
    genericDeclaration?: GenericDeclaration;
    name?: string;
    annotatedBounds?: AnnotatedType[];
  };

export type TypeVariableMethod = Type &
  AnnotatedElement & { bounds?: Type[]; genericDeclaration?: Method; name?: string; annotatedBounds?: AnnotatedType[] };

export type UnsubscribeAction = WebSocketRequestAction & { subscriptionId?: string };

export interface User {
  id?: string;
  email?: string;
  principal?: string;
  provider?: string;
  name?: string;
  firstName?: string;
  entitlements?: Entitlements;
  detailedEntitlements?: DetailedEntitlements;
  editionEntitlements?: Record<string, EntitlementValueObject>;
  lastName?: string;
  avatarUrl?: string;
  admin?: boolean;
  slackEnabled?: boolean;
  impersonated?: boolean;
  timestampOffsetMs?: number;
  features?: UserSettings;
  assistFeatures?: AssistFeatures;
  settings?: Settings;
  metadata?: UserMetadata;
  created?: string;
  deleted?: string;
  onboarded?: boolean;
  trackingCode?: string;
  locale?: Locale;
  likelyPersonal?: boolean;
  apiKey?: string;
  sku: ReclaimEdition;
  edition: ReclaimEdition;
  editionAfterTrial?: ReclaimEdition | null;
  editionUsage: ReclaimEdition;
  overage?: boolean;
  logContextUser?: ObservedUser | null;
  refCode?: string;
  hostedDomain?: string | null;
  primaryCalendar?: ThinCalendar | null;
  primaryCalendarId?: string;
  timezone?: ZoneId;
  startOfWeek?: DayOfWeek;
}

export interface UserInterests {
  tasks?: boolean;
  priorities?: boolean;
  office365?: boolean;
  calendar?: boolean;
  asana?: boolean;
  trello?: boolean;
  todoist?: boolean;
  jira?: boolean;
  linear?: boolean;
  clickup?: boolean;
  monday?: boolean;
}

export interface UserMetadata {
  jobTitle?: string;
  companyName?: string;
  companySize?: UserMetadataCompanySize;
  usecase?: UserMetadataUsecase;
  role?: UserProfileRole;
  department?: UserProfileDepartment | null;
}

export enum UserMetadataCompanySize {
  SUB10 = "SUB_10",
  SUB50 = "SUB_50",
  SUB100 = "SUB_100",
  SUB500 = "SUB_500",
  SUB1000 = "SUB_1000",
  THOUSANDPLUS = "THOUSAND_PLUS",
}

export enum UserMetadataUsecase {
  EDUCATION = "EDUCATION",
  WORK = "WORK",
  NONPROFIT = "NON_PROFIT",
  TEAM = "TEAM",
  SOLOWORK = "SOLO_WORK",
  PERSONAL = "PERSONAL",
}

export interface UserOnboard {
  habits?: boolean;
  tasks?: boolean;
  googleTasks?: boolean;
  planItemPrioritized?: boolean;
  smartOneOnOnes?: boolean;
  bufferTime?: boolean;
  tasksReindex?: boolean;
  googleAddOn?: boolean;
  schedulingLinks?: boolean;
}

export interface UserPayload {
  email: string;
}

export enum UserProfileDepartment {
  PRODUCT = "PRODUCT",
  ENGINEERING = "ENGINEERING",
  SUPPORT = "SUPPORT",
  MARKETING = "MARKETING",
  SALES = "SALES",
  DESIGN = "DESIGN",
  HR = "HR",
  OPERATIONS = "OPERATIONS",
  ACCOUNTINGANDFINANCE = "ACCOUNTING_AND_FINANCE",
  FOUNDER = "FOUNDER",
  OTHER = "OTHER",
}

export enum UserProfileRole {
  EXEC = "EXEC",
  MANAGER = "MANAGER",
  NONMANAGER = "NON_MANAGER",
  CONSULTANT = "CONSULTANT",
  STUDENT = "STUDENT",
}

export interface UserQuests {
  completedQuests: QuestType[];
}

export interface UserSettings {
  assistSettings: AssistSettings;
  scheduler?: number;
  extraScopes?: boolean;
  timePolicies: Record<string, TimePolicy>;
  taskSettings: TaskSettings;
  priorities: PrioritiesSettings;
  colors: ColorsSettings;
  calendar: CalendarSettings;
  focus: FocusSettings;
  asana: AsanaSettings;
  openAISettings: OpenAISettings;
  billing: BillingSettings;
  projects: ProjectsSettings;
  sync: SyncFeatureSettings;
  slackSettings: SlackSettings;
  appNotifications: AppNotificationsSettings;
  interests: UserInterests;
  onboard: UserOnboard;
  weeklyReport: WeeklyReport;
  smartOneOnOnes: SmartOneOnOneSettings;
  schedulingLinks: SchedulingLinkSettings;
  eventStorage: EventStorageSettings;
  quests: QuestSettings;
  googleAddOnSettings: GoogleAddOnSettings;
  availableMeetingTimes?: number[];
}

export type UserSubscription = Subscription & { subscriptionType?: SubscriptionType };

export interface UserTemplateData {
  firstName: string;
  trackingCode: string;
  slackEnabled: boolean;
  slackStatusEnabled: boolean;
}

export enum UserTrait {
  EMAIL = "EMAIL",
  AUTHID = "AUTH_ID",
  FULLNAME = "FULL_NAME",
  FIRSTNAME = "FIRST_NAME",
  LASTNAME = "LAST_NAME",
  CREATEDAT = "CREATED_AT",
  SIGNEDUP = "SIGNED_UP",
  MAINTIMEZONE = "MAIN_TIMEZONE",
  REFERRALCODE = "REFERRAL_CODE",
  ONBOARDED = "ONBOARDED",
  LIKELYPERSONAL = "LIKELY_PERSONAL",
  CLUSTERDEX = "CLUSTERDEX",
  JOBTITLE = "JOB_TITLE",
  COMPANYNAME = "COMPANY_NAME",
  COMPANYSIZE = "COMPANY_SIZE",
  USECASE = "USECASE",
  ROLE = "ROLE",
  DEPARTMENT = "DEPARTMENT",
  PRIMARYCALENDARLINKED = "PRIMARY_CALENDAR_LINKED",
  PRIMARYCALENDARID = "PRIMARY_CALENDAR_ID",
  EDITION = "EDITION",
  EDITIONHR = "EDITION_HR",
  EDITIONAFTERTRIAL = "EDITION_AFTER_TRIAL",
  EDITIONAFTERTRIALHR = "EDITION_AFTER_TRIAL_HR",
  PREDICTEDEDITION = "PREDICTED_EDITION",
  PREDICTEDEDITIONHR = "PREDICTED_EDITION_HR",
  PREDICTEDEDITIONBEFORELASTDOWNGRADE = "PREDICTED_EDITION_BEFORE_LAST_DOWNGRADE",
  PREDICTEDEDITIONAFTERLASTDOWNGRADE = "PREDICTED_EDITION_AFTER_LAST_DOWNGRADE",
  ACCOUNTID = "ACCOUNT_ID",
  ACCOUNTMEMBERS = "ACCOUNT_MEMBERS",
  ACCOUNTSUBSCRIPTIONCHANGE = "ACCOUNT_SUBSCRIPTION_CHANGE",
  ACCOUNTROLE = "ACCOUNT_ROLE",
  ACCOUNTPLANHR = "ACCOUNT_PLAN_HR",
  ACCOUNTTRIALENDDATE = "ACCOUNT_TRIAL_END_DATE",
  ACCOUNTTRIALDAYSREMAINING = "ACCOUNT_TRIAL_DAYS_REMAINING",
  ACCOUNTSUBSCRIPTIONSTATUS = "ACCOUNT_SUBSCRIPTION_STATUS",
  TRIALENDDATE = "TRIAL_END_DATE",
  TRIALDAYSREMAINING = "TRIAL_DAYS_REMAINING",
  USAGESYNCPOLICYCOUNT = "USAGE_SYNC_POLICY_COUNT",
  USAGESYNCPOLICYCUSTOMCOUNT = "USAGE_SYNC_POLICY_CUSTOM_COUNT",
  USAGECONNECTEDCALENDARCOUNT = "USAGE_CONNECTED_CALENDAR_COUNT",
  USAGEHABITACTIVECOUNT = "USAGE_HABIT_ACTIVE_COUNT",
  USAGESMART11ACTIVECOUNT = "USAGE_SMART_11_ACTIVE_COUNT",
  USAGECUSTOMDECOMPRESSION = "USAGE_CUSTOM_DECOMPRESSION",
  PERSONALCALENDARSCOUNT = "PERSONAL_CALENDARS_COUNT",
  PERSONALCALENDARLINKED = "PERSONAL_CALENDAR_LINKED",
  PERSONALCALENDARID = "PERSONAL_CALENDAR_ID",
  ONEONONESACTIVE = "ONE_ON_ONES_ACTIVE",
  ONEONONESPENDING = "ONE_ON_ONES_PENDING",
  ONEONONESORGANIZED = "ONE_ON_ONES_ORGANIZED",
  ONEONONESINVITEDTO = "ONE_ON_ONES_INVITED_TO",
  ONEONONESTOTAL = "ONE_ON_ONES_TOTAL",
  ONEONONESDETECTED = "ONE_ON_ONES_DETECTED",
  DAILYHABITSACTIVE = "DAILY_HABITS_ACTIVE",
  DAILYHABITSTOTAL = "DAILY_HABITS_TOTAL",
  DAILYHABITSACTIVATED = "DAILY_HABITS_ACTIVATED",
  TASKSTOTAL = "TASKS_TOTAL",
  TASKSRECENT = "TASKS_RECENT",
  SLACKACTIONTAKEN = "SLACK_ACTION_TAKEN",
  DOMAIN = "DOMAIN",
  DOMAINISGOOGLEWORKSPACE = "DOMAIN_IS_GOOGLE_WORKSPACE",
  DOMAINSLACKUSERS = "DOMAIN_SLACK_USERS",
  FEATUREASSISTTRAVEL = "FEATURE_ASSIST_TRAVEL",
  FEATUREASSISTCONFERENCEBUFFER = "FEATURE_ASSIST_CONFERENCE_BUFFER",
  FEATURESLACKENABLED = "FEATURE_SLACK_ENABLED",
  FEATURESLACKSTATUSSYNC = "FEATURE_SLACK_STATUS_SYNC",
  FEATURETASKSENABLED = "FEATURE_TASKS_ENABLED",
  FEATUREGOOGLETASKSENABLED = "FEATURE_GOOGLE_TASKS_ENABLED",
  FEATURECOLORSENABLED = "FEATURE_COLORS_ENABLED",
  INTERESTOFFICE365 = "INTEREST_OFFICE365",
  INTERESTINTEGRATIONTRELLO = "INTEREST_INTEGRATION_TRELLO",
  INTERESTINTEGRATIONMONDAY = "INTEREST_INTEGRATION_MONDAY",
  ONBOARDHABITS = "ONBOARD_HABITS",
  ONBOARDTASKS = "ONBOARD_TASKS",
  ONBOARDGOOGLETASKS = "ONBOARD_GOOGLE_TASKS",
  ONBOARDPLANITEMPRIORITIZED = "ONBOARD_PLAN_ITEM_PRIORITIZED",
  ONBOARDSMARTONEONONES = "ONBOARD_SMART_ONE_ON_ONES",
  ONBOARDBUFFERTIME = "ONBOARD_BUFFER_TIME",
  ONBOARDTASKSREINDEX = "ONBOARD_TASKS_REINDEX",
  ONBOARDGOOGLEADDON = "ONBOARD_GOOGLE_ADDON",
  ONBOARDSCHEDULINGLINKS = "ONBOARD_SCHEDULING_LINKS",
  REFERRALSSIGNEDUP = "REFERRALS_SIGNED_UP",
  REFERRALSACTIVE = "REFERRALS_ACTIVE",
  REFERRALSDELETED = "REFERRALS_DELETED",
  REFERRALCREDITSCLAIMED = "REFERRAL_CREDITS_CLAIMED",
  INVITESSENT = "INVITES_SENT",
  INVITEESSIGNEDUP = "INVITEES_SIGNED_UP",
  INVITEESACTIVE = "INVITEES_ACTIVE",
  INVITEESDELETED = "INVITEES_DELETED",
  WEEKLYREPORTSUBSCRIBED = "WEEKLY_REPORT_SUBSCRIBED",
  ZOOMENABLED = "ZOOM_ENABLED",
  LINEARENABLED = "LINEAR_ENABLED",
  JIRAENABLED = "JIRA_ENABLED",
  TODOISTENABLED = "TODOIST_ENABLED",
  ASANAENABLED = "ASANA_ENABLED",
  CLICKUPENABLED = "CLICKUP_ENABLED",
  ADDONINSTALLED = "ADD_ON_INSTALLED",
  SCHEDULINGLINKSLINKCOUNT = "SCHEDULING_LINKS_LINK_COUNT",
  SCHEDULINGLINKSMEETINGCOUNT = "SCHEDULING_LINKS_MEETING_COUNT",
  QUESTWELCOMETORECLAIM = "QUEST_WELCOME_TO_RECLAIM",
  QUESTSETAVAILABILITY = "QUEST_SET_AVAILABILITY",
  QUESTSYNCCALENDAR = "QUEST_SYNC_CALENDAR",
  QUESTSETSCHEDULINGHOURS = "QUEST_SET_SCHEDULING_HOURS",
  QUESTINSTALLGCALADDON = "QUEST_INSTALL_GCAL_ADDON",
  QUESTINSTALLSLACKINTEGRATION = "QUEST_INSTALL_SLACK_INTEGRATION",
  QUESTHABITSOVERVIEW = "QUEST_HABITS_OVERVIEW",
  QUESTCREATEANDCUSTOMIZEHABITS = "QUEST_CREATE_AND_CUSTOMIZE_HABITS",
  QUESTMANAGEHABITS = "QUEST_MANAGE_HABITS",
  QUESTSCHEDULINGLINKSOVERVIEW = "QUEST_SCHEDULING_LINKS_OVERVIEW",
  QUESTSETUPSCHEDULINGLINKS = "QUEST_SETUP_SCHEDULING_LINKS",
  QUESTSETUPTEAMLINKS = "QUEST_SETUP_TEAM_LINKS",
  QUESTSMARTONEONONEOVERVIEW = "QUEST_SMART_ONE_ON_ONE_OVERVIEW",
  QUESTSETUPFIRSTSMARTONEONONE = "QUEST_SETUP_FIRST_SMART_ONE_ON_ONE",
  QUESTMANAGESMARTONEONONE = "QUEST_MANAGE_SMART_ONE_ON_ONE",
  QUESTTASKOVERVIEW = "QUEST_TASK_OVERVIEW",
  QUESTADDFIRSTTASK = "QUEST_ADD_FIRST_TASK",
  QUESTADDTASKINTEGRATION = "QUEST_ADD_TASK_INTEGRATION",
  QUESTMANAGETASKS = "QUEST_MANAGE_TASKS",
  QUESTSETTASKDEFAULTS = "QUEST_SET_TASK_DEFAULTS",
  QUESTAUTOSCHEDULEBREAKANDTRAVELTIME = "QUEST_AUTO_SCHEDULE_BREAK_AND_TRAVEL_TIME",
  QUESTSETUPNOMEETINGDAYS = "QUEST_SETUP_NO_MEETING_DAYS",
  QUESTCUSTOMIZEEVENTCOLORCODING = "QUEST_CUSTOMIZE_EVENT_COLOR_CODING",
  QUESTTRACKANDANALYZEPRODUCTIVITY = "QUEST_TRACK_AND_ANALYZE_PRODUCTIVITY",
  QUESTADDMEMBERSTOTEAM = "QUEST_ADD_MEMBERS_TO_TEAM",
  QUESTSELECTPLAN = "QUEST_SELECT_PLAN",
}

export interface UserView {
  name: string;
  email: string;
  mainAccountId: number;
}

export type WebSocketRequestAction = object;

export interface WebSocketResponseEnvelope {
  data: object;
  delete: boolean;
  type: SubscriptionType;
  compressed: boolean;
  subscriptionId: string | null;
  notificationKeys: string[];
}

export interface WeeklyReport {
  enabled?: boolean;
  sendReport?: boolean;
}

export interface WeeklyReportAgenda {
  firstRow: WeeklyReportAgendaDay[];
  secondRow: WeeklyReportAgendaDay[];
}

export interface WeeklyReportAgendaDay {
  day: string;
  items: string[];
  extraClass: string;
}

export interface WeeklyReportCallout {
  text: string;
  textColor: string;
  backgroundColor: string;
  icon: string;
  svgIcon: string;
}

export interface WeeklyReportHeadlineStats {
  personal: TimeSpentDuringWeek;
  work: TimeSpentDuringWeek;
  meetings: TimeSpentDuringWeek;
}

export interface WeeklyReportSocialShare {
  id: string;
  verificationKey: string;
}

export interface WeeklyReportTask {
  name: string;
  chip: string;
  due?: string | null;
  timeLeft: string;
}

export interface WeeklyReportTemplateData {
  user: UserTemplateData;
  reportRange: string;
  reportRangeEncoded?: string;
  stats: WeeklyReportHeadlineStats;
  celebration: string;
  callouts: WeeklyReportCallout[];
  agenda: WeeklyReportAgenda;
  tasks: WeeklyReportTask[];
  baseUrl: string;
  baseUrlEncoded?: string;
  social?: WeeklyReportSocialShare;
  unsubPayload?: string;
}

export interface WeeklyReportUnsubscribeRequest {
  id: string;
  verificationKey: string;
}

export interface ZoneId {
  id?: string;
  rules?: ZoneRules;
}

export type ZoneOffset = ZoneId & { totalSeconds?: number; id?: string; rules?: ZoneRules };

export interface ZoneOffsetTransition {
  instant?: string;
  dateTimeBefore?: string;
  dateTimeAfter?: string;
  offsetBefore?: ZoneOffset;
  offsetAfter?: ZoneOffset;
  duration?: Duration;
  gap?: boolean;
  overlap?: boolean;
}

export interface ZoneOffsetTransitionRule {
  month: Month;
  dayOfMonthIndicator: number;
  dayOfWeek: DayOfWeek;
  localTime?: string;
  midnightEndOfDay?: boolean;
  timeDefinition: ZoneOffsetTransitionRuleTimeDefinition;
  standardOffset: ZoneOffset;
  offsetBefore: ZoneOffset;
  offsetAfter: ZoneOffset;
}

export enum ZoneOffsetTransitionRuleTimeDefinition {
  UTC = "UTC",
  WALL = "WALL",
  STANDARD = "STANDARD",
}

export interface ZoneRules {
  fixedOffset?: boolean;
  transitions?: ZoneOffsetTransition[];
  transitionRules?: ZoneOffsetTransitionRule[];
}

export interface ZoomUser {
  id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
}

export type LocalTime = string;

export namespace accounts {
  /**
   * @tags accounts
   * @name list
   * @request GET:/accounts
   */
  export namespace List {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = ConnectedAccount[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags accounts
   * @name validateAll
   * @request POST:/accounts/validate
   */
  export namespace ValidateAll {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags accounts
   * @name getCalendars
   * @request GET:/accounts/{accountId}/calendars
   */
  export namespace GetCalendars {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = CalendarView[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags accounts
   * @name setAvailability
   * @request PUT:/accounts/{accountId}/calendars/connected
   */
  export namespace SetAvailability {
    export type RequestQuery = {};
    export type RequestBody = string[];
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags accounts
   * @name delete
   * @request DELETE:/accounts/{id}
   */
  export namespace Delete {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags accounts
   * @name validate
   * @request POST:/accounts/{id}/validate
   */
  export namespace Validate {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace admin {
  /**
   * @name admin_createUser
   * @request POST:/admin/users
   */
  export namespace AdminCreateUser {
    export type RequestQuery = {};
    export type RequestBody = UserPayload;
    export type ResponseBody = UserView;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name admin_deleteUser
   * @request DELETE:/admin/users/{username}
   */
  export namespace AdminDeleteUser {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name admin_listAccounts
   * @request GET:/admin/users/{username}/accounts
   */
  export namespace AdminListAccounts {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = ConnectedAccount[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name admin_createAccount
   * @request POST:/admin/users/{username}/accounts
   */
  export namespace AdminCreateAccount {
    export type RequestQuery = {};
    export type RequestBody = { accountPayload?: ConnectedAccountPayload };
    export type ResponseBody = ConnectedAccount;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name admin_switchMainAccount
   * @request POST:/admin/users/{username}/accounts/{accountId}/main
   */
  export namespace AdminSwitchMainAccount {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name admin_createCalendarSync
   * @request POST:/admin/users/{username}/calendar-syncs
   */
  export namespace AdminCreateCalendarSync {
    export type RequestQuery = {};
    export type RequestBody = CreateCalendarSyncRequest;
    export type ResponseBody = CalendarSync;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name admin_getCalendarSyncs
   * @request GET:/admin/users/{username}/calendar-syncs/
   */
  export namespace AdminGetCalendarSyncs {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = CalendarSync[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name admin_deleteSync
   * @request DELETE:/admin/users/{username}/calendar-syncs/{sourceId}/to/{targetId}
   */
  export namespace AdminDeleteSync {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name admin_patchSync
   * @request PATCH:/admin/users/{username}/calendar-syncs/{sourceId}/to/{targetId}
   */
  export namespace AdminPatchSync {
    export type RequestQuery = {};
    export type RequestBody = Annotation;
    export type ResponseBody = CalendarSync;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace analytics {
  /**
   * @tags analytics
   * @name analytics_teamAnalytics
   * @request GET:/analytics/team
   */
  export namespace AnalyticsTeamAnalytics {
    export type RequestQuery = {
      start: string;
      end: string;
      metricName: AnalyticsMetricName[];
      groupingInterval?: AnalyticsGroupingInterval | null;
    };
    export type RequestBody = never;
    export type ResponseBody = AnalyticsResults;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags analytics
   * @name analytics_teamAnalyticsV2
   * @request GET:/analytics/team/V2
   */
  export namespace AnalyticsTeamAnalyticsV2 {
    export type RequestQuery = {
      start: string;
      end: string;
      metricName: AnalyticsMetricName[];
      groupingInterval?: AnalyticsGroupingInterval | null;
      notificationKey?: string | null;
    };
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags analytics
   * @name analytics_userAnalytics
   * @request GET:/analytics/user
   */
  export namespace AnalyticsUserAnalytics {
    export type RequestQuery = {
      start: string;
      end: string;
      metricName: AnalyticsMetricName[];
      groupingInterval?: AnalyticsGroupingInterval | null;
    };
    export type RequestBody = never;
    export type ResponseBody = AnalyticsResults;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags analytics
   * @name analytics_userAnalyticsV2
   * @request GET:/analytics/user/V2
   */
  export namespace AnalyticsUserAnalyticsV2 {
    export type RequestQuery = {
      start: string;
      end: string;
      metricName: AnalyticsMetricName[];
      groupingInterval?: AnalyticsGroupingInterval | null;
      notificationKey?: string | null;
    };
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace apiManagement {
  /**
   * @tags api-management
   * @name createApiKey
   * @request POST:/api-management/api-key
   */
  export namespace CreateApiKey {
    export type RequestQuery = {};
    export type RequestBody = ApiKey;
    export type ResponseBody = ApiKey;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags api-management
   * @name updateApiKey
   * @request PATCH:/api-management/api-key
   */
  export namespace UpdateApiKey {
    export type RequestQuery = {};
    export type RequestBody = ApiKey;
    export type ResponseBody = ApiKey;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags api-management
   * @name reissueApiKey
   * @request PATCH:/api-management/api-key/reissue/{id}
   */
  export namespace ReissueApiKey {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = ApiKey;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags api-management
   * @name listApiKey
   * @request GET:/api-management/api-key/{id}
   */
  export namespace ListApiKey {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = ApiKey;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags api-management
   * @name deleteApiKey
   * @request DELETE:/api-management/api-key/{id}
   */
  export namespace DeleteApiKey {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags api-management
   * @name listApiKey_1
   * @request GET:/api-management/api-keys
   */
  export namespace ListApiKey1 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = ApiKey[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace asana {
  /**
   * @tags asana, integration, private
   * @name integrations
   * @request GET:/asana/integrations
   */
  export namespace Integrations {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = AsanaIntegration[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags asana, integration, private
   * @name deleteIntegration
   * @request DELETE:/asana/integrations
   */
  export namespace DeleteIntegration {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = AsanaIntegration[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace assist {
  /**
   * @tags assist
   * @name getDailyHabits
   * @request GET:/assist/habits/daily
   */
  export namespace GetDailyHabits {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = DailyHabit[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags assist
   * @name create
   * @request POST:/assist/habits/daily
   */
  export namespace Create {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = DailyHabit;
    export type ResponseBody = DailyHabit[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags assist
   * @name getDailyHabit
   * @request GET:/assist/habits/daily/{id}
   */
  export namespace GetDailyHabit {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = DailyHabit;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags assist
   * @name update
   * @request PUT:/assist/habits/daily/{id}
   */
  export namespace Update {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = DailyHabit;
    export type ResponseBody = DailyHabit[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags assist
   * @name delete_1
   * @request DELETE:/assist/habits/daily/{id}
   */
  export namespace Delete1 {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags assist
   * @name patch
   * @request PATCH:/assist/habits/daily/{id}
   */
  export namespace Patch {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = DailyHabit;
    export type ResponseBody = DailyHabit[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags assist
   * @name getHabitTemplate
   * @request GET:/assist/habits/template
   */
  export namespace GetHabitTemplate {
    export type RequestQuery = { templateKey: HabitTemplateKey };
    export type RequestBody = never;
    export type ResponseBody = DailyHabitTemplate;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags assist
   * @name createHabitTemplates
   * @request POST:/assist/habits/template/create
   */
  export namespace CreateHabitTemplates {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = { templates?: HabitTemplateKey[] };
    export type ResponseBody = HabitTemplateKey[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags assist
   * @name getHabitTemplates
   * @request GET:/assist/habits/templates
   */
  export namespace GetHabitTemplates {
    export type RequestQuery = { role?: UserProfileRole | null; department?: UserProfileDepartment | null };
    export type RequestBody = never;
    export type ResponseBody = DailyHabitTemplate[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace calendars {
  /**
   * @tags calendars
   * @name getAllPersonal
   * @request GET:/calendars/personal
   */
  export namespace GetAllPersonal {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Calendar[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name getPersonalCandidates
   * @request GET:/calendars/personal/candidates
   */
  export namespace GetPersonalCandidates {
    export type RequestQuery = { credentialId?: number | null };
    export type RequestBody = never;
    export type ResponseBody = Calendar[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name getPersonal
   * @request GET:/calendars/personal/{id}
   */
  export namespace GetPersonal {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Calendar;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name deletePersonal
   * @request DELETE:/calendars/personal/{id}
   */
  export namespace DeletePersonal {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name getPrimary
   * @request GET:/calendars/primary
   */
  export namespace GetPrimary {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Calendar;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name share
   * @request POST:/calendars/share
   */
  export namespace Share {
    export type RequestQuery = { credentialId: number };
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name getAllSync
   * @request GET:/calendars/sync
   */
  export namespace GetAllSync {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Calendar[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name createSync
   * @request POST:/calendars/sync
   */
  export namespace CreateSync {
    export type RequestQuery = {};
    export type RequestBody = { calendar?: Calendar };
    export type ResponseBody = Calendar;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendarSyncPolicy
   * @name getSyncPolicies
   * @request GET:/calendars/sync-policy
   */
  export namespace GetSyncPolicies {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = CalendarSyncPolicy[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendarSyncPolicy
   * @name createSyncPolicy
   * @request POST:/calendars/sync-policy
   */
  export namespace CreateSyncPolicy {
    export type RequestQuery = {};
    export type RequestBody = { calendarSyncPolicy?: CalendarSyncPolicy };
    export type ResponseBody = CalendarSyncPolicy;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendarSyncPolicy
   * @name testSyncPolicy
   * @request POST:/calendars/sync-policy/validate
   */
  export namespace TestSyncPolicy {
    export type RequestQuery = {};
    export type RequestBody = CalendarSyncPolicy;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendarSyncPolicy
   * @name getSyncPolicy
   * @request GET:/calendars/sync-policy/{sourceId}/to/{targetId}
   */
  export namespace GetSyncPolicy {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = CalendarSyncPolicy;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendarSyncPolicy
   * @name deleteSyncPolicy
   * @request DELETE:/calendars/sync-policy/{sourceId}/to/{targetId}
   */
  export namespace DeleteSyncPolicy {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendarSyncPolicy
   * @name patchSyncPolicy
   * @request PATCH:/calendars/sync-policy/{sourceId}/to/{targetId}
   */
  export namespace PatchSyncPolicy {
    export type RequestQuery = {};
    export type RequestBody = CalendarSyncPolicy;
    export type ResponseBody = CalendarSyncPolicy;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name getSyncCandidates
   * @request GET:/calendars/sync/candidates
   */
  export namespace GetSyncCandidates {
    export type RequestQuery = { credentialId?: number | null };
    export type RequestBody = never;
    export type ResponseBody = Calendar[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendarSyncEvents
   * @name getSyncEventsForUser
   * @request GET:/calendars/sync/events
   * @secure
   */
  export namespace GetSyncEventsForUser {
    export type RequestQuery = { limit: number };
    export type RequestBody = never;
    export type ResponseBody = SyncEvent[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendarSyncEvents
   * @name getSyncEventsForCalendar
   * @request GET:/calendars/sync/events/{calendarId}
   * @secure
   */
  export namespace GetSyncEventsForCalendar {
    export type RequestQuery = { limit: number };
    export type RequestBody = never;
    export type ResponseBody = SyncEvent[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendarSyncEvents
   * @name getSyncEventsForPolicy
   * @request GET:/calendars/sync/events/{sourceId}/to/{targetId}
   * @secure
   */
  export namespace GetSyncEventsForPolicy {
    export type RequestQuery = { limit: number };
    export type RequestBody = never;
    export type ResponseBody = SyncEvent[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name interest
   * @request POST:/calendars/sync/interest
   */
  export namespace Interest {
    export type RequestQuery = { type: string };
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name getSourceCandidates
   * @request GET:/calendars/sync/{credentialId}/candidates/sources
   */
  export namespace GetSourceCandidates {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Calendar[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name getTargetCandidates
   * @request GET:/calendars/sync/{credentialId}/candidates/targets
   */
  export namespace GetTargetCandidates {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Calendar[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name getSync
   * @request GET:/calendars/sync/{id}
   */
  export namespace GetSync {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Calendar;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name deleteSync
   * @request DELETE:/calendars/sync/{id}
   */
  export namespace DeleteSync {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name watch
   * @request POST:/calendars/watch
   */
  export namespace Watch {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = LocalTime;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name listWatch
   * @summary Heads up! We have seen at least one case where Google can issue an excessive, unnatural amount of watch callbacks.
   * @request POST:/calendars/watchList
   * @description Heads up! We have seen at least one case where Google can issue an excessive, unnatural amount of watch callbacks. for more information. Specifically, BE CAREFUL adding any blocking IO code, such as web service calls or database queries, upstream of that job.
   */
  export namespace ListWatch {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = LocalTime;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags calendars
   * @name settingsWatch
   * @request POST:/calendars/watchSettings
   */
  export namespace SettingsWatch {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = LocalTime;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace clickup {
  /**
   * @tags clickup, integration, private
   * @name integrations_1
   * @request GET:/clickup/integrations
   */
  export namespace Integrations1 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = ClickUpIntegration[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags clickup, integration, private
   * @name deleteIntegration_1
   * @request DELETE:/clickup/integrations
   */
  export namespace DeleteIntegration1 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags clickup, integration, private
   * @name clickUpIntegrationsDetails
   * @request GET:/clickup/integrations/details
   */
  export namespace ClickUpIntegrationsDetails {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = ClickUpIntegrationDetails[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags clickup, integration, private
   * @name patchIntegration
   * @request PATCH:/clickup/integrations/{workspaceId}
   */
  export namespace PatchIntegration {
    export type RequestQuery = {};
    export type RequestBody = ClickUpIntegrationPatch;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace credentials {
  /**
   * @tags credentials
   * @name list_1
   * @request GET:/credentials
   * @secure
   */
  export namespace List1 {
    export type RequestQuery = { includeInvalid?: boolean | null };
    export type RequestBody = never;
    export type ResponseBody = Credential[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags credentials
   * @name listPersonal
   * @request GET:/credentials/personal
   * @secure
   */
  export namespace ListPersonal {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Credential[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags credentials
   * @name getPrimary_1
   * @request GET:/credentials/primary
   * @secure
   */
  export namespace GetPrimary1 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Calendar;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags credentials
   * @name get
   * @request GET:/credentials/{id}
   * @secure
   */
  export namespace Get {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Credential;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags credentials
   * @name delete_2
   * @request DELETE:/credentials/{id}
   */
  export namespace Delete2 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace events {
  /**
   * @tags events
   * @name query
   * @request GET:/events
   */
  export namespace Query {
    export type RequestQuery = {
      calendar?: number | null;
      calendarIds?: number[] | null | null;
      priorities?: number[] | null | null;
      type?: EventType[] | null | null;
      project?: number[] | null | null;
      start?: string | null;
      end?: string | null;
      sourceDetails?: boolean | null;
      thin?: boolean | null;
      habitIds?: number[] | null | null;
      recurringOneOnOneIds?: number[] | null | null;
      includeProjects?: ProjectInclude | null;
    };
    export type RequestBody = never;
    export type ResponseBody = Event[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags events
   * @name getPersonal_1
   * @request GET:/events/personal
   */
  export namespace GetPersonal1 {
    export type RequestQuery = { start?: string | null; end?: string | null; limit?: number | null };
    export type RequestBody = never;
    export type ResponseBody = Event[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags events
   * @name queryGoogleRaw
   * @request GET:/events/raw-google/{credentialId}/{calendarId}
   */
  export namespace QueryGoogleRaw {
    export type RequestQuery = {
      q?: string | null;
      daysBack?: number | null;
      singleEvents?: boolean | null;
      eventField?: string[] | null | null;
      privateExtendedProperty?: string[] | null | null;
      sharedExtendedProperty?: string[] | null | null;
    };
    export type RequestBody = never;
    export type ResponseBody = EventWithData[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags events
   * @name fetchGoogleRaw
   * @request GET:/events/raw-google/{credentialId}/{calendarId}/{eventId}
   */
  export namespace FetchGoogleRaw {
    export type RequestQuery = { eventField?: string[] | null | null };
    export type RequestBody = never;
    export type ResponseBody = EventWithData;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags events
   * @name gcalViewRedirect
   * @request GET:/events/view/{calendarId}/{eventId}
   */
  export namespace GcalViewRedirect {
    export type RequestQuery = { skipSourceRedirect?: boolean | null };
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags events
   * @name getForCalendar
   * @request GET:/events/{calendarId}/{eventId}
   */
  export namespace GetForCalendar {
    export type RequestQuery = {
      sourceDetails?: boolean | null;
      thin?: boolean | null;
      includeProjects?: ProjectInclude | null;
    };
    export type RequestBody = never;
    export type ResponseBody = Event;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags events
   * @name adjustConferenceBuffer
   * @request POST:/events/{calendarId}/{eventId}/buffer
   */
  export namespace AdjustConferenceBuffer {
    export type RequestQuery = { duration: number; notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags events
   * @name adjustPriority
   * @request POST:/events/{calendarId}/{eventId}/priority
   */
  export namespace AdjustPriority {
    export type RequestQuery = {
      priority: PriorityLevel;
      period?: RecurringEventUpdatePeriod | null;
      notificationKey?: string | null;
    };
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags events
   * @name adjustTravelTime
   * @request POST:/events/{calendarId}/{eventId}/travel/{type}
   */
  export namespace AdjustTravelTime {
    export type RequestQuery = { duration: number; notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags events
   * @name get_1
   * @request GET:/events/{eventId}
   */
  export namespace Get1 {
    export type RequestQuery = {
      sourceDetails?: boolean | null;
      thin?: boolean | null;
      includeProjects?: ProjectInclude | null;
    };
    export type RequestBody = never;
    export type ResponseBody = Event;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace googleAddOn {
  /**
   * @tags google-add-on, integration, private
   * @name integrations_2
   * @request GET:/google-add-on/integrations
   */
  export namespace Integrations2 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = GoogleAddOnSettings[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace integrations {
  /**
   * @tags integration, private
   * @name enabled
   * @request GET:/integrations/enabled
   */
  export namespace Enabled {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name getZoomIntegration
   * @request GET:/integrations/zoom
   * @secure
   */
  export namespace GetZoomIntegration {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = ZoomUser;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name deleteZoomToken
   * @request DELETE:/integrations/zoom
   */
  export namespace DeleteZoomToken {
    export type RequestQuery = {};
    export type RequestBody = { user?: User };
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace interpreter {
  /**
   * @name message
   * @request POST:/interpreter/message
   */
  export namespace Message {
    export type RequestQuery = {};
    export type RequestBody = MessagePost & { user?: User };
    export type ResponseBody = InterpretedPlansGet;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name fetchMessage
   * @request GET:/interpreter/plans/pending/{planId}
   * @secure
   */
  export namespace FetchMessage {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = InterpretedPlanGet;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace invite {
  /**
   * @tags invite
   * @name resolve
   * @request GET:/invite/{trackingCode}
   */
  export namespace Resolve {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace jira {
  /**
   * @tags integration, jira, private
   * @name integrations_3
   * @request GET:/jira/integrations
   */
  export namespace Integrations3 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = JiraIntegration[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags integration, jira, private
   * @name deleteIntegration_2
   * @request DELETE:/jira/integrations/{id}
   */
  export namespace DeleteIntegration2 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = JiraIntegration[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags integration, jira, private
   * @name patchIntegration_1
   * @request PATCH:/jira/integrations/{id}
   */
  export namespace PatchIntegration1 {
    export type RequestQuery = {};
    export type RequestBody = JiraIntegrationPatch;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace linear {
  /**
   * @tags integration, linear, private
   * @name integrations_4
   * @request GET:/linear/integrations
   */
  export namespace Integrations4 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = LinearIntegration[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags integration, linear, private
   * @name deleteIntegration_3
   * @request DELETE:/linear/integrations/{id}
   */
  export namespace DeleteIntegration3 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags integration, linear, private
   * @name patchIntegration_2
   * @request PATCH:/linear/integrations/{id}
   */
  export namespace PatchIntegration2 {
    export type RequestQuery = {};
    export type RequestBody = LinearIntegrationPatch;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags integration, linear, private
   * @name issueDetails
   * @request GET:/linear/issue/{id}
   */
  export namespace IssueDetails {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = LinearTaskDetails;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace moment {
  /**
   * @tags moment
   * @name get_2
   * @request GET:/moment
   */
  export namespace Get2 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Moment;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags moment
   * @name next
   * @request GET:/moment/next
   */
  export namespace Next {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Moment;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace ok {
  /**
   * @name ok
   * @request GET:/ok
   */
  export namespace Ok {
    export type RequestQuery = { env?: string | null };
    export type RequestBody = never;
    export type ResponseBody = LocalTime;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace oneOnOne {
  /**
   * @tags oneOnOnes
   * @name getOneOnOnes
   * @request GET:/oneOnOne
   */
  export namespace GetOneOnOnes {
    export type RequestQuery = { instances?: boolean | null };
    export type RequestBody = never;
    export type ResponseBody = RecurringOneOnOne[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name createOnOnOne
   * @request POST:/oneOnOne
   */
  export namespace CreateOnOnOne {
    export type RequestQuery = { notificationKey?: string | null; sendTeamInvite?: boolean | null };
    export type RequestBody = RecurringOneOnOne;
    export type ResponseBody = RecurringOneOnOne[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name convertPendingToAuto
   * @request POST:/oneOnOne/convert-auto/{id}
   */
  export namespace ConvertPendingToAuto {
    export type RequestQuery = { title?: string | null };
    export type RequestBody = never;
    export type ResponseBody = RecurringOneOnOne;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name getDetected
   * @request GET:/oneOnOne/detected
   */
  export namespace GetDetected {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = RecurringOneOnOne[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name respond
   * @request POST:/oneOnOne/invite/{id}/respond
   */
  export namespace Respond {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = { status?: RecurringAssignmentAttendeeStatus };
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name respondAnonymously
   * @request POST:/oneOnOne/invite/{id}/respond/{inviteKey}
   */
  export namespace RespondAnonymously {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = { status?: RecurringAssignmentAttendeeStatus };
    export type ResponseBody = RecurringOneOnOne;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name getInviteeEligibility
   * @request GET:/oneOnOne/invitee-eligibility
   */
  export namespace GetInviteeEligibility {
    export type RequestQuery = { email: string };
    export type RequestBody = never;
    export type ResponseBody = InviteeEligibility;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name getMeetingInvites
   * @request GET:/oneOnOne/invites
   */
  export namespace GetMeetingInvites {
    export type RequestQuery = { instances?: boolean | null };
    export type RequestBody = never;
    export type ResponseBody = RecurringOneOnOne[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name getMeetingInvite
   * @request GET:/oneOnOne/invites/{id}
   */
  export namespace GetMeetingInvite {
    export type RequestQuery = { inviteKey?: string | null; instances?: boolean | null };
    export type RequestBody = never;
    export type ResponseBody = RecurringOneOnOne;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name getSuggestions
   * @request GET:/oneOnOne/suggestions
   */
  export namespace GetSuggestions {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = RecurringOneOnOne[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name zoomRedirect
   * @request GET:/oneOnOne/zoom/{trackingCode}/{oneOnOneId}
   */
  export namespace ZoomRedirect {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name getOneOnOne
   * @request GET:/oneOnOne/{id}
   */
  export namespace GetOneOnOne {
    export type RequestQuery = { instances?: boolean | null };
    export type RequestBody = never;
    export type ResponseBody = RecurringOneOnOne;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name deleteOneOnOne
   * @request DELETE:/oneOnOne/{id}
   */
  export namespace DeleteOneOnOne {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name patchOneOnOne
   * @request PATCH:/oneOnOne/{id}
   */
  export namespace PatchOneOnOne {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = Annotation;
    export type ResponseBody = RecurringOneOnOne;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags oneOnOnes
   * @name getInstances
   * @request GET:/oneOnOne/{oneOnOneId}/instances
   * @secure
   */
  export namespace GetInstances {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = RecurringAssignmentInstance[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace people {
  /**
   * @tags people
   * @name search
   * @request GET:/people
   * @secure
   */
  export namespace Search {
    export type RequestQuery = { q: string; limit: number };
    export type RequestBody = never;
    export type ResponseBody = ThinPerson[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags people, private
   * @name unsubscribe
   * @request DELETE:/people/subscriptions
   */
  export namespace Unsubscribe {
    export type RequestQuery = { email: string; type: string };
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace planner {
  /**
   * @tags actions
   * @name addTime
   * @request POST:/planner/add-time/task/{taskId}
   */
  export namespace AddTime {
    export type RequestQuery = { minutes?: number | null; notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name clearHabitTimePolicyExceptions
   * @request POST:/planner/clear-exceptions/habit/{habitId}
   */
  export namespace ClearHabitTimePolicyExceptions {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name clearOneOnOneTimePolicyExceptions
   * @request POST:/planner/clear-exceptions/one-on-one/{oneOnOneId}
   */
  export namespace ClearOneOnOneTimePolicyExceptions {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name clearTaskTimePolicyExceptions
   * @request POST:/planner/clear-exceptions/task/{taskId}
   */
  export namespace ClearTaskTimePolicyExceptions {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name doneHabit
   * @request POST:/planner/done/habit/{habitId}
   */
  export namespace DoneHabit {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name doneTask
   * @request POST:/planner/done/task/{taskId}
   */
  export namespace DoneTask {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name changeCategory
   * @request PUT:/planner/event/category/{calendarId}/{eventId}
   */
  export namespace ChangeCategory {
    export type RequestQuery = { categoryIn: string; notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name moveEvent
   * @summary Event Actions
   * @request POST:/planner/event/move/{eventId}
   * @description Event Actions
   */
  export namespace MoveEvent {
    export type RequestQuery = { start: string; end: string; notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name pinEvent
   * @request POST:/planner/event/pin/{eventId}
   */
  export namespace PinEvent {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name rsvp
   * @request PUT:/planner/event/rsvp/{calendarId}/{eventId}
   */
  export namespace Rsvp {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = RsvpResponseBody;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name unpinEvent
   * @request POST:/planner/event/unpin/{eventId}
   */
  export namespace UnpinEvent {
    export type RequestQuery = {};
    export type RequestBody = { notificationKey?: string | null };
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name habitDeleteInstanceAndReschedule
   * @request POST:/planner/habit/{calendarId}/{eventId}/reschedule
   */
  export namespace HabitDeleteInstanceAndReschedule {
    export type RequestQuery = { snoozeOption?: SnoozeOption | null; notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name logWork
   * @request POST:/planner/log-work/task/{taskId}
   */
  export namespace LogWork {
    export type RequestQuery = { minutes?: number | null; end?: string | null; notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name oneOnOneReschedule
   * @request POST:/planner/one-on-one/reschedule/{oneOnOneId}/{eventId}
   */
  export namespace OneOnOneReschedule {
    export type RequestQuery = {};
    export type RequestBody = { notificationKey?: string | null };
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name oneOnOneSkipDay
   * @request POST:/planner/one-on-one/skip-day/{oneOnOneId}/{eventId}
   */
  export namespace OneOnOneSkipDay {
    export type RequestQuery = {};
    export type RequestBody = { notificationKey?: string | null };
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name oneOnOneSkipUntil
   * @request POST:/planner/one-on-one/skip-until/{oneOnOneId}/{eventId}
   */
  export namespace OneOnOneSkipUntil {
    export type RequestQuery = {};
    export type RequestBody = { notificationKey?: string | null };
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name oneOnOneSkipWeek
   * @request POST:/planner/one-on-one/skip-week/{oneOnOneId}/{eventId}
   */
  export namespace OneOnOneSkipWeek {
    export type RequestQuery = {};
    export type RequestBody = { notificationKey?: string | null };
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name deleteHabitPolicy
   * @request DELETE:/planner/policy/habit/{habitId}
   */
  export namespace DeleteHabitPolicy {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name deleteTaskPolicy
   * @summary Task Actions
   * @request DELETE:/planner/policy/task/{taskId}
   * @description Task Actions
   */
  export namespace DeleteTaskPolicy {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name prioritizeTask
   * @request POST:/planner/prioritize/task/{taskId}
   */
  export namespace PrioritizeTask {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name rescheduleHabitEvent
   * @request POST:/planner/reschedule/habit/event/{eventId}
   */
  export namespace RescheduleHabitEvent {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name rescheduleTaskEvent
   * @request POST:/planner/reschedule/task/event/{eventId}
   */
  export namespace RescheduleTaskEvent {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name restartHabit
   * @request POST:/planner/restart/habit/{habitId}
   */
  export namespace RestartHabit {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name restartTask
   * @request POST:/planner/restart/task/{taskId}
   */
  export namespace RestartTask {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name skipHabitEvent
   * @request POST:/planner/skip/habit/event/{eventId}
   */
  export namespace SkipHabitEvent {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name startHabit
   * @summary Habit Actions
   * @request POST:/planner/start/habit/{habitId}
   * @description Habit Actions
   */
  export namespace StartHabit {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name startTask
   * @request POST:/planner/start/task/{taskId}
   */
  export namespace StartTask {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name stopHabit
   * @request POST:/planner/stop/habit/{habitId}
   */
  export namespace StopHabit {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name stopTask
   * @request POST:/planner/stop/task/{taskId}
   */
  export namespace StopTask {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name taskDeleteMultipleInstanceAndReschedule
   * @request POST:/planner/task/reschedule/bulk
   */
  export namespace TaskDeleteMultipleInstanceAndReschedule {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = EventList;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name taskDeleteInstanceAndReschedule
   * @request POST:/planner/task/{calendarId}/{eventId}/reschedule
   */
  export namespace TaskDeleteInstanceAndReschedule {
    export type RequestQuery = { snoozeOption?: SnoozeOption | null; notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name taskClearSnooze
   * @request POST:/planner/task/{taskId}/clear-snooze
   */
  export namespace TaskClearSnooze {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name toggleHabit
   * @request POST:/planner/toggle/habit/{habitId}
   */
  export namespace ToggleHabit {
    export type RequestQuery = { enable?: boolean | null; notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags actions
   * @name unarchiveTask
   * @request POST:/planner/unarchive/task/{taskId}
   */
  export namespace UnarchiveTask {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = PlannerActionIntermediateResult;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace projects {
  /**
   * @tags projects
   * @name query_1
   * @request GET:/projects
   */
  export namespace Query1 {
    export type RequestQuery = { parameters: HttpParameters };
    export type RequestBody = never;
    export type ResponseBody = Project[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name create_1
   * @request POST:/projects
   */
  export namespace Create1 {
    export type RequestQuery = {};
    export type RequestBody = Project;
    export type ResponseBody = Project;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name get_3
   * @request GET:/projects/{id}
   */
  export namespace Get3 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Project;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name put
   * @request PUT:/projects/{id}
   */
  export namespace Put {
    export type RequestQuery = {};
    export type RequestBody = Project;
    export type ResponseBody = Project;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name delete_3
   * @request DELETE:/projects/{id}
   */
  export namespace Delete3 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name patch_1
   * @request PATCH:/projects/{id}
   */
  export namespace Patch1 {
    export type RequestQuery = {};
    export type RequestBody = Project;
    export type ResponseBody = Project;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name getEvents
   * @request GET:/projects/{id}/events
   */
  export namespace GetEvents {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = EventKey[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name getTasks
   * @request GET:/projects/{id}/tasks
   */
  export namespace GetTasks {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = number[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name associateEvent
   * @request POST:/projects/{projectId}/events/{calendarId}/{eventId}
   */
  export namespace AssociateEvent {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name disassociateEvent
   * @request DELETE:/projects/{projectId}/events/{calendarId}/{eventId}
   */
  export namespace DisassociateEvent {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name associateTask
   * @request POST:/projects/{projectId}/tasks/{taskId}
   */
  export namespace AssociateTask {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = any;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags projects
   * @name disassociateTask
   * @request DELETE:/projects/{projectId}/tasks/{taskId}
   */
  export namespace DisassociateTask {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = any;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace resources {
  /**
   * @tags resources
   * @name getResources
   * @request GET:/resources
   * @secure
   */
  export namespace GetResources {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Resources;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace scoring {
  /**
   * @tags scoring
   * @name rescore
   * @request POST:/scoring/rescore
   */
  export namespace Rescore {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace slack {
  /**
   * @tags integration, private, slack
   * @name actionEndpoint
   * @request POST:/slack/action-endpoint
   */
  export namespace ActionEndpoint {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags integration, private, slack
   * @name slackRedirect
   * @request GET:/slack/add
   */
  export namespace SlackRedirect {
    export type RequestQuery = { scope?: string | null; bot?: string | null; teamId?: string | null };
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags integration, private, slack
   * @name command
   * @request POST:/slack/command
   */
  export namespace Command {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags integration, private, slack
   * @name connect
   * @request GET:/slack/connect
   */
  export namespace Connect {
    export type RequestQuery = { code?: string | null; error?: string | null; state?: string | null };
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags integration, private, slack
   * @name interactiveEndpoint
   * @request POST:/slack/interactive-endpoint
   */
  export namespace InteractiveEndpoint {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags integration, private, slack
   * @name link
   * @request POST:/slack/link
   */
  export namespace Link {
    export type RequestQuery = { teamId: string; userId: string };
    export type RequestBody = { user?: User };
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags integration, private, slack
   * @name optionsLoadEndpoint
   * @request POST:/slack/options-load-endpoint
   */
  export namespace OptionsLoadEndpoint {
    export type RequestQuery = {};
    export type RequestBody = { response_url?: string | null; payload?: string | null };
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace sso {
  /**
   * @tags actions
   * @name findProvider
   * @request GET:/sso/provider/{email}
   */
  export namespace FindProvider {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = LocalTime;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace tasks {
  /**
   * @tags tasks
   * @name query_2
   * @request GET:/tasks
   * @secure
   */
  export namespace Query2 {
    export type RequestQuery = {
      status?: TaskStatus[] | null | null;
      project?: number | null;
      priority?: PriorityLevel | null;
      id?: number[] | null | null;
      instances?: boolean | null;
      includeProjects?: ProjectInclude | null;
    };
    export type RequestBody = never;
    export type ResponseBody = Task[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name create_2
   * @request POST:/tasks
   */
  export namespace Create2 {
    export type RequestQuery = {
      instances?: boolean | null;
      includeProjects?: ProjectInclude | null;
      notificationKey?: string | null;
    };
    export type RequestBody = Task & { user?: User };
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name interest_1
   * @request POST:/tasks/interest
   */
  export namespace Interest1 {
    export type RequestQuery = {};
    export type RequestBody = { user?: User };
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name findMinIndex
   * @request GET:/tasks/min-index
   * @secure
   */
  export namespace FindMinIndex {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = number;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name reindexByDue
   * @request PATCH:/tasks/reindex-by-due
   */
  export namespace ReindexByDue {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Task[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name getTask
   * @request GET:/tasks/{id}
   * @secure
   */
  export namespace GetTask {
    export type RequestQuery = { instances?: boolean | null; includeProjects?: ProjectInclude | null };
    export type RequestBody = never;
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name delete_4
   * @request DELETE:/tasks/{id}
   */
  export namespace Delete4 {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name put_1
   * @request PUT:/tasks/{taskId}
   */
  export namespace Put1 {
    export type RequestQuery = {
      instances?: boolean | null;
      includeProjects?: ProjectInclude | null;
      notificationKey?: string | null;
    };
    export type RequestBody = Task;
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name patch_2
   * @request PATCH:/tasks/{taskId}
   */
  export namespace Patch2 {
    export type RequestQuery = {
      instances?: boolean | null;
      includeProjects?: ProjectInclude | null;
      notificationKey?: string | null;
    };
    export type RequestBody = Annotation;
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name getInstances_1
   * @request GET:/tasks/{taskId}/instances
   * @secure
   */
  export namespace GetInstances1 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = TaskInstance[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name updateInstance
   * @request PATCH:/tasks/{taskId}/instances/{index}
   */
  export namespace UpdateInstance {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = { user?: User };
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags tasks
   * @name reindex
   * @request PATCH:/tasks/{taskId}/reindex
   */
  export namespace Reindex {
    export type RequestQuery = {};
    export type RequestBody = Reindex;
    export type ResponseBody = Task;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace timescheme {
  /**
   * @tags time-scheme-rules
   * @name listRules
   * @request GET:/timescheme/rules
   */
  export namespace ListRules {
    export type RequestQuery = { type: TimeSchemeIntegrationRuleServiceType };
    export type RequestBody = never;
    export type ResponseBody = TimeSchemeIntegrationRule[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags time-scheme-rules
   * @name createRule
   * @request POST:/timescheme/rules
   */
  export namespace CreateRule {
    export type RequestQuery = {};
    export type RequestBody = TimeSchemeIntegrationRuleCreateRequest;
    export type ResponseBody = TimeSchemeIntegrationRule;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags time-scheme-rules
   * @name deleteRule
   * @request DELETE:/timescheme/rules/{ruleId}
   */
  export namespace DeleteRule {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags time-scheme-rules
   * @name updateRule
   * @request PATCH:/timescheme/rules/{ruleId}
   */
  export namespace UpdateRule {
    export type RequestQuery = {};
    export type RequestBody = TimeSchemeIntegrationRuleUpdateRequest;
    export type ResponseBody = TimeSchemeIntegrationRule;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags time-scheme-rules
   * @name reindex_1
   * @request PATCH:/timescheme/rules/{ruleId}/reindex
   */
  export namespace Reindex1 {
    export type RequestQuery = {};
    export type RequestBody = TimeSchemeIntegrationRuleReindexRequest;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace timeschemes {
  /**
   * @tags time-scheme
   * @name listTimePolicySchemes
   * @request GET:/timeschemes
   */
  export namespace ListTimePolicySchemes {
    export type RequestQuery = {
      taskCategory?: TaskCategory | null;
      policyType?: TimePolicyType[] | null | null;
      features?: ReclaimEventType[] | null | null;
    };
    export type RequestBody = never;
    export type ResponseBody = TimeScheme[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags time-scheme
   * @name createTimeScheme
   * @request POST:/timeschemes
   */
  export namespace CreateTimeScheme {
    export type RequestQuery = {};
    export type RequestBody = TimeSchemeCreateRequest;
    export type ResponseBody = TimeScheme;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags time-scheme
   * @name queryTimePolicySchemesByUserIdAndFeature
   * @request GET:/timeschemes/filter-by-feature
   */
  export namespace QueryTimePolicySchemesByUserIdAndFeature {
    export type RequestQuery = { userId: string; feature: ReclaimEventType };
    export type RequestBody = never;
    export type ResponseBody = TimeScheme[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags time-scheme
   * @name listTimePolicySchemesByUserIdAndFeature
   * @request GET:/timeschemes/filter-by-feature/{userId}
   */
  export namespace ListTimePolicySchemesByUserIdAndFeature {
    export type RequestQuery = { feature: ReclaimEventType };
    export type RequestBody = never;
    export type ResponseBody = TimeScheme[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags time-scheme
   * @name updateTimeScheme
   * @request GET:/timeschemes/{schemeId}
   */
  export namespace UpdateTimeScheme {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = TimeScheme;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags time-scheme
   * @name deleteTimeScheme
   * @request DELETE:/timeschemes/{schemeId}
   */
  export namespace DeleteTimeScheme {
    export type RequestQuery = { moveToTimeSchemeId?: string | null };
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags time-scheme
   * @name updateTimeScheme_1
   * @request PATCH:/timeschemes/{schemeId}
   */
  export namespace UpdateTimeScheme1 {
    export type RequestQuery = {};
    export type RequestBody = TimeSchemeUpdateRequest;
    export type ResponseBody = TimeScheme;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace todoist {
  /**
   * @tags integration, private, todoist
   * @name integrations_5
   * @request GET:/todoist/integrations
   */
  export namespace Integrations5 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = TodoistIntegration[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags integration, private, todoist
   * @name integrationsDetails
   * @request GET:/todoist/integrations/details
   */
  export namespace IntegrationsDetails {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = TodoistIntegrationDetails[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags integration, private, todoist
   * @name deleteIntegration_4
   * @request DELETE:/todoist/integrations/{id}
   */
  export namespace DeleteIntegration4 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags integration, private, todoist
   * @name patchIntegration_3
   * @request PATCH:/todoist/integrations/{id}
   */
  export namespace PatchIntegration3 {
    export type RequestQuery = {};
    export type RequestBody = TodoistIntegrationPatch;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace users {
  /**
   * @tags users
   * @name current
   * @summary User details for the currently authenticated user
   * @request GET:/users/current
   * @description User details for the currently authenticated user
   */
  export namespace Current {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = User;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags users
   * @name delete_5
   * @request DELETE:/users/current
   */
  export namespace Delete5 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags users
   * @name patch_3
   * @request PATCH:/users/current
   */
  export namespace Patch3 {
    export type RequestQuery = {};
    export type RequestBody = User;
    export type ResponseBody = User;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags contacts
   * @name getContacts
   * @request GET:/users/current/contacts
   * @secure
   */
  export namespace GetContacts {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = ThinPerson[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags contacts
   * @name inviteContacts
   * @request POST:/users/current/contacts/invite
   */
  export namespace InviteContacts {
    export type RequestQuery = {};
    export type RequestBody = ThinPerson[];
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags users
   * @name onboarded
   * @request POST:/users/current/onboarded
   */
  export namespace Onboarded {
    export type RequestQuery = { notificationKey?: string | null };
    export type RequestBody = OnboardedState;
    export type ResponseBody = User;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags users
   * @name productUsage
   * @request GET:/users/current/product-usage
   */
  export namespace ProductUsage {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = ProductUsageReport;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags users
   * @name hardDowngrade
   * @request POST:/users/current/product-usage/hard-downgrade
   */
  export namespace HardDowngrade {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = ProductUsageReport;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags quest
   * @name getCompletedQuests
   * @request GET:/users/current/quest
   */
  export namespace GetCompletedQuests {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = UserQuests;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags quest
   * @name completeQuest
   * @request PATCH:/users/current/quest
   */
  export namespace CompleteQuest {
    export type RequestQuery = {};
    export type RequestBody = { questType?: QuestType };
    export type ResponseBody = UserQuests;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags users
   * @name referrals
   * @request GET:/users/current/referrals
   */
  export namespace Referrals {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = ReferralStats;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags users
   * @name claimRewards
   * @request POST:/users/current/rewards
   */
  export namespace ClaimRewards {
    export type RequestQuery = { claim: number };
    export type RequestBody = never;
    export type ResponseBody = ReferralStats;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags timePolicies
   * @name get_4
   * @request GET:/users/current/timePolicies
   */
  export namespace Get4 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Record<string, CustomTimePolicy>;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags timePolicies
   * @name patch_4
   * @request PATCH:/users/current/timePolicies
   */
  export namespace Patch4 {
    export type RequestQuery = {};
    export type RequestBody = User;
    export type ResponseBody = Record<string, CustomTimePolicy>;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags users
   * @name interest_2
   * @request POST:/users/interest
   */
  export namespace Interest2 {
    export type RequestQuery = { feature: string };
    export type RequestBody = never;
    export type ResponseBody = User;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags users
   * @name addTrait
   * @request POST:/users/trait/{trait}
   */
  export namespace AddTrait {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = User;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace weeklyReport {
  /**
   * @tags weekly-report
   * @name social
   * @request GET:/weekly-report/social
   */
  export namespace Social {
    export type RequestQuery = { id: string; verificationKey?: string | null };
    export type RequestBody = never;
    export type ResponseBody = WeeklyReportTemplateData;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags weekly-report
   * @name socialImage
   * @request GET:/weekly-report/social/{id}.png
   */
  export namespace SocialImage {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags weekly-report
   * @name socialImageAlt
   * @request GET:/weekly-report/social/{trackingCode}/{suffix}.png
   */
  export namespace SocialImageAlt {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags weekly-report
   * @name unsubscribe_1
   * @request POST:/weekly-report/unsubscribe
   */
  export namespace Unsubscribe1 {
    export type RequestQuery = {};
    export type RequestBody = WeeklyReportUnsubscribeRequest;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace avatar {
  /**
   * @name credential
   * @request GET:/avatar/credential/{credentialId}
   */
  export namespace Credential {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name avatar
   * @request GET:/avatar/lookup
   */
  export namespace Avatar {
    export type RequestQuery = { email: string };
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name me
   * @request GET:/avatar/me
   */
  export namespace Me {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace aws {
  /**
   * @tags aws-ses
   * @name sesEvent
   * @request POST:/aws/ses/events
   */
  export namespace SesEvent {
    export type RequestQuery = {};
    export type RequestBody = LocalTime;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace oauth {
  /**
   * @name accountInit
   * @request GET:/oauth/zoom/account/init
   */
  export namespace AccountInit {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name initZoom
   * @request GET:/oauth/zoom/init
   */
  export namespace InitZoom {
    export type RequestQuery = { state?: string | null };
    export type RequestBody = never;
    export type ResponseBody = object | null;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace scim {
  /**
   * @name getGroups
   * @request GET:/scim/v2/Groups
   */
  export namespace GetGroups {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = ScimResourceList;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name getResourceTypes
   * @request GET:/scim/v2/ResourceTypes
   */
  export namespace GetResourceTypes {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name getSchemas
   * @request GET:/scim/v2/Schemas
   */
  export namespace GetSchemas {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name getServiceProvider
   * @request GET:/scim/v2/ServiceProvider
   */
  export namespace GetServiceProvider {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name getUsers
   * @request GET:/scim/v2/Users
   */
  export namespace GetUsers {
    export type RequestQuery = {
      attributes?: string[] | null | null;
      startIndex?: number | null;
      count?: number | null;
      filter?: string | null;
    };
    export type RequestBody = never;
    export type ResponseBody = ScimResourceList;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name putUser
   * @request PUT:/scim/v2/Users
   */
  export namespace PutUser {
    export type RequestQuery = {};
    export type RequestBody = ScimUser;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name createUser
   * @request POST:/scim/v2/Users
   */
  export namespace CreateUser {
    export type RequestQuery = {};
    export type RequestBody = ScimUser;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name getUser
   * @request GET:/scim/v2/Users/{id}
   */
  export namespace GetUser {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name putUserId
   * @request PUT:/scim/v2/Users/{id}
   */
  export namespace PutUserId {
    export type RequestQuery = {};
    export type RequestBody = ScimUser;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name deleteUser
   * @request DELETE:/scim/v2/Users/{id}
   */
  export namespace DeleteUser {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @name patchUser
   * @request PATCH:/scim/v2/Users/{id}
   */
  export namespace PatchUser {
    export type RequestQuery = {};
    export type RequestBody = ScimPatch;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace web {
  /**
   * @tags asana, integration, private
   * @name fieldChangeCallback
   * @request POST:/web/asana/formfieldchange
   */
  export namespace FieldChangeCallback {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags asana, integration, private
   * @name fieldChangeCallbackPreflight
   * @request OPTIONS:/web/asana/formfieldchange
   */
  export namespace FieldChangeCallbackPreflight {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags asana, integration, private
   * @name formCallback
   * @request POST:/web/asana/formsubmit
   */
  export namespace FormCallback {
    export type RequestQuery = {};
    export type RequestBody = AsanaSyncFormSubmission;
    export type ResponseBody = AsanaAttachedResource;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags asana, integration, private
   * @name formCallbackPreflight
   * @request OPTIONS:/web/asana/formsubmit
   */
  export namespace FormCallbackPreflight {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags asana, integration, private
   * @name syncForm
   * @request GET:/web/asana/syncform
   */
  export namespace SyncForm {
    export type RequestQuery = { workspace: string; task: string; expires_at?: string | null };
    export type RequestBody = never;
    export type ResponseBody = AsanaFormMetadata;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags asana, integration, private
   * @name syncFormPreflight
   * @request OPTIONS:/web/asana/syncform
   */
  export namespace SyncFormPreflight {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags asana, integration, private
   * @name taskDetails
   * @request GET:/web/asana/task/{asanaTaskId}
   */
  export namespace TaskDetails {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags asana, integration, private
   * @name taskDetailsPreflight
   * @request OPTIONS:/web/asana/task/{asanaTaskId}
   */
  export namespace TaskDetailsPreflight {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags asana, integration, private
   * @name widget
   * @request GET:/web/asana/widget
   */
  export namespace Widget {
    export type RequestQuery = { workspace: string; task: string; attachment: string; expires_at?: string | null };
    export type RequestBody = never;
    export type ResponseBody = AsanaWidgetMetadata;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags asana, integration, private
   * @name widgetPreflight
   * @request OPTIONS:/web/asana/widget
   */
  export namespace WidgetPreflight {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags clickup, integration, private
   * @name taskDetails_1
   * @request GET:/web/clickup/task/{taskId}
   */
  export namespace TaskDetails1 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags integration, jira, private
   * @name taskDetails_2
   * @request GET:/web/jira/task/{issueKey}/{issueId}
   */
  export namespace TaskDetails2 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags integration, linear, private
   * @name taskDetails_3
   * @request GET:/web/linear/task/{id}
   */
  export namespace TaskDetails3 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = LinearTaskDetails;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags integration, private, todoist
   * @name taskDetails_4
   * @request GET:/web/todoist/task/{itemId}
   */
  export namespace TaskDetails4 {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace ws {
  /**
   * @tags websocket
   * @name swaggerHelper
   * @summary This is a bogus endpoint that gives us swagger generated objects for our websocket specific classes.
   * @request POST:/ws/bogus
   * @description This is a bogus endpoint that gives us swagger generated objects for our websocket specific classes.
   */
  export namespace SwaggerHelper {
    export type RequestQuery = {};
    export type RequestBody = {
      user?: User;
      envelope?: WebSocketResponseEnvelope;
      assistCompleted?: AssistCompleted;
      assistPlanned?: AssistPlanned;
    };
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags websocket
   * @name connect_1
   * @summary How do we get the connectionId? # List all integrations aws apigatewayv2 get-integrations --api-id xxxxxxxxx # Update all @connect integration aws apigatewayv2 update-integration --integration-id zzzzzzzz --api-id xxxxxxxxx --request-parameters 'integration.
   * @request POST:/ws/connect
   * @description How do we get the connectionId? # List all integrations aws apigatewayv2 get-integrations --api-id xxxxxxxxx # Update all @connect integration aws apigatewayv2 update-integration --integration-id zzzzzzzz --api-id xxxxxxxxx --request-parameters 'integration.request.header.connectionId'='context.connectionId'
   */
  export namespace Connect1 {
    export type RequestQuery = {};
    export type RequestBody = { user?: User };
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags websocket
   * @name defaultHandler
   * @request POST:/ws/default
   */
  export namespace DefaultHandler {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags websocket
   * @name disconnect
   * @request POST:/ws/disconnect
   */
  export namespace Disconnect {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags websocket
   * @name subscribe
   * @request POST:/ws/subscribe
   */
  export namespace Subscribe {
    export type RequestQuery = {};
    export type RequestBody = { subscribeAction?: SubscribeAction };
    export type ResponseBody = WebSocketResponseEnvelope;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags websocket
   * @name unsubscribe_2
   * @request POST:/ws/unsubscribe
   */
  export namespace Unsubscribe2 {
    export type RequestQuery = {};
    export type RequestBody = { unsubscribeAction?: UnsubscribeAction };
    export type ResponseBody = Annotation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}

export const HEADER_BUILD_ID = "X-Reclaim-Build-Id";

export type RequestParams = Omit<AxiosRequestConfig, "url" | "data" | "method"> & {
  secure?: boolean;
};

// TODO: The need for this is hard codded into the codegen source... so dumb
enum BodyType {
  Json,
}

export type RequestQueryParamsType = Record<string | number, any>;

export type ApiConfig<SecurityDataType> = {
  baseUrl?: string;
  baseApiParams?: RequestParams;
  securityWorker?: (securityData: SecurityDataType) => RequestParams;
};

/** Overrided Promise type. Needs for additional typings of `.catch` callback */
export type TypedErrorPromise<ResolveType, RejectType = unknown> = Omit<Promise<ResolveType>, "then" | "catch"> & {
  then<TResult1 = ResolveType, TResult2 = never>(
    onfulfilled?: ((value: ResolveType) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onrejected?: ((reason: RejectType) => TResult2 | PromiseLike<TResult2>) | undefined | null,
  ): Promise<TResult1 | TResult2>;
  catch<TResult = never>(
    onrejected?: ((reason: RejectType) => TResult | PromiseLike<TResult>) | undefined | null,
  ): Promise<ResolveType | TResult>;
  finally<TResult = never>(onfinally?: (() => void) | undefined | null): Promise<ResolveType | TResult>;
  readonly [Symbol.toStringTag]: string;
};

export class ReclaimApiError<
  E extends { status: number; response?: unknown | null } = { status: number; response?: unknown | null },
> extends Error {
  status: E["status"];
  statusText?: string;
  headers: unknown;
  body: E["response"];
  handled: boolean;

  constructor(res: AxiosResponse, message?: string) {
    super(message);

    if (!res && !message) message = "No response object";

    this.status = res?.status;
    this.statusText = res?.statusText || res.data?.message || message;
    this.headers = res?.headers;
    this.body = res?.data;
  }
}

export class NetworkError extends Error {
  status: "NetworkError" = "NetworkError";
  statusText: any;
  headers: any;
  body: any;
  handled: boolean;

  constructor(res: AxiosResponse, message?: string) {
    super(message);

    if (!res && !message) message = "No response object";

    this.statusText = res?.statusText || res.data?.message || message;
    this.headers = res?.headers;
    this.body = res?.data;
  }
}

// export type ClientConfig = ApiConfig<SecurityDataType,> & {BUILD_ID?: string}
export type ClientConfig = ApiConfig<any> & { BUILD_ID?: string };

class HttpClient<SecurityDataType> {
  public baseUrl: string = "https://api.app.reclaim.ai/api";
  public client: AxiosInstance;
  private securityData: SecurityDataType = null as any;
  private securityWorker: ApiConfig<SecurityDataType>["securityWorker"] = (() => {}) as any;
  private baseApiParams: RequestParams = {
    headers: {
      "Content-Type": "application/json",
      Referrer: "no-referrer",
    },
  };

  private requestHooks = {};
  private responseHooks = {};

  constructor({ baseUrl, baseApiParams, securityWorker, BUILD_ID }: ClientConfig = { BUILD_ID: "unknown" }) {
    if (!!baseUrl) this.baseUrl = baseUrl;
    if (!!baseApiParams) this.baseApiParams = baseApiParams;
    if (!!securityWorker) this.securityWorker = securityWorker;
    this.client = axios.create({
      baseURL: baseUrl || this.baseUrl,
      withCredentials: true,
      headers: {
        [HEADER_BUILD_ID]: BUILD_ID,
        ...(this.baseApiParams.headers || {}),
        post: {
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    });
    this.client.interceptors.request.use((config) => {
      Object.keys(this.requestHooks).forEach((name) => {
        this.requestHooks[name](config);
      });

      return config;
    });

    this.client.interceptors.response.use(
      (r) => {
        Object.keys(this.responseHooks).forEach((name) => {
          if (r.status) {
            return this.responseHooks[`${name} - Status: ${r.status}`](new ReclaimApiError(r));
          }

          this.responseHooks[`${name} - Status: network`](new NetworkError(r));
        });
        return r;
      },
      (error) => {
        Object.keys(this.responseHooks).forEach((name) => {
          this.responseHooks[name](error);
        });
        return error;
      },
    );
  }

  public setSecurityData = (data: SecurityDataType) => {
    this.securityData = data;
  };

  private addQueryParam(query: RequestQueryParamsType, key: string) {
    return (
      encodeURIComponent(key) + "=" + encodeURIComponent(Array.isArray(query[key]) ? query[key].join(",") : query[key])
    );
  }

  protected addQueryParams(rawQuery?: RequestQueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys.length
      ? `?${keys
          .map((key) =>
            typeof query[key] === "object" && !Array.isArray(query[key])
              ? this.addQueryParams(query[key] as object).substring(1)
              : this.addQueryParam(query, key),
          )
          .join("&")}`
      : "";
  }

  public registerRequestHook(name: string, fn: (config: AxiosRequestConfig) => void | Promise<void>) {
    this.requestHooks[name] = fn;
  }

  public registerResponseHook(
    name: string,
    status: 401 | 500 | "NetworkError",
    fn: (error: any) => void | Promise<void>,
  ) {
    this.responseHooks[`${name} - Status: ${status}`] = fn;
  }

  public request = <T extends unknown, E extends { status: number; response?: unknown | null }>(
    path: string,
    method: AxiosRequestConfig["method"],
    { secure, ...params }: RequestParams = {},
    data?: any,
    _dumbUnusedBodyType?: any,
    secureByDefault?: boolean,
  ): TypedErrorPromise<T, ReclaimApiError<E> | NetworkError> =>
    this.client
      .request({
        url: path,
        ...params,
        method,
        data,
        validateStatus: function (status) {
          return true;
        },
      })
      .then((r) => {
        if (r.status >= 200 && r.status < 300) {
          return !!r.data ? r.data : null;
        } else if (!r.status) {
          throw new NetworkError(r);
        } else {
          throw new ReclaimApiError(r);
        }
      });
}

/**
 * @title Reclaim API
 * @version 0.1
 * @baseUrl https://api.app.reclaim.ai/api
 * Reclaim's awesome API
 */
export class ReclaimApi<SecurityDataType = any> extends HttpClient<SecurityDataType> {
  accounts = {
    /**
     * @tags accounts
     * @name list
     * @request GET:/accounts
     */
    list: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        ConnectedAccount[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/accounts`, "GET", params),

    /**
     * @tags accounts
     * @name validateAll
     * @request POST:/accounts/validate
     */
    validateAll: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/accounts/validate`,
        "POST",
        params,
      ),

    /**
     * @tags accounts
     * @name getCalendars
     * @request GET:/accounts/{accountId}/calendars
     */
    getCalendars: <ErrorTypes extends { status: number; response: any }>(accountId: number, params?: RequestParams) =>
      this.request<
        CalendarView[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/accounts/${accountId}/calendars`, "GET", params),

    /**
     * @tags accounts
     * @name setAvailability
     * @request PUT:/accounts/{accountId}/calendars/connected
     */
    setAvailability: <ErrorTypes extends { status: number; response: any }>(
      accountId: number,
      data: string[],
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/accounts/${accountId}/calendars/connected`,
        "PUT",
        params,
        data,
      ),

    /**
     * @tags accounts
     * @name delete
     * @request DELETE:/accounts/{id}
     */
    delete: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/accounts/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @tags accounts
     * @name validate
     * @request POST:/accounts/{id}/validate
     */
    validate: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/accounts/${id}/validate`,
        "POST",
        params,
      ),
  };
  admin = {
    /**
     * @name admin_createUser
     * @request POST:/admin/users
     */
    adminCreateUser: <ErrorTypes extends { status: number; response: any }>(
      data: UserPayload,
      params?: RequestParams,
    ) =>
      this.request<UserView, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/admin/users`,
        "POST",
        params,
        data,
      ),

    /**
     * @name admin_deleteUser
     * @request DELETE:/admin/users/{username}
     */
    adminDeleteUser: <ErrorTypes extends { status: number; response: any }>(username: string, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/admin/users/${username}`,
        "DELETE",
        params,
      ),

    /**
     * @name admin_listAccounts
     * @request GET:/admin/users/{username}/accounts
     */
    adminListAccounts: <ErrorTypes extends { status: number; response: any }>(
      username: string,
      params?: RequestParams,
    ) =>
      this.request<
        ConnectedAccount[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/admin/users/${username}/accounts`, "GET", params),

    /**
     * @name admin_createAccount
     * @request POST:/admin/users/{username}/accounts
     */
    adminCreateAccount: <ErrorTypes extends { status: number; response: any }>(
      username: string,
      data: { accountPayload?: ConnectedAccountPayload },
      params?: RequestParams,
    ) =>
      this.request<
        ConnectedAccount,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/admin/users/${username}/accounts`, "POST", params, data),

    /**
     * @name admin_switchMainAccount
     * @request POST:/admin/users/{username}/accounts/{accountId}/main
     */
    adminSwitchMainAccount: <ErrorTypes extends { status: number; response: any }>(
      username: string,
      accountId: number,
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/admin/users/${username}/accounts/${accountId}/main`,
        "POST",
        params,
      ),

    /**
     * @name admin_createCalendarSync
     * @request POST:/admin/users/{username}/calendar-syncs
     */
    adminCreateCalendarSync: <ErrorTypes extends { status: number; response: any }>(
      username: string,
      data: CreateCalendarSyncRequest,
      params?: RequestParams,
    ) =>
      this.request<CalendarSync, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/admin/users/${username}/calendar-syncs`,
        "POST",
        params,
        data,
      ),

    /**
     * @name admin_getCalendarSyncs
     * @request GET:/admin/users/{username}/calendar-syncs/
     */
    adminGetCalendarSyncs: <ErrorTypes extends { status: number; response: any }>(
      username: string,
      params?: RequestParams,
    ) =>
      this.request<
        CalendarSync[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/admin/users/${username}/calendar-syncs/`, "GET", params),

    /**
     * @name admin_deleteSync
     * @request DELETE:/admin/users/{username}/calendar-syncs/{sourceId}/to/{targetId}
     */
    adminDeleteSync: <ErrorTypes extends { status: number; response: any }>(
      username: string,
      sourceId: number,
      targetId: number,
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/admin/users/${username}/calendar-syncs/${sourceId}/to/${targetId}`,
        "DELETE",
        params,
      ),

    /**
     * @name admin_patchSync
     * @request PATCH:/admin/users/{username}/calendar-syncs/{sourceId}/to/{targetId}
     */
    adminPatchSync: <ErrorTypes extends { status: number; response: any }>(
      username: string,
      sourceId: number,
      targetId: number,
      data: Annotation,
      params?: RequestParams,
    ) =>
      this.request<CalendarSync, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/admin/users/${username}/calendar-syncs/${sourceId}/to/${targetId}`,
        "PATCH",
        params,
        data,
      ),
  };
  analytics = {
    /**
     * @tags analytics
     * @name analytics_teamAnalytics
     * @request GET:/analytics/team
     */
    analyticsTeamAnalytics: <ErrorTypes extends { status: number; response: any }>(
      query: {
        start: string;
        end: string;
        metricName: AnalyticsMetricName[];
        groupingInterval?: AnalyticsGroupingInterval | null;
      },
      params?: RequestParams,
    ) =>
      this.request<
        AnalyticsResults,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/analytics/team${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags analytics
     * @name analytics_teamAnalyticsV2
     * @request GET:/analytics/team/V2
     */
    analyticsTeamAnalyticsV2: <ErrorTypes extends { status: number; response: any }>(
      query: {
        start: string;
        end: string;
        metricName: AnalyticsMetricName[];
        groupingInterval?: AnalyticsGroupingInterval | null;
        notificationKey?: string | null;
      },
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/analytics/team/V2${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags analytics
     * @name analytics_userAnalytics
     * @request GET:/analytics/user
     */
    analyticsUserAnalytics: <ErrorTypes extends { status: number; response: any }>(
      query: {
        start: string;
        end: string;
        metricName: AnalyticsMetricName[];
        groupingInterval?: AnalyticsGroupingInterval | null;
      },
      params?: RequestParams,
    ) =>
      this.request<
        AnalyticsResults,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/analytics/user${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags analytics
     * @name analytics_userAnalyticsV2
     * @request GET:/analytics/user/V2
     */
    analyticsUserAnalyticsV2: <ErrorTypes extends { status: number; response: any }>(
      query: {
        start: string;
        end: string;
        metricName: AnalyticsMetricName[];
        groupingInterval?: AnalyticsGroupingInterval | null;
        notificationKey?: string | null;
      },
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/analytics/user/V2${this.addQueryParams(query)}`,
        "GET",
        params,
      ),
  };
  apiManagement = {
    /**
     * @tags api-management
     * @name createApiKey
     * @request POST:/api-management/api-key
     */
    createApiKey: <ErrorTypes extends { status: number; response: any }>(data: ApiKey, params?: RequestParams) =>
      this.request<ApiKey, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/api-management/api-key`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags api-management
     * @name updateApiKey
     * @request PATCH:/api-management/api-key
     */
    updateApiKey: <ErrorTypes extends { status: number; response: any }>(data: ApiKey, params?: RequestParams) =>
      this.request<ApiKey, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/api-management/api-key`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @tags api-management
     * @name reissueApiKey
     * @request PATCH:/api-management/api-key/reissue/{id}
     */
    reissueApiKey: <ErrorTypes extends { status: number; response: any }>(id: string, params?: RequestParams) =>
      this.request<ApiKey, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/api-management/api-key/reissue/${id}`,
        "PATCH",
        params,
      ),

    /**
     * @tags api-management
     * @name listApiKey
     * @request GET:/api-management/api-key/{id}
     */
    listApiKey: <ErrorTypes extends { status: number; response: any }>(id: string, params?: RequestParams) =>
      this.request<ApiKey, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/api-management/api-key/${id}`,
        "GET",
        params,
      ),

    /**
     * @tags api-management
     * @name deleteApiKey
     * @request DELETE:/api-management/api-key/{id}
     */
    deleteApiKey: <ErrorTypes extends { status: number; response: any }>(id: string, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/api-management/api-key/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @tags api-management
     * @name listApiKey_1
     * @request GET:/api-management/api-keys
     */
    listApiKey1: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<ApiKey[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/api-management/api-keys`,
        "GET",
        params,
      ),
  };
  asana = {
    /**
     * @tags asana, integration, private
     * @name integrations
     * @request GET:/asana/integrations
     */
    integrations: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        AsanaIntegration[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/asana/integrations`, "GET", params),

    /**
     * @tags asana, integration, private
     * @name deleteIntegration
     * @request DELETE:/asana/integrations
     */
    deleteIntegration: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        AsanaIntegration[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/asana/integrations`, "DELETE", params),
  };
  assist = {
    /**
     * @tags assist
     * @name getDailyHabits
     * @request GET:/assist/habits/daily
     */
    getDailyHabits: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<DailyHabit[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/assist/habits/daily`,
        "GET",
        params,
      ),

    /**
     * @tags assist
     * @name create
     * @request POST:/assist/habits/daily
     */
    create: <ErrorTypes extends { status: number; response: any }>(
      data: DailyHabit,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<DailyHabit[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/assist/habits/daily${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags assist
     * @name getDailyHabit
     * @request GET:/assist/habits/daily/{id}
     */
    getDailyHabit: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<DailyHabit, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/assist/habits/daily/${id}`,
        "GET",
        params,
      ),

    /**
     * @tags assist
     * @name update
     * @request PUT:/assist/habits/daily/{id}
     */
    update: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      data: DailyHabit,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<DailyHabit[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/assist/habits/daily/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @tags assist
     * @name delete_1
     * @request DELETE:/assist/habits/daily/{id}
     */
    delete1: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/assist/habits/daily/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @tags assist
     * @name patch
     * @request PATCH:/assist/habits/daily/{id}
     */
    patch: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      data: DailyHabit,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<DailyHabit[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/assist/habits/daily/${id}${this.addQueryParams(query)}`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @tags assist
     * @name getHabitTemplate
     * @request GET:/assist/habits/template
     */
    getHabitTemplate: <ErrorTypes extends { status: number; response: any }>(
      query: { templateKey: HabitTemplateKey },
      params?: RequestParams,
    ) =>
      this.request<
        DailyHabitTemplate,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/assist/habits/template${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags assist
     * @name createHabitTemplates
     * @request POST:/assist/habits/template/create
     */
    createHabitTemplates: <ErrorTypes extends { status: number; response: any }>(
      data: { templates?: HabitTemplateKey[] },
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        HabitTemplateKey[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/assist/habits/template/create${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags assist
     * @name getHabitTemplates
     * @request GET:/assist/habits/templates
     */
    getHabitTemplates: <ErrorTypes extends { status: number; response: any }>(
      query?: { role?: UserProfileRole | null; department?: UserProfileDepartment | null },
      params?: RequestParams,
    ) =>
      this.request<
        DailyHabitTemplate[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/assist/habits/templates${this.addQueryParams(query)}`, "GET", params),
  };
  calendars = {
    /**
     * @tags calendars
     * @name getAllPersonal
     * @request GET:/calendars/personal
     */
    getAllPersonal: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Calendar[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/personal`,
        "GET",
        params,
      ),

    /**
     * @tags calendars
     * @name getPersonalCandidates
     * @request GET:/calendars/personal/candidates
     */
    getPersonalCandidates: <ErrorTypes extends { status: number; response: any }>(
      query?: { credentialId?: number | null },
      params?: RequestParams,
    ) =>
      this.request<Calendar[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/personal/candidates${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags calendars
     * @name getPersonal
     * @request GET:/calendars/personal/{id}
     */
    getPersonal: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<Calendar, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/personal/${id}`,
        "GET",
        params,
      ),

    /**
     * @tags calendars
     * @name deletePersonal
     * @request DELETE:/calendars/personal/{id}
     */
    deletePersonal: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/personal/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @tags calendars
     * @name getPrimary
     * @request GET:/calendars/primary
     */
    getPrimary: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Calendar, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/primary`,
        "GET",
        params,
      ),

    /**
     * @tags calendars
     * @name share
     * @request POST:/calendars/share
     */
    share: <ErrorTypes extends { status: number; response: any }>(
      query: { credentialId: number },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/share${this.addQueryParams(query)}`,
        "POST",
        params,
      ),

    /**
     * @tags calendars
     * @name getAllSync
     * @request GET:/calendars/sync
     */
    getAllSync: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Calendar[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync`,
        "GET",
        params,
      ),

    /**
     * @tags calendars
     * @name createSync
     * @request POST:/calendars/sync
     */
    createSync: <ErrorTypes extends { status: number; response: any }>(
      data: { calendar?: Calendar },
      params?: RequestParams,
    ) =>
      this.request<Calendar, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags calendarSyncPolicy
     * @name getSyncPolicies
     * @request GET:/calendars/sync-policy
     */
    getSyncPolicies: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        CalendarSyncPolicy[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/calendars/sync-policy`, "GET", params),

    /**
     * @tags calendarSyncPolicy
     * @name createSyncPolicy
     * @request POST:/calendars/sync-policy
     */
    createSyncPolicy: <ErrorTypes extends { status: number; response: any }>(
      data: { calendarSyncPolicy?: CalendarSyncPolicy },
      params?: RequestParams,
    ) =>
      this.request<
        CalendarSyncPolicy,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/calendars/sync-policy`, "POST", params, data),

    /**
     * @tags calendarSyncPolicy
     * @name testSyncPolicy
     * @request POST:/calendars/sync-policy/validate
     */
    testSyncPolicy: <ErrorTypes extends { status: number; response: any }>(
      data: CalendarSyncPolicy,
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync-policy/validate`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags calendarSyncPolicy
     * @name getSyncPolicy
     * @request GET:/calendars/sync-policy/{sourceId}/to/{targetId}
     */
    getSyncPolicy: <ErrorTypes extends { status: number; response: any }>(
      sourceId: number,
      targetId: number,
      params?: RequestParams,
    ) =>
      this.request<
        CalendarSyncPolicy,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/calendars/sync-policy/${sourceId}/to/${targetId}`, "GET", params),

    /**
     * @tags calendarSyncPolicy
     * @name deleteSyncPolicy
     * @request DELETE:/calendars/sync-policy/{sourceId}/to/{targetId}
     */
    deleteSyncPolicy: <ErrorTypes extends { status: number; response: any }>(
      sourceId: number,
      targetId: number,
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync-policy/${sourceId}/to/${targetId}`,
        "DELETE",
        params,
      ),

    /**
     * @tags calendarSyncPolicy
     * @name patchSyncPolicy
     * @request PATCH:/calendars/sync-policy/{sourceId}/to/{targetId}
     */
    patchSyncPolicy: <ErrorTypes extends { status: number; response: any }>(
      sourceId: number,
      targetId: number,
      data: CalendarSyncPolicy,
      params?: RequestParams,
    ) =>
      this.request<
        CalendarSyncPolicy,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/calendars/sync-policy/${sourceId}/to/${targetId}`, "PATCH", params, data),

    /**
     * @tags calendars
     * @name getSyncCandidates
     * @request GET:/calendars/sync/candidates
     */
    getSyncCandidates: <ErrorTypes extends { status: number; response: any }>(
      query?: { credentialId?: number | null },
      params?: RequestParams,
    ) =>
      this.request<Calendar[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/candidates${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags calendarSyncEvents
     * @name getSyncEventsForUser
     * @request GET:/calendars/sync/events
     * @secure
     */
    getSyncEventsForUser: <ErrorTypes extends { status: number; response: any }>(
      query: { limit: number },
      params?: RequestParams,
    ) =>
      this.request<SyncEvent[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/events${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags calendarSyncEvents
     * @name getSyncEventsForCalendar
     * @request GET:/calendars/sync/events/{calendarId}
     * @secure
     */
    getSyncEventsForCalendar: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      query: { limit: number },
      params?: RequestParams,
    ) =>
      this.request<SyncEvent[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/events/${calendarId}${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags calendarSyncEvents
     * @name getSyncEventsForPolicy
     * @request GET:/calendars/sync/events/{sourceId}/to/{targetId}
     * @secure
     */
    getSyncEventsForPolicy: <ErrorTypes extends { status: number; response: any }>(
      sourceId: number,
      targetId: number,
      query: { limit: number },
      params?: RequestParams,
    ) =>
      this.request<SyncEvent[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/events/${sourceId}/to/${targetId}${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags calendars
     * @name interest
     * @request POST:/calendars/sync/interest
     */
    interest: <ErrorTypes extends { status: number; response: any }>(query: { type: string }, params?: RequestParams) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/interest${this.addQueryParams(query)}`,
        "POST",
        params,
      ),

    /**
     * @tags calendars
     * @name getSourceCandidates
     * @request GET:/calendars/sync/{credentialId}/candidates/sources
     */
    getSourceCandidates: <ErrorTypes extends { status: number; response: any }>(
      credentialId: number,
      params?: RequestParams,
    ) =>
      this.request<Calendar[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/${credentialId}/candidates/sources`,
        "GET",
        params,
      ),

    /**
     * @tags calendars
     * @name getTargetCandidates
     * @request GET:/calendars/sync/{credentialId}/candidates/targets
     */
    getTargetCandidates: <ErrorTypes extends { status: number; response: any }>(
      credentialId: number,
      params?: RequestParams,
    ) =>
      this.request<Calendar[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/${credentialId}/candidates/targets`,
        "GET",
        params,
      ),

    /**
     * @tags calendars
     * @name getSync
     * @request GET:/calendars/sync/{id}
     */
    getSync: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<Calendar, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/${id}`,
        "GET",
        params,
      ),

    /**
     * @tags calendars
     * @name deleteSync
     * @request DELETE:/calendars/sync/{id}
     */
    deleteSync: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/sync/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @tags calendars
     * @name watch
     * @request POST:/calendars/watch
     */
    watch: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<LocalTime, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/watch`,
        "POST",
        params,
      ),

    /**
     * @tags calendars
     * @name listWatch
     * @summary Heads up! We have seen at least one case where Google can issue an excessive, unnatural amount of watch callbacks.
     * @request POST:/calendars/watchList
     * @description Heads up! We have seen at least one case where Google can issue an excessive, unnatural amount of watch callbacks. for more information. Specifically, BE CAREFUL adding any blocking IO code, such as web service calls or database queries, upstream of that job.
     */
    listWatch: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<LocalTime, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/watchList`,
        "POST",
        params,
      ),

    /**
     * @tags calendars
     * @name settingsWatch
     * @request POST:/calendars/watchSettings
     */
    settingsWatch: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<LocalTime, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/calendars/watchSettings`,
        "POST",
        params,
      ),
  };
  clickup = {
    /**
     * @tags clickup, integration, private
     * @name integrations_1
     * @request GET:/clickup/integrations
     */
    integrations1: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        ClickUpIntegration[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/clickup/integrations`, "GET", params),

    /**
     * @tags clickup, integration, private
     * @name deleteIntegration_1
     * @request DELETE:/clickup/integrations
     */
    deleteIntegration1: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/clickup/integrations`,
        "DELETE",
        params,
      ),

    /**
     * @tags clickup, integration, private
     * @name clickUpIntegrationsDetails
     * @request GET:/clickup/integrations/details
     */
    clickUpIntegrationsDetails: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        ClickUpIntegrationDetails[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/clickup/integrations/details`, "GET", params),

    /**
     * @tags clickup, integration, private
     * @name patchIntegration
     * @request PATCH:/clickup/integrations/{workspaceId}
     */
    patchIntegration: <ErrorTypes extends { status: number; response: any }>(
      workspaceId: string,
      data: ClickUpIntegrationPatch,
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/clickup/integrations/${workspaceId}`,
        "PATCH",
        params,
        data,
      ),
  };
  credentials = {
    /**
     * @tags credentials
     * @name list_1
     * @request GET:/credentials
     * @secure
     */
    list1: <ErrorTypes extends { status: number; response: any }>(
      query?: { includeInvalid?: boolean | null },
      params?: RequestParams,
    ) =>
      this.request<Credential[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/credentials${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags credentials
     * @name listPersonal
     * @request GET:/credentials/personal
     * @secure
     */
    listPersonal: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Credential[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/credentials/personal`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags credentials
     * @name getPrimary_1
     * @request GET:/credentials/primary
     * @secure
     */
    getPrimary1: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Calendar, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/credentials/primary`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags credentials
     * @name get
     * @request GET:/credentials/{id}
     * @secure
     */
    get: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<Credential, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/credentials/${id}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags credentials
     * @name delete_2
     * @request DELETE:/credentials/{id}
     */
    delete2: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/credentials/${id}`,
        "DELETE",
        params,
      ),
  };
  events = {
    /**
     * @tags events
     * @name query
     * @request GET:/events
     */
    query: <ErrorTypes extends { status: number; response: any }>(
      query?: {
        calendar?: number | null;
        calendarIds?: number[] | null | null;
        priorities?: number[] | null | null;
        type?: EventType[] | null | null;
        project?: number[] | null | null;
        start?: string | null;
        end?: string | null;
        sourceDetails?: boolean | null;
        thin?: boolean | null;
        habitIds?: number[] | null | null;
        recurringOneOnOneIds?: number[] | null | null;
        includeProjects?: ProjectInclude | null;
      },
      params?: RequestParams,
    ) =>
      this.request<Event[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags events
     * @name getPersonal_1
     * @request GET:/events/personal
     */
    getPersonal1: <ErrorTypes extends { status: number; response: any }>(
      query?: { start?: string | null; end?: string | null; limit?: number | null },
      params?: RequestParams,
    ) =>
      this.request<Event[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events/personal${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags events
     * @name queryGoogleRaw
     * @request GET:/events/raw-google/{credentialId}/{calendarId}
     */
    queryGoogleRaw: <ErrorTypes extends { status: number; response: any }>(
      credentialId: number,
      calendarId: string,
      query?: {
        q?: string | null;
        daysBack?: number | null;
        singleEvents?: boolean | null;
        eventField?: string[] | null | null;
        privateExtendedProperty?: string[] | null | null;
        sharedExtendedProperty?: string[] | null | null;
      },
      params?: RequestParams,
    ) =>
      this.request<
        EventWithData[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/events/raw-google/${credentialId}/${calendarId}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags events
     * @name fetchGoogleRaw
     * @request GET:/events/raw-google/{credentialId}/{calendarId}/{eventId}
     */
    fetchGoogleRaw: <ErrorTypes extends { status: number; response: any }>(
      credentialId: number,
      calendarId: string,
      eventId: string,
      query?: { eventField?: string[] | null | null },
      params?: RequestParams,
    ) =>
      this.request<EventWithData, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events/raw-google/${credentialId}/${calendarId}/${eventId}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags events
     * @name gcalViewRedirect
     * @request GET:/events/view/{calendarId}/{eventId}
     */
    gcalViewRedirect: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      query?: { skipSourceRedirect?: boolean | null },
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events/view/${calendarId}/${eventId}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags events
     * @name getForCalendar
     * @request GET:/events/{calendarId}/{eventId}
     */
    getForCalendar: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      query?: { sourceDetails?: boolean | null; thin?: boolean | null; includeProjects?: ProjectInclude | null },
      params?: RequestParams,
    ) =>
      this.request<Event, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events/${calendarId}/${eventId}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags events
     * @name adjustConferenceBuffer
     * @request POST:/events/{calendarId}/{eventId}/buffer
     */
    adjustConferenceBuffer: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      query: { duration: number; notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events/${calendarId}/${eventId}/buffer${this.addQueryParams(query)}`,
        "POST",
        params,
      ),

    /**
     * @tags events
     * @name adjustPriority
     * @request POST:/events/{calendarId}/{eventId}/priority
     */
    adjustPriority: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      query: { priority: PriorityLevel; period?: RecurringEventUpdatePeriod | null; notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events/${calendarId}/${eventId}/priority${this.addQueryParams(query)}`,
        "POST",
        params,
      ),

    /**
     * @tags events
     * @name adjustTravelTime
     * @request POST:/events/{calendarId}/{eventId}/travel/{type}
     */
    adjustTravelTime: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      type: AssistType,
      query: { duration: number; notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events/${calendarId}/${eventId}/travel/${type}${this.addQueryParams(query)}`,
        "POST",
        params,
      ),

    /**
     * @tags events
     * @name get_1
     * @request GET:/events/{eventId}
     */
    get1: <ErrorTypes extends { status: number; response: any }>(
      eventId: string,
      query?: { sourceDetails?: boolean | null; thin?: boolean | null; includeProjects?: ProjectInclude | null },
      params?: RequestParams,
    ) =>
      this.request<Event, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/events/${eventId}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),
  };
  googleAddOn = {
    /**
     * @tags google-add-on, integration, private
     * @name integrations_2
     * @request GET:/google-add-on/integrations
     */
    integrations2: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        GoogleAddOnSettings[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/google-add-on/integrations`, "GET", params),
  };
  integrations = {
    /**
     * @tags integration, private
     * @name enabled
     * @request GET:/integrations/enabled
     */
    enabled: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/integrations/enabled`,
        "GET",
        params,
      ),

    /**
     * @name getZoomIntegration
     * @request GET:/integrations/zoom
     * @secure
     */
    getZoomIntegration: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<ZoomUser, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/integrations/zoom`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @name deleteZoomToken
     * @request DELETE:/integrations/zoom
     */
    deleteZoomToken: <ErrorTypes extends { status: number; response: any }>(
      data: { user?: User },
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/integrations/zoom`,
        "DELETE",
        params,
        data,
      ),
  };
  interpreter = {
    /**
     * @name message
     * @request POST:/interpreter/message
     */
    message: <ErrorTypes extends { status: number; response: any }>(
      data: MessagePost & { user?: User },
      params?: RequestParams,
    ) =>
      this.request<
        InterpretedPlansGet,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/interpreter/message`, "POST", params, data),

    /**
     * @name fetchMessage
     * @request GET:/interpreter/plans/pending/{planId}
     * @secure
     */
    fetchMessage: <ErrorTypes extends { status: number; response: any }>(planId: string, params?: RequestParams) =>
      this.request<
        InterpretedPlanGet,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/interpreter/plans/pending/${planId}`, "GET", params, null, BodyType.Json, true),
  };
  invite = {
    /**
     * @tags invite
     * @name resolve
     * @request GET:/invite/{trackingCode}
     */
    resolve: <ErrorTypes extends { status: number; response: any }>(trackingCode: string, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/invite/${trackingCode}`,
        "GET",
        params,
      ),
  };
  jira = {
    /**
     * @tags integration, jira, private
     * @name integrations_3
     * @request GET:/jira/integrations
     */
    integrations3: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        JiraIntegration[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/jira/integrations`, "GET", params),

    /**
     * @tags integration, jira, private
     * @name deleteIntegration_2
     * @request DELETE:/jira/integrations/{id}
     */
    deleteIntegration2: <ErrorTypes extends { status: number; response: any }>(id: string, params?: RequestParams) =>
      this.request<
        JiraIntegration[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/jira/integrations/${id}`, "DELETE", params),

    /**
     * @tags integration, jira, private
     * @name patchIntegration_1
     * @request PATCH:/jira/integrations/{id}
     */
    patchIntegration1: <ErrorTypes extends { status: number; response: any }>(
      id: string,
      data: JiraIntegrationPatch,
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/jira/integrations/${id}`,
        "PATCH",
        params,
        data,
      ),
  };
  linear = {
    /**
     * @tags integration, linear, private
     * @name integrations_4
     * @request GET:/linear/integrations
     */
    integrations4: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        LinearIntegration[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/linear/integrations`, "GET", params),

    /**
     * @tags integration, linear, private
     * @name deleteIntegration_3
     * @request DELETE:/linear/integrations/{id}
     */
    deleteIntegration3: <ErrorTypes extends { status: number; response: any }>(id: string, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/linear/integrations/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @tags integration, linear, private
     * @name patchIntegration_2
     * @request PATCH:/linear/integrations/{id}
     */
    patchIntegration2: <ErrorTypes extends { status: number; response: any }>(
      id: string,
      data: LinearIntegrationPatch,
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/linear/integrations/${id}`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @tags integration, linear, private
     * @name issueDetails
     * @request GET:/linear/issue/{id}
     */
    issueDetails: <ErrorTypes extends { status: number; response: any }>(id: string, params?: RequestParams) =>
      this.request<
        LinearTaskDetails,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/linear/issue/${id}`, "GET", params),
  };
  moment = {
    /**
     * @tags moment
     * @name get_2
     * @request GET:/moment
     */
    get2: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Moment, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/moment`,
        "GET",
        params,
      ),

    /**
     * @tags moment
     * @name next
     * @request GET:/moment/next
     */
    next: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Moment, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/moment/next`,
        "GET",
        params,
      ),
  };
  ok = {
    /**
     * @name ok
     * @request GET:/ok
     */
    ok: <ErrorTypes extends { status: number; response: any }>(
      query?: { env?: string | null },
      params?: RequestParams,
    ) =>
      this.request<LocalTime, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/ok${this.addQueryParams(query)}`,
        "GET",
        params,
      ),
  };
  oneOnOne = {
    /**
     * @tags oneOnOnes
     * @name getOneOnOnes
     * @request GET:/oneOnOne
     */
    getOneOnOnes: <ErrorTypes extends { status: number; response: any }>(
      query?: { instances?: boolean | null },
      params?: RequestParams,
    ) =>
      this.request<
        RecurringOneOnOne[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags oneOnOnes
     * @name createOnOnOne
     * @request POST:/oneOnOne
     */
    createOnOnOne: <ErrorTypes extends { status: number; response: any }>(
      data: RecurringOneOnOne,
      query?: { notificationKey?: string | null; sendTeamInvite?: boolean | null },
      params?: RequestParams,
    ) =>
      this.request<
        RecurringOneOnOne[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags oneOnOnes
     * @name convertPendingToAuto
     * @request POST:/oneOnOne/convert-auto/{id}
     */
    convertPendingToAuto: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      query?: { title?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        RecurringOneOnOne,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/convert-auto/${id}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags oneOnOnes
     * @name getDetected
     * @request GET:/oneOnOne/detected
     */
    getDetected: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        RecurringOneOnOne[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/detected`, "GET", params),

    /**
     * @tags oneOnOnes
     * @name respond
     * @request POST:/oneOnOne/invite/{id}/respond
     */
    respond: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      data: { status?: RecurringAssignmentAttendeeStatus },
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/oneOnOne/invite/${id}/respond${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags oneOnOnes
     * @name respondAnonymously
     * @request POST:/oneOnOne/invite/{id}/respond/{inviteKey}
     */
    respondAnonymously: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      inviteKey: string,
      data: { status?: RecurringAssignmentAttendeeStatus },
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        RecurringOneOnOne,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/invite/${id}/respond/${inviteKey}${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags oneOnOnes
     * @name getInviteeEligibility
     * @request GET:/oneOnOne/invitee-eligibility
     */
    getInviteeEligibility: <ErrorTypes extends { status: number; response: any }>(
      query: { email: string },
      params?: RequestParams,
    ) =>
      this.request<
        InviteeEligibility,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/invitee-eligibility${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags oneOnOnes
     * @name getMeetingInvites
     * @request GET:/oneOnOne/invites
     */
    getMeetingInvites: <ErrorTypes extends { status: number; response: any }>(
      query?: { instances?: boolean | null },
      params?: RequestParams,
    ) =>
      this.request<
        RecurringOneOnOne[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/invites${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags oneOnOnes
     * @name getMeetingInvite
     * @request GET:/oneOnOne/invites/{id}
     */
    getMeetingInvite: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      query?: { inviteKey?: string | null; instances?: boolean | null },
      params?: RequestParams,
    ) =>
      this.request<
        RecurringOneOnOne,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/invites/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags oneOnOnes
     * @name getSuggestions
     * @request GET:/oneOnOne/suggestions
     */
    getSuggestions: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        RecurringOneOnOne[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/suggestions`, "GET", params),

    /**
     * @tags oneOnOnes
     * @name zoomRedirect
     * @request GET:/oneOnOne/zoom/{trackingCode}/{oneOnOneId}
     */
    zoomRedirect: <ErrorTypes extends { status: number; response: any }>(
      trackingCode: string,
      oneOnOneId: number,
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/oneOnOne/zoom/${trackingCode}/${oneOnOneId}`,
        "GET",
        params,
      ),

    /**
     * @tags oneOnOnes
     * @name getOneOnOne
     * @request GET:/oneOnOne/{id}
     */
    getOneOnOne: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      query?: { instances?: boolean | null },
      params?: RequestParams,
    ) =>
      this.request<
        RecurringOneOnOne,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags oneOnOnes
     * @name deleteOneOnOne
     * @request DELETE:/oneOnOne/{id}
     */
    deleteOneOnOne: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/oneOnOne/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @tags oneOnOnes
     * @name patchOneOnOne
     * @request PATCH:/oneOnOne/{id}
     */
    patchOneOnOne: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      data: Annotation,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        RecurringOneOnOne,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/${id}${this.addQueryParams(query)}`, "PATCH", params, data),

    /**
     * @tags oneOnOnes
     * @name getInstances
     * @request GET:/oneOnOne/{oneOnOneId}/instances
     * @secure
     */
    getInstances: <ErrorTypes extends { status: number; response: any }>(oneOnOneId: number, params?: RequestParams) =>
      this.request<
        RecurringAssignmentInstance[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/oneOnOne/${oneOnOneId}/instances`, "GET", params, null, BodyType.Json, true),
  };
  people = {
    /**
     * @tags people
     * @name search
     * @request GET:/people
     * @secure
     */
    search: <ErrorTypes extends { status: number; response: any }>(
      query: { q: string; limit: number },
      params?: RequestParams,
    ) =>
      this.request<ThinPerson[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/people${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags people, private
     * @name unsubscribe
     * @request DELETE:/people/subscriptions
     */
    unsubscribe: <ErrorTypes extends { status: number; response: any }>(
      query: { email: string; type: string },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/people/subscriptions${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),
  };
  planner = {
    /**
     * @tags actions
     * @name addTime
     * @request POST:/planner/add-time/task/{taskId}
     */
    addTime: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      query?: { minutes?: number | null; notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/add-time/task/${taskId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name clearHabitTimePolicyExceptions
     * @request POST:/planner/clear-exceptions/habit/{habitId}
     */
    clearHabitTimePolicyExceptions: <ErrorTypes extends { status: number; response: any }>(
      habitId: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/clear-exceptions/habit/${habitId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name clearOneOnOneTimePolicyExceptions
     * @request POST:/planner/clear-exceptions/one-on-one/{oneOnOneId}
     */
    clearOneOnOneTimePolicyExceptions: <ErrorTypes extends { status: number; response: any }>(
      oneOnOneId: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/clear-exceptions/one-on-one/${oneOnOneId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name clearTaskTimePolicyExceptions
     * @request POST:/planner/clear-exceptions/task/{taskId}
     */
    clearTaskTimePolicyExceptions: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/clear-exceptions/task/${taskId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name doneHabit
     * @request POST:/planner/done/habit/{habitId}
     */
    doneHabit: <ErrorTypes extends { status: number; response: any }>(
      habitId: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/done/habit/${habitId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name doneTask
     * @request POST:/planner/done/task/{taskId}
     */
    doneTask: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/done/task/${taskId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name changeCategory
     * @request PUT:/planner/event/category/{calendarId}/{eventId}
     */
    changeCategory: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      query: { categoryIn: string; notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/event/category/${calendarId}/${eventId}${this.addQueryParams(query)}`, "PUT", params),

    /**
     * @tags actions
     * @name moveEvent
     * @summary Event Actions
     * @request POST:/planner/event/move/{eventId}
     * @description Event Actions
     */
    moveEvent: <ErrorTypes extends { status: number; response: any }>(
      eventId: string,
      query: { start: string; end: string; notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/event/move/${eventId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name pinEvent
     * @request POST:/planner/event/pin/{eventId}
     */
    pinEvent: <ErrorTypes extends { status: number; response: any }>(
      eventId: string,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/event/pin/${eventId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name rsvp
     * @request PUT:/planner/event/rsvp/{calendarId}/{eventId}
     */
    rsvp: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      data: RsvpResponseBody,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/event/rsvp/${calendarId}/${eventId}${this.addQueryParams(query)}`, "PUT", params, data),

    /**
     * @tags actions
     * @name unpinEvent
     * @request POST:/planner/event/unpin/{eventId}
     */
    unpinEvent: <ErrorTypes extends { status: number; response: any }>(
      eventId: string,
      data: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/event/unpin/${eventId}`, "POST", params, data),

    /**
     * @tags actions
     * @name habitDeleteInstanceAndReschedule
     * @request POST:/planner/habit/{calendarId}/{eventId}/reschedule
     */
    habitDeleteInstanceAndReschedule: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      query?: { snoozeOption?: SnoozeOption | null; notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/habit/${calendarId}/${eventId}/reschedule${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name logWork
     * @request POST:/planner/log-work/task/{taskId}
     */
    logWork: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      query?: { minutes?: number | null; end?: string | null; notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/log-work/task/${taskId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name oneOnOneReschedule
     * @request POST:/planner/one-on-one/reschedule/{oneOnOneId}/{eventId}
     */
    oneOnOneReschedule: <ErrorTypes extends { status: number; response: any }>(
      oneOnOneId: number,
      eventId: string,
      data: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/one-on-one/reschedule/${oneOnOneId}/${eventId}`, "POST", params, data),

    /**
     * @tags actions
     * @name oneOnOneSkipDay
     * @request POST:/planner/one-on-one/skip-day/{oneOnOneId}/{eventId}
     */
    oneOnOneSkipDay: <ErrorTypes extends { status: number; response: any }>(
      oneOnOneId: number,
      eventId: string,
      data: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/one-on-one/skip-day/${oneOnOneId}/${eventId}`, "POST", params, data),

    /**
     * @tags actions
     * @name oneOnOneSkipUntil
     * @request POST:/planner/one-on-one/skip-until/{oneOnOneId}/{eventId}
     */
    oneOnOneSkipUntil: <ErrorTypes extends { status: number; response: any }>(
      oneOnOneId: number,
      eventId: string,
      data: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/one-on-one/skip-until/${oneOnOneId}/${eventId}`, "POST", params, data),

    /**
     * @tags actions
     * @name oneOnOneSkipWeek
     * @request POST:/planner/one-on-one/skip-week/{oneOnOneId}/{eventId}
     */
    oneOnOneSkipWeek: <ErrorTypes extends { status: number; response: any }>(
      oneOnOneId: number,
      eventId: string,
      data: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/one-on-one/skip-week/${oneOnOneId}/${eventId}`, "POST", params, data),

    /**
     * @tags actions
     * @name deleteHabitPolicy
     * @request DELETE:/planner/policy/habit/{habitId}
     */
    deleteHabitPolicy: <ErrorTypes extends { status: number; response: any }>(
      habitId: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/policy/habit/${habitId}${this.addQueryParams(query)}`, "DELETE", params),

    /**
     * @tags actions
     * @name deleteTaskPolicy
     * @summary Task Actions
     * @request DELETE:/planner/policy/task/{taskId}
     * @description Task Actions
     */
    deleteTaskPolicy: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/policy/task/${taskId}${this.addQueryParams(query)}`, "DELETE", params),

    /**
     * @tags actions
     * @name prioritizeTask
     * @request POST:/planner/prioritize/task/{taskId}
     */
    prioritizeTask: <ErrorTypes extends { status: number; response: any }>(taskId: number, params?: RequestParams) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/prioritize/task/${taskId}`, "POST", params),

    /**
     * @tags actions
     * @name rescheduleHabitEvent
     * @request POST:/planner/reschedule/habit/event/{eventId}
     */
    rescheduleHabitEvent: <ErrorTypes extends { status: number; response: any }>(
      eventId: string,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/reschedule/habit/event/${eventId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name rescheduleTaskEvent
     * @request POST:/planner/reschedule/task/event/{eventId}
     */
    rescheduleTaskEvent: <ErrorTypes extends { status: number; response: any }>(
      eventId: string,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/reschedule/task/event/${eventId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name restartHabit
     * @request POST:/planner/restart/habit/{habitId}
     */
    restartHabit: <ErrorTypes extends { status: number; response: any }>(
      habitId: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/restart/habit/${habitId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name restartTask
     * @request POST:/planner/restart/task/{taskId}
     */
    restartTask: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/restart/task/${taskId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name skipHabitEvent
     * @request POST:/planner/skip/habit/event/{eventId}
     */
    skipHabitEvent: <ErrorTypes extends { status: number; response: any }>(
      eventId: string,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/skip/habit/event/${eventId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name startHabit
     * @summary Habit Actions
     * @request POST:/planner/start/habit/{habitId}
     * @description Habit Actions
     */
    startHabit: <ErrorTypes extends { status: number; response: any }>(
      habitId: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/start/habit/${habitId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name startTask
     * @request POST:/planner/start/task/{taskId}
     */
    startTask: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/start/task/${taskId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name stopHabit
     * @request POST:/planner/stop/habit/{habitId}
     */
    stopHabit: <ErrorTypes extends { status: number; response: any }>(
      habitId: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/stop/habit/${habitId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name stopTask
     * @request POST:/planner/stop/task/{taskId}
     */
    stopTask: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/stop/task/${taskId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name taskDeleteMultipleInstanceAndReschedule
     * @request POST:/planner/task/reschedule/bulk
     */
    taskDeleteMultipleInstanceAndReschedule: <ErrorTypes extends { status: number; response: any }>(
      data: EventList,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/task/reschedule/bulk${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags actions
     * @name taskDeleteInstanceAndReschedule
     * @request POST:/planner/task/{calendarId}/{eventId}/reschedule
     */
    taskDeleteInstanceAndReschedule: <ErrorTypes extends { status: number; response: any }>(
      calendarId: number,
      eventId: string,
      query?: { snoozeOption?: SnoozeOption | null; notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/task/${calendarId}/${eventId}/reschedule${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name taskClearSnooze
     * @request POST:/planner/task/{taskId}/clear-snooze
     */
    taskClearSnooze: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/task/${taskId}/clear-snooze${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name toggleHabit
     * @request POST:/planner/toggle/habit/{habitId}
     */
    toggleHabit: <ErrorTypes extends { status: number; response: any }>(
      habitId: number,
      query?: { enable?: boolean | null; notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/toggle/habit/${habitId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags actions
     * @name unarchiveTask
     * @request POST:/planner/unarchive/task/{taskId}
     */
    unarchiveTask: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        PlannerActionIntermediateResult,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/planner/unarchive/task/${taskId}${this.addQueryParams(query)}`, "POST", params),
  };
  projects = {
    /**
     * @tags projects
     * @name query_1
     * @request GET:/projects
     */
    query1: <ErrorTypes extends { status: number; response: any }>(
      query: { parameters: HttpParameters },
      params?: RequestParams,
    ) =>
      this.request<Project[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags projects
     * @name create_1
     * @request POST:/projects
     */
    create1: <ErrorTypes extends { status: number; response: any }>(data: Project, params?: RequestParams) =>
      this.request<Project, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags projects
     * @name get_3
     * @request GET:/projects/{id}
     */
    get3: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<Project, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${id}`,
        "GET",
        params,
      ),

    /**
     * @tags projects
     * @name put
     * @request PUT:/projects/{id}
     */
    put: <ErrorTypes extends { status: number; response: any }>(id: number, data: Project, params?: RequestParams) =>
      this.request<Project, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @tags projects
     * @name delete_3
     * @request DELETE:/projects/{id}
     */
    delete3: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @tags projects
     * @name patch_1
     * @request PATCH:/projects/{id}
     */
    patch1: <ErrorTypes extends { status: number; response: any }>(id: number, data: Project, params?: RequestParams) =>
      this.request<Project, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${id}`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @tags projects
     * @name getEvents
     * @request GET:/projects/{id}/events
     */
    getEvents: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<EventKey[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${id}/events`,
        "GET",
        params,
      ),

    /**
     * @tags projects
     * @name getTasks
     * @request GET:/projects/{id}/tasks
     */
    getTasks: <ErrorTypes extends { status: number; response: any }>(id: number, params?: RequestParams) =>
      this.request<number[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${id}/tasks`,
        "GET",
        params,
      ),

    /**
     * @tags projects
     * @name associateEvent
     * @request POST:/projects/{projectId}/events/{calendarId}/{eventId}
     */
    associateEvent: <ErrorTypes extends { status: number; response: any }>(
      projectId: number,
      calendarId: number,
      eventId: string,
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${projectId}/events/${calendarId}/${eventId}`,
        "POST",
        params,
      ),

    /**
     * @tags projects
     * @name disassociateEvent
     * @request DELETE:/projects/{projectId}/events/{calendarId}/{eventId}
     */
    disassociateEvent: <ErrorTypes extends { status: number; response: any }>(
      projectId: number,
      calendarId: number,
      eventId: string,
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${projectId}/events/${calendarId}/${eventId}`,
        "DELETE",
        params,
      ),

    /**
     * @tags projects
     * @name associateTask
     * @request POST:/projects/{projectId}/tasks/{taskId}
     */
    associateTask: <ErrorTypes extends { status: number; response: any }>(
      projectId: number,
      taskId: number,
      params?: RequestParams,
    ) =>
      this.request<any, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${projectId}/tasks/${taskId}`,
        "POST",
        params,
      ),

    /**
     * @tags projects
     * @name disassociateTask
     * @request DELETE:/projects/{projectId}/tasks/{taskId}
     */
    disassociateTask: <ErrorTypes extends { status: number; response: any }>(
      projectId: number,
      taskId: number,
      params?: RequestParams,
    ) =>
      this.request<any, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/projects/${projectId}/tasks/${taskId}`,
        "DELETE",
        params,
      ),
  };
  resources = {
    /**
     * @tags resources
     * @name getResources
     * @request GET:/resources
     * @secure
     */
    getResources: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Resources, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/resources`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),
  };
  scoring = {
    /**
     * @tags scoring
     * @name rescore
     * @request POST:/scoring/rescore
     */
    rescore: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/scoring/rescore`,
        "POST",
        params,
      ),
  };
  slack = {
    /**
     * @tags integration, private, slack
     * @name actionEndpoint
     * @request POST:/slack/action-endpoint
     */
    actionEndpoint: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/slack/action-endpoint`,
        "POST",
        params,
      ),

    /**
     * @tags integration, private, slack
     * @name slackRedirect
     * @request GET:/slack/add
     */
    slackRedirect: <ErrorTypes extends { status: number; response: any }>(
      query?: { scope?: string | null; bot?: string | null; teamId?: string | null },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/slack/add${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags integration, private, slack
     * @name command
     * @request POST:/slack/command
     */
    command: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/slack/command`,
        "POST",
        params,
      ),

    /**
     * @tags integration, private, slack
     * @name connect
     * @request GET:/slack/connect
     */
    connect: <ErrorTypes extends { status: number; response: any }>(
      query?: { code?: string | null; error?: string | null; state?: string | null },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/slack/connect${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags integration, private, slack
     * @name interactiveEndpoint
     * @request POST:/slack/interactive-endpoint
     */
    interactiveEndpoint: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/slack/interactive-endpoint`,
        "POST",
        params,
      ),

    /**
     * @tags integration, private, slack
     * @name link
     * @request POST:/slack/link
     */
    link: <ErrorTypes extends { status: number; response: any }>(
      query: { teamId: string; userId: string },
      data: { user?: User },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/slack/link${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags integration, private, slack
     * @name optionsLoadEndpoint
     * @request POST:/slack/options-load-endpoint
     */
    optionsLoadEndpoint: <ErrorTypes extends { status: number; response: any }>(
      data: { response_url?: string | null; payload?: string | null },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/slack/options-load-endpoint`,
        "POST",
        params,
        data,
      ),
  };
  sso = {
    /**
     * @tags actions
     * @name findProvider
     * @request GET:/sso/provider/{email}
     */
    findProvider: <ErrorTypes extends { status: number; response: any }>(email: string, params?: RequestParams) =>
      this.request<LocalTime, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/sso/provider/${email}`,
        "GET",
        params,
      ),
  };
  tasks = {
    /**
     * @tags tasks
     * @name query_2
     * @request GET:/tasks
     * @secure
     */
    query2: <ErrorTypes extends { status: number; response: any }>(
      query?: {
        status?: TaskStatus[] | null | null;
        project?: number | null;
        priority?: PriorityLevel | null;
        id?: number[] | null | null;
        instances?: boolean | null;
        includeProjects?: ProjectInclude | null;
      },
      params?: RequestParams,
    ) =>
      this.request<Task[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags tasks
     * @name create_2
     * @request POST:/tasks
     */
    create2: <ErrorTypes extends { status: number; response: any }>(
      data: Task & { user?: User },
      query?: { instances?: boolean | null; includeProjects?: ProjectInclude | null; notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags tasks
     * @name interest_1
     * @request POST:/tasks/interest
     */
    interest1: <ErrorTypes extends { status: number; response: any }>(data: { user?: User }, params?: RequestParams) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/interest`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags tasks
     * @name findMinIndex
     * @request GET:/tasks/min-index
     * @secure
     */
    findMinIndex: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<number, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/min-index`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags tasks
     * @name reindexByDue
     * @request PATCH:/tasks/reindex-by-due
     */
    reindexByDue: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Task[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/reindex-by-due`,
        "PATCH",
        params,
      ),

    /**
     * @tags tasks
     * @name getTask
     * @request GET:/tasks/{id}
     * @secure
     */
    getTask: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      query?: { instances?: boolean | null; includeProjects?: ProjectInclude | null },
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/${id}${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags tasks
     * @name delete_4
     * @request DELETE:/tasks/{id}
     */
    delete4: <ErrorTypes extends { status: number; response: any }>(
      id: number,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/${id}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @tags tasks
     * @name put_1
     * @request PUT:/tasks/{taskId}
     */
    put1: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      data: Task,
      query?: { instances?: boolean | null; includeProjects?: ProjectInclude | null; notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/${taskId}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @tags tasks
     * @name patch_2
     * @request PATCH:/tasks/{taskId}
     */
    patch2: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      data: Annotation,
      query?: { instances?: boolean | null; includeProjects?: ProjectInclude | null; notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/${taskId}${this.addQueryParams(query)}`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @tags tasks
     * @name getInstances_1
     * @request GET:/tasks/{taskId}/instances
     * @secure
     */
    getInstances1: <ErrorTypes extends { status: number; response: any }>(taskId: number, params?: RequestParams) =>
      this.request<
        TaskInstance[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/tasks/${taskId}/instances`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags tasks
     * @name updateInstance
     * @request PATCH:/tasks/{taskId}/instances/{index}
     */
    updateInstance: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      index: number,
      data: { user?: User },
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/${taskId}/instances/${index}${this.addQueryParams(query)}`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @tags tasks
     * @name reindex
     * @request PATCH:/tasks/{taskId}/reindex
     */
    reindex: <ErrorTypes extends { status: number; response: any }>(
      taskId: number,
      data: Reindex,
      params?: RequestParams,
    ) =>
      this.request<Task, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/tasks/${taskId}/reindex`,
        "PATCH",
        params,
        data,
      ),
  };
  timescheme = {
    /**
     * @tags time-scheme-rules
     * @name listRules
     * @request GET:/timescheme/rules
     */
    listRules: <ErrorTypes extends { status: number; response: any }>(
      query: { type: TimeSchemeIntegrationRuleServiceType },
      params?: RequestParams,
    ) =>
      this.request<
        TimeSchemeIntegrationRule[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/timescheme/rules${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags time-scheme-rules
     * @name createRule
     * @request POST:/timescheme/rules
     */
    createRule: <ErrorTypes extends { status: number; response: any }>(
      data: TimeSchemeIntegrationRuleCreateRequest,
      params?: RequestParams,
    ) =>
      this.request<
        TimeSchemeIntegrationRule,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/timescheme/rules`, "POST", params, data),

    /**
     * @tags time-scheme-rules
     * @name deleteRule
     * @request DELETE:/timescheme/rules/{ruleId}
     */
    deleteRule: <ErrorTypes extends { status: number; response: any }>(ruleId: string, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/timescheme/rules/${ruleId}`,
        "DELETE",
        params,
      ),

    /**
     * @tags time-scheme-rules
     * @name updateRule
     * @request PATCH:/timescheme/rules/{ruleId}
     */
    updateRule: <ErrorTypes extends { status: number; response: any }>(
      ruleId: string,
      data: TimeSchemeIntegrationRuleUpdateRequest,
      params?: RequestParams,
    ) =>
      this.request<
        TimeSchemeIntegrationRule,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/timescheme/rules/${ruleId}`, "PATCH", params, data),

    /**
     * @tags time-scheme-rules
     * @name reindex_1
     * @request PATCH:/timescheme/rules/{ruleId}/reindex
     */
    reindex1: <ErrorTypes extends { status: number; response: any }>(
      ruleId: string,
      data: TimeSchemeIntegrationRuleReindexRequest,
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/timescheme/rules/${ruleId}/reindex`,
        "PATCH",
        params,
        data,
      ),
  };
  timeschemes = {
    /**
     * @tags time-scheme
     * @name listTimePolicySchemes
     * @request GET:/timeschemes
     */
    listTimePolicySchemes: <ErrorTypes extends { status: number; response: any }>(
      query?: {
        taskCategory?: TaskCategory | null;
        policyType?: TimePolicyType[] | null | null;
        features?: ReclaimEventType[] | null | null;
      },
      params?: RequestParams,
    ) =>
      this.request<TimeScheme[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/timeschemes${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags time-scheme
     * @name createTimeScheme
     * @request POST:/timeschemes
     */
    createTimeScheme: <ErrorTypes extends { status: number; response: any }>(
      data: TimeSchemeCreateRequest,
      params?: RequestParams,
    ) =>
      this.request<TimeScheme, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/timeschemes`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags time-scheme
     * @name queryTimePolicySchemesByUserIdAndFeature
     * @request GET:/timeschemes/filter-by-feature
     */
    queryTimePolicySchemesByUserIdAndFeature: <ErrorTypes extends { status: number; response: any }>(
      query: { userId: string; feature: ReclaimEventType },
      params?: RequestParams,
    ) =>
      this.request<TimeScheme[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/timeschemes/filter-by-feature${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags time-scheme
     * @name listTimePolicySchemesByUserIdAndFeature
     * @request GET:/timeschemes/filter-by-feature/{userId}
     */
    listTimePolicySchemesByUserIdAndFeature: <ErrorTypes extends { status: number; response: any }>(
      userId: string,
      query: { feature: ReclaimEventType },
      params?: RequestParams,
    ) =>
      this.request<TimeScheme[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/timeschemes/filter-by-feature/${userId}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags time-scheme
     * @name updateTimeScheme
     * @request GET:/timeschemes/{schemeId}
     */
    updateTimeScheme: <ErrorTypes extends { status: number; response: any }>(
      schemeId: string,
      params?: RequestParams,
    ) =>
      this.request<TimeScheme, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/timeschemes/${schemeId}`,
        "GET",
        params,
      ),

    /**
     * @tags time-scheme
     * @name deleteTimeScheme
     * @request DELETE:/timeschemes/{schemeId}
     */
    deleteTimeScheme: <ErrorTypes extends { status: number; response: any }>(
      schemeId: string,
      query?: { moveToTimeSchemeId?: string | null },
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/timeschemes/${schemeId}${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @tags time-scheme
     * @name updateTimeScheme_1
     * @request PATCH:/timeschemes/{schemeId}
     */
    updateTimeScheme1: <ErrorTypes extends { status: number; response: any }>(
      schemeId: string,
      data: TimeSchemeUpdateRequest,
      params?: RequestParams,
    ) =>
      this.request<TimeScheme, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/timeschemes/${schemeId}`,
        "PATCH",
        params,
        data,
      ),
  };
  todoist = {
    /**
     * @tags integration, private, todoist
     * @name integrations_5
     * @request GET:/todoist/integrations
     */
    integrations5: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        TodoistIntegration[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/todoist/integrations`, "GET", params),

    /**
     * @tags integration, private, todoist
     * @name integrationsDetails
     * @request GET:/todoist/integrations/details
     */
    integrationsDetails: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        TodoistIntegrationDetails[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/todoist/integrations/details`, "GET", params),

    /**
     * @tags integration, private, todoist
     * @name deleteIntegration_4
     * @request DELETE:/todoist/integrations/{id}
     */
    deleteIntegration4: <ErrorTypes extends { status: number; response: any }>(id: string, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/todoist/integrations/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @tags integration, private, todoist
     * @name patchIntegration_3
     * @request PATCH:/todoist/integrations/{id}
     */
    patchIntegration3: <ErrorTypes extends { status: number; response: any }>(
      id: string,
      data: TodoistIntegrationPatch,
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/todoist/integrations/${id}`,
        "PATCH",
        params,
        data,
      ),
  };
  users = {
    /**
     * @tags users
     * @name current
     * @summary User details for the currently authenticated user
     * @request GET:/users/current
     * @description User details for the currently authenticated user
     */
    current: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<User, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/current`,
        "GET",
        params,
      ),

    /**
     * @tags users
     * @name delete_5
     * @request DELETE:/users/current
     */
    delete5: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/current`,
        "DELETE",
        params,
      ),

    /**
     * @tags users
     * @name patch_3
     * @request PATCH:/users/current
     */
    patch3: <ErrorTypes extends { status: number; response: any }>(data: User, params?: RequestParams) =>
      this.request<User, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/current`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @tags contacts
     * @name getContacts
     * @request GET:/users/current/contacts
     * @secure
     */
    getContacts: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<ThinPerson[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/current/contacts`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags contacts
     * @name inviteContacts
     * @request POST:/users/current/contacts/invite
     */
    inviteContacts: <ErrorTypes extends { status: number; response: any }>(
      data: ThinPerson[],
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/current/contacts/invite`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags users
     * @name onboarded
     * @request POST:/users/current/onboarded
     */
    onboarded: <ErrorTypes extends { status: number; response: any }>(
      data: OnboardedState,
      query?: { notificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<User, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/current/onboarded${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags users
     * @name productUsage
     * @request GET:/users/current/product-usage
     */
    productUsage: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        ProductUsageReport,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/users/current/product-usage`, "GET", params),

    /**
     * @tags users
     * @name hardDowngrade
     * @request POST:/users/current/product-usage/hard-downgrade
     */
    hardDowngrade: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        ProductUsageReport,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/users/current/product-usage/hard-downgrade`, "POST", params),

    /**
     * @tags quest
     * @name getCompletedQuests
     * @request GET:/users/current/quest
     */
    getCompletedQuests: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<UserQuests, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/current/quest`,
        "GET",
        params,
      ),

    /**
     * @tags quest
     * @name completeQuest
     * @request PATCH:/users/current/quest
     */
    completeQuest: <ErrorTypes extends { status: number; response: any }>(
      data: { questType?: QuestType },
      params?: RequestParams,
    ) =>
      this.request<UserQuests, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/current/quest`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @tags users
     * @name referrals
     * @request GET:/users/current/referrals
     */
    referrals: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<ReferralStats, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/current/referrals`,
        "GET",
        params,
      ),

    /**
     * @tags users
     * @name claimRewards
     * @request POST:/users/current/rewards
     */
    claimRewards: <ErrorTypes extends { status: number; response: any }>(
      query: { claim: number },
      params?: RequestParams,
    ) =>
      this.request<ReferralStats, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/current/rewards${this.addQueryParams(query)}`,
        "POST",
        params,
      ),

    /**
     * @tags timePolicies
     * @name get_4
     * @request GET:/users/current/timePolicies
     */
    get4: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        Record<string, CustomTimePolicy>,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/users/current/timePolicies`, "GET", params),

    /**
     * @tags timePolicies
     * @name patch_4
     * @request PATCH:/users/current/timePolicies
     */
    patch4: <ErrorTypes extends { status: number; response: any }>(data: User, params?: RequestParams) =>
      this.request<
        Record<string, CustomTimePolicy>,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/users/current/timePolicies`, "PATCH", params, data),

    /**
     * @tags users
     * @name interest_2
     * @request POST:/users/interest
     */
    interest2: <ErrorTypes extends { status: number; response: any }>(
      query: { feature: string },
      params?: RequestParams,
    ) =>
      this.request<User, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/interest${this.addQueryParams(query)}`,
        "POST",
        params,
      ),

    /**
     * @tags users
     * @name addTrait
     * @request POST:/users/trait/{trait}
     */
    addTrait: <ErrorTypes extends { status: number; response: any }>(trait: UserTrait, params?: RequestParams) =>
      this.request<User, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/users/trait/${trait}`,
        "POST",
        params,
      ),
  };
  weeklyReport = {
    /**
     * @tags weekly-report
     * @name social
     * @request GET:/weekly-report/social
     */
    social: <ErrorTypes extends { status: number; response: any }>(
      query: { id: string; verificationKey?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        WeeklyReportTemplateData,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/weekly-report/social${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags weekly-report
     * @name socialImage
     * @request GET:/weekly-report/social/{id}.png
     */
    socialImage: <ErrorTypes extends { status: number; response: any }>(id: string, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/weekly-report/social/${id}.png`,
        "GET",
        params,
      ),

    /**
     * @tags weekly-report
     * @name socialImageAlt
     * @request GET:/weekly-report/social/{trackingCode}/{suffix}.png
     */
    socialImageAlt: <ErrorTypes extends { status: number; response: any }>(
      trackingCode: string,
      suffix: string,
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/weekly-report/social/${trackingCode}/${suffix}.png`,
        "GET",
        params,
      ),

    /**
     * @tags weekly-report
     * @name unsubscribe_1
     * @request POST:/weekly-report/unsubscribe
     */
    unsubscribe1: <ErrorTypes extends { status: number; response: any }>(
      data: WeeklyReportUnsubscribeRequest,
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/weekly-report/unsubscribe`,
        "POST",
        params,
        data,
      ),
  };
  avatar = {
    /**
     * @name credential
     * @request GET:/avatar/credential/{credentialId}
     */
    credential: <ErrorTypes extends { status: number; response: any }>(credentialId: number, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/avatar/credential/${credentialId}`,
        "GET",
        params,
      ),

    /**
     * @name avatar
     * @request GET:/avatar/lookup
     */
    avatar: <ErrorTypes extends { status: number; response: any }>(query: { email: string }, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/avatar/lookup${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @name me
     * @request GET:/avatar/me
     */
    me: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/avatar/me`,
        "GET",
        params,
      ),
  };
  aws = {
    /**
     * @tags aws-ses
     * @name sesEvent
     * @request POST:/aws/ses/events
     */
    sesEvent: <ErrorTypes extends { status: number; response: any }>(data: LocalTime, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/aws/ses/events`,
        "POST",
        params,
        data,
      ),
  };
  oauth = {
    /**
     * @name accountInit
     * @request GET:/oauth/zoom/account/init
     */
    accountInit: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/oauth/zoom/account/init`,
        "GET",
        params,
      ),

    /**
     * @name initZoom
     * @request GET:/oauth/zoom/init
     */
    initZoom: <ErrorTypes extends { status: number; response: any }>(
      query?: { state?: string | null },
      params?: RequestParams,
    ) =>
      this.request<object | null, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/oauth/zoom/init${this.addQueryParams(query)}`,
        "GET",
        params,
      ),
  };
  scim = {
    /**
     * @name getGroups
     * @request GET:/scim/v2/Groups
     */
    getGroups: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        ScimResourceList,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/scim/v2/Groups`, "GET", params),

    /**
     * @name getResourceTypes
     * @request GET:/scim/v2/ResourceTypes
     */
    getResourceTypes: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/scim/v2/ResourceTypes`,
        "GET",
        params,
      ),

    /**
     * @name getSchemas
     * @request GET:/scim/v2/Schemas
     */
    getSchemas: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/scim/v2/Schemas`,
        "GET",
        params,
      ),

    /**
     * @name getServiceProvider
     * @request GET:/scim/v2/ServiceProvider
     */
    getServiceProvider: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/scim/v2/ServiceProvider`,
        "GET",
        params,
      ),

    /**
     * @name getUsers
     * @request GET:/scim/v2/Users
     */
    getUsers: <ErrorTypes extends { status: number; response: any }>(
      query?: {
        attributes?: string[] | null | null;
        startIndex?: number | null;
        count?: number | null;
        filter?: string | null;
      },
      params?: RequestParams,
    ) =>
      this.request<
        ScimResourceList,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/scim/v2/Users${this.addQueryParams(query)}`, "GET", params),

    /**
     * @name putUser
     * @request PUT:/scim/v2/Users
     */
    putUser: <ErrorTypes extends { status: number; response: any }>(data: ScimUser, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/scim/v2/Users`,
        "PUT",
        params,
        data,
      ),

    /**
     * @name createUser
     * @request POST:/scim/v2/Users
     */
    createUser: <ErrorTypes extends { status: number; response: any }>(data: ScimUser, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/scim/v2/Users`,
        "POST",
        params,
        data,
      ),

    /**
     * @name getUser
     * @request GET:/scim/v2/Users/{id}
     */
    getUser: <ErrorTypes extends { status: number; response: any }>(id: string, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/scim/v2/Users/${id}`,
        "GET",
        params,
      ),

    /**
     * @name putUserId
     * @request PUT:/scim/v2/Users/{id}
     */
    putUserId: <ErrorTypes extends { status: number; response: any }>(
      id: string,
      data: ScimUser,
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/scim/v2/Users/${id}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @name deleteUser
     * @request DELETE:/scim/v2/Users/{id}
     */
    deleteUser: <ErrorTypes extends { status: number; response: any }>(id: string, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/scim/v2/Users/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @name patchUser
     * @request PATCH:/scim/v2/Users/{id}
     */
    patchUser: <ErrorTypes extends { status: number; response: any }>(
      id: string,
      data: ScimPatch,
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/scim/v2/Users/${id}`,
        "PATCH",
        params,
        data,
      ),
  };
  web = {
    /**
     * @tags asana, integration, private
     * @name fieldChangeCallback
     * @request POST:/web/asana/formfieldchange
     */
    fieldChangeCallback: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/web/asana/formfieldchange`,
        "POST",
        params,
      ),

    /**
     * @tags asana, integration, private
     * @name fieldChangeCallbackPreflight
     * @request OPTIONS:/web/asana/formfieldchange
     */
    fieldChangeCallbackPreflight: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/web/asana/formfieldchange`,
        "OPTIONS",
        params,
      ),

    /**
     * @tags asana, integration, private
     * @name formCallback
     * @request POST:/web/asana/formsubmit
     */
    formCallback: <ErrorTypes extends { status: number; response: any }>(
      data: AsanaSyncFormSubmission,
      params?: RequestParams,
    ) =>
      this.request<
        AsanaAttachedResource,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/web/asana/formsubmit`, "POST", params, data),

    /**
     * @tags asana, integration, private
     * @name formCallbackPreflight
     * @request OPTIONS:/web/asana/formsubmit
     */
    formCallbackPreflight: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/web/asana/formsubmit`,
        "OPTIONS",
        params,
      ),

    /**
     * @tags asana, integration, private
     * @name syncForm
     * @request GET:/web/asana/syncform
     */
    syncForm: <ErrorTypes extends { status: number; response: any }>(
      query: { workspace: string; task: string; expires_at?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        AsanaFormMetadata,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/web/asana/syncform${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags asana, integration, private
     * @name syncFormPreflight
     * @request OPTIONS:/web/asana/syncform
     */
    syncFormPreflight: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/web/asana/syncform`,
        "OPTIONS",
        params,
      ),

    /**
     * @tags asana, integration, private
     * @name taskDetails
     * @request GET:/web/asana/task/{asanaTaskId}
     */
    taskDetails: <ErrorTypes extends { status: number; response: any }>(asanaTaskId: string, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/web/asana/task/${asanaTaskId}`,
        "GET",
        params,
      ),

    /**
     * @tags asana, integration, private
     * @name taskDetailsPreflight
     * @request OPTIONS:/web/asana/task/{asanaTaskId}
     */
    taskDetailsPreflight: <ErrorTypes extends { status: number; response: any }>(
      asanaTaskId: string,
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/web/asana/task/${asanaTaskId}`,
        "OPTIONS",
        params,
      ),

    /**
     * @tags asana, integration, private
     * @name widget
     * @request GET:/web/asana/widget
     */
    widget: <ErrorTypes extends { status: number; response: any }>(
      query: { workspace: string; task: string; attachment: string; expires_at?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        AsanaWidgetMetadata,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/web/asana/widget${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags asana, integration, private
     * @name widgetPreflight
     * @request OPTIONS:/web/asana/widget
     */
    widgetPreflight: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/web/asana/widget`,
        "OPTIONS",
        params,
      ),

    /**
     * @tags clickup, integration, private
     * @name taskDetails_1
     * @request GET:/web/clickup/task/{taskId}
     */
    taskDetails1: <ErrorTypes extends { status: number; response: any }>(taskId: string, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/web/clickup/task/${taskId}`,
        "GET",
        params,
      ),

    /**
     * @tags integration, jira, private
     * @name taskDetails_2
     * @request GET:/web/jira/task/{issueKey}/{issueId}
     */
    taskDetails2: <ErrorTypes extends { status: number; response: any }>(
      issueKey: string,
      issueId: string,
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/web/jira/task/${issueKey}/${issueId}`,
        "GET",
        params,
      ),

    /**
     * @tags integration, linear, private
     * @name taskDetails_3
     * @request GET:/web/linear/task/{id}
     */
    taskDetails3: <ErrorTypes extends { status: number; response: any }>(id: string, params?: RequestParams) =>
      this.request<
        LinearTaskDetails,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/web/linear/task/${id}`, "GET", params),

    /**
     * @tags integration, private, todoist
     * @name taskDetails_4
     * @request GET:/web/todoist/task/{itemId}
     */
    taskDetails4: <ErrorTypes extends { status: number; response: any }>(itemId: string, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/web/todoist/task/${itemId}`,
        "GET",
        params,
      ),
  };
  ws = {
    /**
     * @tags websocket
     * @name swaggerHelper
     * @summary This is a bogus endpoint that gives us swagger generated objects for our websocket specific classes.
     * @request POST:/ws/bogus
     * @description This is a bogus endpoint that gives us swagger generated objects for our websocket specific classes.
     */
    swaggerHelper: <ErrorTypes extends { status: number; response: any }>(
      data: {
        user?: User;
        envelope?: WebSocketResponseEnvelope;
        assistCompleted?: AssistCompleted;
        assistPlanned?: AssistPlanned;
      },
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/ws/bogus`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags websocket
     * @name connect_1
     * @summary How do we get the connectionId? # List all integrations aws apigatewayv2 get-integrations --api-id xxxxxxxxx # Update all @connect integration aws apigatewayv2 update-integration --integration-id zzzzzzzz --api-id xxxxxxxxx --request-parameters 'integration.
     * @request POST:/ws/connect
     * @description How do we get the connectionId? # List all integrations aws apigatewayv2 get-integrations --api-id xxxxxxxxx # Update all @connect integration aws apigatewayv2 update-integration --integration-id zzzzzzzz --api-id xxxxxxxxx --request-parameters 'integration.request.header.connectionId'='context.connectionId'
     */
    connect1: <ErrorTypes extends { status: number; response: any }>(data: { user?: User }, params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/ws/connect`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags websocket
     * @name defaultHandler
     * @request POST:/ws/default
     */
    defaultHandler: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/ws/default`,
        "POST",
        params,
      ),

    /**
     * @tags websocket
     * @name disconnect
     * @request POST:/ws/disconnect
     */
    disconnect: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/ws/disconnect`,
        "POST",
        params,
      ),

    /**
     * @tags websocket
     * @name subscribe
     * @request POST:/ws/subscribe
     */
    subscribe: <ErrorTypes extends { status: number; response: any }>(
      data: { subscribeAction?: SubscribeAction },
      params?: RequestParams,
    ) =>
      this.request<
        WebSocketResponseEnvelope,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/ws/subscribe`, "POST", params, data),

    /**
     * @tags websocket
     * @name unsubscribe_2
     * @request POST:/ws/unsubscribe
     */
    unsubscribe2: <ErrorTypes extends { status: number; response: any }>(
      data: { unsubscribeAction?: UnsubscribeAction },
      params?: RequestParams,
    ) =>
      this.request<Annotation, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/ws/unsubscribe`,
        "POST",
        params,
        data,
      ),
  };
}
